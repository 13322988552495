import request from "../services/Http";
import { useContext } from "react";
import { Context } from "../context/store/Store";
import {
  addKeyAndIndexFieldToGenericArrayOfObjects,
  getPageProperties, getTableColumns
} from "../services/Helpers";
import { SET_IS_LOADING, SET_HEADCOUNT_TYPE } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from "./useIsLoading";

export default function useHeadcountType(routeKey) {
  const [{ headcountType, pagesAndPermissions }, dispatch] = useContext(Context)
  const { isLoading, setIsLoading } = useIsLoading()

  async function fetchHeadcountType() {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.get('hc-type')
      if (res) {
        let rows = res.data.data
        const properties = getPageProperties(pagesAndPermissions, routeKey)
        const table = getTableColumns(properties.page.table, routeKey)
        let columns = table.columns
        const dropdownValues = res.data.dropdownValues
        let uploadTemplateLink = res.data.uploadTemplate ?? null
        let tableName = res.data.tableName ?? null

        if (headcountType && headcountType.hasOwnProperty('apiDelete') && headcountType.apiDelete) {
          rows = [...headcountType.rows, ...rows]
        }

        const dataTableData = { columns: columns, rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), dropdownValues: dropdownValues, apiDelete: false, uploadTemplateLink, tableName }
        dispatch({
          type: SET_HEADCOUNT_TYPE,
          payload: dataTableData
        })
      }
    }
    catch (e) { }

    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function updateOrCreateHeadcountType(data) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.post('hc-type', {
        hcType: data
      })
      toast.success(res.data.data)
      await fetchHeadcountType()
    }
    catch (e) { }

    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function deleteHeadcountType(hcType) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })

    try {
      const res = await request.delete('hc-type', {
        data: { hcType }
      })
      if (res) {
        await fetchHeadcountType()
        toast.success(res.data.data)
      }
    }
    catch (e) { }

    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  return { fetchHeadcountType, updateOrCreateHeadcountType, deleteHeadcountType, isLoading }
}