import request from "../services/Http";
import { useContext } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_ATTRIBUTE_MANAGEMENT, SET_ATTRIBUTE_MANAGEMENT_LIST } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    isJSONValid,
    addKeyAndIndexFieldToGenericArrayOfObjects,
    getPageProperties, getTableColumns
} from "../services/Helpers";
import { useIsLoading } from "./useIsLoading";

export default function useAttributeManagement(routeKey) {

    const [{ attributeManagement, pagesAndPermissions }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()

    async function getAttributeManagementList() {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.get('attribute-management')
            let rows = res.data.data ?? []
            dispatch({ type: SET_ATTRIBUTE_MANAGEMENT_LIST, payload: rows });

        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    /**
     * @param data_by
     * @param val
     * function used to manage get api call for attribute Management
     **/
    async function getAttributeManagement(data_by) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.get(data_by)
            const properties = getPageProperties(pagesAndPermissions, routeKey)
            const table = getTableColumns(properties.page.table, data_by)
            let columns = table.columns
            let rows = isJSONValid(res.data.data) ?? {}
            let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {}
            let dependencyCount = isJSONValid(res.data.dependencyCount) ?? {}
            let uploadTemplateLink = res.data.uploadTemplate ?? null
            let orgStructureLink = res.data.orgStructureLink ?? null
            let tableName = res.data.tableName ?? null

            if (attributeManagement && attributeManagement.hasOwnProperty('apiDelete') && attributeManagement.apiDelete) {
                rows = [...attributeManagement.rows, ...rows]
            }

            const dataTableData = { columns, rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), dropdownValues: dropdownValues, apiDelete: false, uploadTemplateLink, orgStructureLink, tableName }
            dispatch({
                type: SET_ATTRIBUTE_MANAGEMENT,
                payload: dataTableData
            });
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function updateOrCreateAttributeManagement(data, val) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.post(val, { attributeManagement: data })
            toast.success(res.data.data)
            if (res)
                await getAttributeManagement(val)
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    /**
     * @param data
     * @param val
     * function used to manage delete api call for attribute Management
     **/
    async function deleteAttributeManagement(data, val) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.delete(val, {
                data: {
                    attributeManagement: data
                }
            })
            if (res) await getAttributeManagement(val)
            toast.success(res.data.data);
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    return { getAttributeManagementList, getAttributeManagement, updateOrCreateAttributeManagement, deleteAttributeManagement, isLoading }
}