import { useContext } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SET_IS_LOADING, SET_LIGHTS } from "../context/store/Constants";
import { Context } from "../context/store/Store";
import {
  addKeyAndIndexFieldToGenericArrayOfObjects,
  getPageProperties,
  getTableColumns,
  isJSONValid,
} from "../services/Helpers";
import request from "../services/Http";
import { useIsLoading } from "./useIsLoading";

const CV_ENDPOINT = "cv-light";

export default function useManageLights(routeKey) {
  const [{ lights, pagesAndPermissions }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();

  async function getCVLights() {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get(CV_ENDPOINT);
      const properties = getPageProperties(pagesAndPermissions, routeKey ?? "manage-lights");
      const table = getTableColumns(properties.page.table, "manage-lights");
      let columns = table.columns;
      let rows = isJSONValid(res.data.data) ?? {};
      let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {};
      let uploadTemplateLink = res.data.uploadTemplate ?? null
      let orgStructureLink = res.data.orgStructureLink ?? null
      let tableName = res.data.tableName ?? null
      if (lights && lights.hasOwnProperty("apiDelete") && lights.apiDelete) {
        rows = [...lights.rows, ...rows];
      }

      const dgData = {
        columns,
        rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows),
        dropdownValues,
        apiDelete: false,
        uploadTemplateLink, orgStructureLink, tableName
      };
      dispatch({
        type: SET_LIGHTS,
        payload: dgData,
      });
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function updateOrCreateCVLight(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post(CV_ENDPOINT, { lights: data });
      if (res) {
        toast.success(res.data.data);
        await getCVLights();
      }
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  /**
   * @param data
   * @param val
   * function used to manage delete api call for manage lights
   **/
  async function deleteCVLight(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete(CV_ENDPOINT, {
        data: { lights: data },
      });
      if (res) {
        toast.success(res.data.data);
        await getCVLights();
      }
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }
  return { getCVLights, updateOrCreateCVLight, deleteCVLight, isLoading };
}
