import BaseLayOut from "../../../../components/Layout/BaseLayOut";
import CustomSkelton from "../../../../components/Skelton/CustomSkelton";
import useTransactionCapability from "../../../../utils/hooks/useTransactionCapability";
import { useEffect } from "react";
import TransactionCapabilityDataGrid from "./TransactionCapabilityDataGrid";

export default function TransactionCapability({routeKey, pageName}){

  const {getAllTransactionCapabilities, createUpdateTransactionCapabilities, deleteTransactionCapabilities, transactionCapability, setTransactionCapability, permissions, isLoading} = useTransactionCapability(routeKey)

  useEffect(() => {
    const fn = async () => await getAllTransactionCapabilities()
    fn()
    return () => {
      setTransactionCapability({rows: [], columns: [], dropdownData: {}, customColumns:[]})
    }
  }, []);
  useEffect(() => {}, [transactionCapability]);

  return <CustomSkelton is_loading={isLoading}>
    <BaseLayOut pageTitle={pageName ?? "Transaction Capability"}>
      <TransactionCapabilityDataGrid rows={transactionCapability?.rows} columns={transactionCapability?.columns} customColumns={transactionCapability?.customColumns} dropDownData={transactionCapability?.dropdownData} isLoading={isLoading} postAPI={createUpdateTransactionCapabilities} handleDelete={deleteTransactionCapabilities} permissions={permissions} allowAdding={false} allowSubmit={false}/>
    </BaseLayOut>
  </CustomSkelton>
}