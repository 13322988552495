import React, { useEffect } from "react";
import usePagePermissions from "utils/hooks/usePagePermissions";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import useMasterCVLightType from "../../utils/hooks/useMasterCVLightType";
import ManageCVLightTypeDataGrid from "./components/ManageCVLightTypeDataGrid";

function ManageCVLightType({ routeKey, pageName }) {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const { getCVLightTypes, isLoading, updateOrCreateCVLightType, deleteCVLightType, cvLightTypes, setCVLightTypes } = useMasterCVLightType(routeKey);

  useEffect(() => {
    const fn = async () => await getCVLightTypes();
    fn()
  }, []);

  useEffect(() => {}, [cvLightTypes]);

  return (
    <BaseLayOut pageTitle={pageName || "Manage CV Light Types"}>
      <ManageCVLightTypeDataGrid
        isLoading={isLoading}
        rows={cvLightTypes.rows ?? []}
        columns={cvLightTypes.columns}
        permissions={getPermissionsForPage(routeKey)}
        dropDownData={cvLightTypes.dropdownValues}
        postData={updateOrCreateCVLightType}
        handleDelete={deleteCVLightType}
        allowSelection={true}
        allowAdding={true}
        allowDeletingFromApi={true}
        apiCallback={getCVLightTypes}
        setTypeState={setCVLightTypes}
        typeRecordSate={cvLightTypes}
      />
    </BaseLayOut>
  );
}

export default ManageCVLightType;
