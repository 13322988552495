import MDTypography from 'components/MDTypography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';

export default function ToggleGroupItems({ title, items, onChange, checkedId, disabled }) {
    if (!items?.length) {
        return null;
    }
    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            onChange(newAlignment);
        }
    };
    return (
        <div className="manage-headcount__toggle-group">
            <div className="manage-headcount__title">
                <MDTypography variant="body2" fontWeight="medium">
                    {title}
                </MDTypography>
            </div>
            <div className="manage-headcount__toggle-group-button">
                <ToggleButtonGroup
                    value={checkedId}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="view type"
                >
                    {items.map(({ id, name }) => (
                        <ToggleButton key={id} value={id} disabled={disabled} aria-label={name}>
                            {name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </div>
        </div>
    );
}
