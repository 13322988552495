import * as React from 'react';
import { useEffect, useState, useRef, useContext } from "react";
import 'devextreme/dist/css/dx.light.css';
import MDBox from '../../../components/MDBox';
import { DEButton } from 'utils/services/Helpers';
import { Form, GroupItem, SimpleItem, Label } from 'devextreme-react/form';
import {
  FormFileUploader,
  FormTextArea,
  FormDateBox,
  FormTagBox,
  FormTextBox,
  CustomRadioGroup,
  SelectJD,
} from "../../../components/CustomDataGridComponents";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from "uuid";
import Swal from 'sweetalert2';
import { Context } from "../../../utils/context/store/Store";
import { sanitizeInput } from 'utils/services/Helpers';

const TransferForm = React.memo(({
  formHcLeaverData,
  columns,
  dropDownData,
  setDataSource,
  permissions,
  dataSource,
  setHasDataChanged,
  setDgData,
}) => {
  const [formHcNewData, setFormHcNewData] = useState({});
  const [formDataChanged, setFormDataChanged] = useState(false);
  const [formClassName, setFormClassName] = useState(null);
  const [formErrorMsg, setFormErrorMsg] = useState(null);
  const formRef = useRef(null);
  const [{ headcountRequest }, dispatch] = useContext(Context)

  useEffect(() => {
    localStorage.setItem('resize', true)
    if (Object.keys(formHcLeaverData)?.length)
      setFormHcNewData(formHcLeaverData);

    // cleanup on unmount
    return () => {
      localStorage.removeItem('resize')
      setFormHcNewData({});
      setFormDataChanged(false);
      setFormClassName(null);
    };
  }, []);
  useEffect(() => { }, [formHcNewData])

  /**
   * use to handle form submit
   **/
  const handleSubmit = () => {
    if (formRef.current.instance.validate().isValid) {
      if (dataSource && dataSource.length && formHcNewData && formHcNewData.hasOwnProperty('masterEmployeeId') && dataSource.some((d) => d.masterEmployeeId === formHcNewData.masterEmployeeId && d.id !== formHcNewData.id)) {
        toast.error('Duplicate Entry found for Employee');
      } else {
        const currentLineManagerId = dropDownData?.currentLineManager?.find(manager => manager?.email === formHcNewData?.currentLineManager)?.id ?? null;
        // Validate custom fields
        if (formHcNewData?.master_clients === undefined || formHcNewData?.master_clients === null || formHcNewData?.master_clients === '' || !formHcNewData?.master_clients?.length) {
          setFormErrorMsg('Please add client!')
        }
        else if (formHcNewData?.reason === undefined || formHcNewData?.reason === null || formHcNewData?.reason === '') {
          setFormErrorMsg('Please add justification!')
        }
        else if (formHcNewData?.masterEmployeeId === formHcNewData?.lineManager) {
          setFormErrorMsg('The line manager cannot be the same as the employee!')
        }
        else if (currentLineManagerId && formHcNewData?.lineManager === currentLineManagerId) {
          setFormErrorMsg('The current line manager and the new line manager cannot be the same!')
        }
        else {
          if (formHcNewData.hasOwnProperty('attachment')) {
            formHcNewData["hasFile"] = true
          }
          const newData =
            dataSource &&
            dataSource?.map((item) => (item.id === formHcNewData?.id ? formHcNewData : item));
          setFormErrorMsg(null)
          setDataSource(newData);
          localStorage.removeItem('LR')
          setDgData(newData)

          if (!newData.some(e => !e.masterEmployeeId)) {
            setHasDataChanged(true)
          } else {
            setHasDataChanged(false)
          }

          setFormDataChanged(false);
        }
      }
    }
  };

  /**
   * use to handle form cancel
   **/
  const handleReset = () => {
    formHcLeaverData.masterEmployeeId = null
    formHcLeaverData.jobName = null
    formHcLeaverData.masterOrgDivisionId = null
    formHcLeaverData.division = null
    formHcLeaverData.masterOrgEntityId = null
    formHcLeaverData.entity = null
    formHcLeaverData.masterCountryId = null
    formHcLeaverData.country = null
    formHcLeaverData.masterLevelId = null
    formHcLeaverData.level = null
    formHcLeaverData.masterOrgHfmId = null
    formHcLeaverData.hfm = null
    formHcLeaverData.master_clients = null
    formHcLeaverData.currentLineManager = null
    formHcLeaverData.current_master_clients = null
    setFormHcNewData(formHcLeaverData);
    formRef.current.instance.resetValues()
    formHcLeaverData.replacementRequired = 0
    setHasDataChanged(false)
    setFormDataChanged(false);
    const newData =
      dataSource &&
      dataSource?.map((item) => (item.id === formHcLeaverData?.id ? formHcLeaverData : item));
    setFormErrorMsg(null)
    setDataSource(newData);
    setDgData(newData);
    localStorage.removeItem('LR')
  };

  const handleCancel = (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete this record',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      heightAuto: false,
      height: '200px'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updData = dataSource.filter((item) => item.id !== formHcLeaverData.id)
        setDataSource(updData)
        setHasDataChanged(false)
        setFormDataChanged(false)
        localStorage.removeItem('LR')

      }
    });
  }

  /**
   * Handle Form Data Change
   **/
  const formFieldDataChanged = (e) => {
    let obj = null;
    if (e.dataField === 'masterEmployeeId') {
      const emp = e.value
        ? dropDownData
          ? dropDownData['masterEmployeeId'].find((a) => a.id === e.value)
          : null
        : null;

      obj = emp
        ? {
          masterEmployeeId: emp.id,
          empName: emp.empName,
          jobName: emp.jobName,
          masterOrgDivisionId: emp.masterOrgDivisionId,
          division: emp.division.id,
          masterOrgEntityId: emp.masterOrgEntityId,
          entity: emp.entity.id,
          masterCountryId: emp.masterCountryId,
          country: emp.country.id,
          masterLevelId: emp.masterLevelId,
          level: emp.level.id,
          masterOrgHfmId: emp.masterOrgHfmId,
          hfm: emp.hfm.id,
          currentLineManager: emp.currentLineManager,
          current_master_clients: emp.current_master_clients
        }
        : {
          masterEmployeeId: null,
          empName: null,
          jobName: null,
          masterOrgDivisionId: null,
          division: null,
          masterOrgEntityId: null,
          entity: null,
          masterCountryId: null,
          country: null,
          masterLevelId: null,
          level: null,
          masterOrgHfmId: null,
          hfm: null,
          currentLineManager: null
        };

      const newData = dataSource && dataSource?.map((item) => (item.id === formHcNewData?.id ? { ...formHcNewData, ...obj } : item));
      setDataSource(newData);
      setDgData(newData)
    }

    setFormHcNewData(prevState => {
      const updatedObject = { ...prevState, ...obj };
      updatedObject[e.dataField] = e.value;
      return updatedObject;
    });
    setFormDataChanged(true)
    setHasDataChanged(false)

  };

  /**
   * @param columns
   * @param dropDownData
   * function use to handle rendering of form fields
   **/
  function renderFormFields(columns, dropDownData) {
    // return columns.map(renderFormField(columns, dropDownData, formHcNewData, formHcLeaverData));
    return columns.map((col, index) => {
      const isDisabled = col.disabled || !(formHcLeaverData.newRow || (!formHcLeaverData.hasRollback && !formHcLeaverData.isRollback && formHcLeaverData.isDraft) || (formHcLeaverData.hasRollback && formHcLeaverData.isRollback))
      if (col.type === 'select') {
        if (col.dataIndex === 'masterEmployeeId') {
          return (
            <GroupItem>
              <GroupItem>
                <SimpleItem key={index} name={col.dataIndex}
                  editorType="dxSelectBox"
                  dataField={col.dataIndex}
                  isRequired={col.required}
                  visible={col.is_form_visible}
                  disabled={isDisabled}
                  editorOptions={{
                    disabled: isDisabled,
                    showClearButton: true,
                    searchEnabled: true,
                    dataSource: {
                      paginate: true,
                      pageSize: 10,
                      store: dropDownData?.[col.dataIndex] ?? []
                    },
                    valueExpr: 'id',
                    displayExpr: 'name',
                    paging: true,
                    pageSize: 10
                  }}
                >
                  <Label text={col.title} for={col.dataIndex} />
                </SimpleItem>
              </GroupItem>

              {/* <GroupItem colCount={2}>
                <Label text={" "} visible={true} showColon={false} for={col.dataIndex} />
                <p className='employee-field-message'>If the employee is transitioning internally, please refrain from adding the leaver and ensure to coordinate with the TA Lead as they are responsible for managing internal mobility cases on TEOS.</p>
              </GroupItem> */}
            </GroupItem>
          );
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxSelectBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              disabled={isDisabled}
              editorOptions={{
                disabled: isDisabled,
                showClearButton: true,
                searchEnabled: true,
                dataSource: {
                  paginate: true,
                  pageSize: 10,
                  store: dropDownData?.[col.dataIndex] ?? []
                },
                valueExpr: 'id',
                displayExpr: 'name',
                paging: true,
                pageSize: 10
              }}
            >
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
      }
      else if (col.type === 'multi-select') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible} disabled={isDisabled}
            editorOptions={{
              disabled: isDisabled,
              setValue: (e, dataIndex) => {
                formHcNewData[dataIndex] = e
                setFormHcNewData(formHcNewData)
                setHasDataChanged(false)
                // setFormDataChanged(true)
                // localStorage.setItem('LR', JSON.stringify(formHcNewData))
              }
            }}
            component={(props) => FormTagBox(props, formHcNewData, dropDownData, col.editable, col.dataIndex === 'current_master_clients')}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        );
      }
      else if (col.type === 'toggle') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            editorType="dxSwitch"
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible}
            disabled={isDisabled}
          >
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        );
      }
      else if (col.type === 'checkbox') {
        if (col.dataIndex === 'redundancy') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxCheckBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.masterEosTypeId === 2
              }
              disabled={isDisabled}
            >
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else if (col.dataIndex === 'replacementRequired') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxNumberBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              disabled={isDisabled}
              editorOptions={{
                disabled: isDisabled,
                showSpinButtons: true,
                min: 0
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          )
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxCheckBox"
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible}
              disabled={isDisabled}
            >
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
      }
      else if (col.type === 'date') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible} disabled={isDisabled}
            component={(props) => FormDateBox(props, formHcNewData, col.editable)}
            editorOptions={{
              disabled: isDisabled,
              setValue: (e, dataIndex) => {
                // const d = { ...formHcNewData }
                // formHcLeaverData[dataIndex] = e
                // setFormHcNewData(prevState => {
                //   const updatedObject = { ...prevState, ...d };
                //   updatedObject[dataIndex] = e;
                //   return updatedObject;
                // });
                formHcNewData[dataIndex] = e
                setFormHcNewData(formHcNewData)
                setHasDataChanged(false)
                setFormDataChanged(true)

              }
            }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        );
      }
      else if (col.type === 'file') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={col.is_form_visible} disabled={isDisabled}
            component={(props) => FormFileUploader(props, formHcNewData)}
            editorOptions={{
              disabled: isDisabled,
              setValue: (e, dataIndex) => {
                formHcNewData[dataIndex] = e
                setFormHcNewData(formHcNewData)
              }
            }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        );
      }
      else if (col.type === 'textarea') {
        if (col.dataIndex === 'justification') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required} disabled={isDisabled}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.emailProxyRequired
              }
              component={(props) => FormTextArea(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled,
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
        else if (col.dataIndex === 'handoverComments') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required} disabled={isDisabled}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.emailProxyRequired
              }
              component={(props) => FormTextArea(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled,
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
        else if (col.dataIndex === 'oooContactComments') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required} disabled={isDisabled}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.emailProxyRequired
              }
              component={(props) => FormTextArea(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled,
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
        else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              dataField={col.dataIndex}
              isRequired={col.required}
              visible={col.is_form_visible} disabled={isDisabled}
              component={(props) => FormTextArea(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled,
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                },
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
      }
      else if (col.type === 'radio-group') {
        return (
          <SimpleItem key={index} name={col.dataIndex}
            editorType="dxTextBox"
            dataField={col.dataIndex}
            isRequired={col.required} disabled={isDisabled}
            visible={col.is_form_visible}
            component={(props) => CustomRadioGroup(props, col.options)}
            editorOptions={{
              disabled: isDisabled,
              setValue: (e, dataIndex) => {
                formHcNewData[dataIndex] = e
                setFormHcNewData(formHcNewData)
                setHasDataChanged(false)
                setFormDataChanged(true)
                localStorage.setItem('LR', JSON.stringify(formHcNewData))
              }
            }}>
            <Label text={col.title} for={col.dataIndex} />
          </SimpleItem>
        )
      }
      else {
        // text & string types
        if (col.dataIndex === 'movingTo') {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxTextBox"
              dataField={col.dataIndex}
              isRequired={col.required} disabled={isDisabled}
              visible={
                col.is_form_visible &&
                formHcNewData &&
                formHcNewData.masterEosTypeId === 1
              }
              component={(props) => FormTextBox(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled || !(formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback)),
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        } else {
          return (
            <SimpleItem key={index} name={col.dataIndex}
              editorType="dxTextBox"
              dataField={col.dataIndex}
              isRequired={col.required} disabled={isDisabled}
              visible={col.is_form_visible}
              component={(props) => FormTextBox(props, formHcNewData, col.editable)}
              editorOptions={{
                disabled: isDisabled,
                setValue: (e, dataIndex) => {
                  // const d = { ...formHcNewData }
                  // formHcLeaverData[dataIndex] = e
                  // setFormHcNewData(prevState => {
                  //   const updatedObject = { ...prevState, ...d };
                  //   updatedObject[dataIndex] = e;
                  //   return updatedObject;
                  // });
                  formHcNewData[dataIndex] = e
                  setFormHcNewData(formHcNewData)
                  setHasDataChanged(false)
                  setFormDataChanged(true)
                  localStorage.setItem('LR', JSON.stringify(formHcNewData))

                }
              }}>
              <Label text={col.title} for={col.dataIndex} />
            </SimpleItem>
          );
        }
      }
    });
  }

  return <React.Fragment>
    {columns?.length ? (
      <MDBox id="TransferFormContainer">
        <div style={{ padding: '16px' }} className={formClassName}>
          <Form
            className="HcLeaverFormContent"
            key="id"
            ref={formRef}
            onFieldDataChanged={formFieldDataChanged}
            formData={formHcNewData}
          // width={'100%'}
          // style={{ marginBottom: '30px' }}
          >
            <GroupItem colCount={2}>
              {columns?.length > 0 && renderFormFields(columns, dropDownData)}
            </GroupItem>
          </Form>
          {formErrorMsg !== null ? <MDBox className="form-error">{formErrorMsg}</MDBox> : null}
          <MDBox style={{ textAlign: 'right' }}>
            {
              formHcNewData.newRow || (!formHcNewData.hasRollback && !formHcNewData.isRollback && formHcNewData.isDraft) || (formHcNewData.hasRollback && formHcNewData.isRollback)
                ? <>
                  <DEButton
                    visible={permissions && permissions.canCreate}
                    stylingMode={'contained'}
                    type={'success'}
                    text={'Add'}
                    onClick={handleSubmit}
                    style={{ marginLeft: '10px' }}
                  />
                  <DEButton
                    visible={permissions && permissions.canCreate}
                    stylingMode={'contained'}
                    type={'danger'}
                    text={'Reset'}
                    onClick={handleReset}
                    style={{ marginLeft: '10px' }}
                  />
                  {
                    !formHcNewData.isRollback
                      ? <DEButton
                        visible={permissions && permissions.canCreate}
                        stylingMode={'contained'}
                        type={'default'}
                        text={'Cancel'}
                        onClick={handleCancel}
                        style={{ marginLeft: '10px' }}
                      />
                      : null
                  }
                </>
                : null
            }
          </MDBox>
        </div>
      </MDBox>
    ) : null}
  </React.Fragment>;
})

TransferForm.displayName = "TransferForm"
export default TransferForm;
