import { useLayoutEffect, useState } from "react";

export function usePagination(){
  const [totalCount, setTotalCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(() => window.innerWidth >= 992 ? 1000000 : 1000000);

  useLayoutEffect(() => {
    function updatePageSize() {
      const screenSizeInches = window.innerWidth / window.devicePixelRatio;
      const thresholdInches = 13;

      if (screenSizeInches > thresholdInches) setPageSize(1000000)
      else setPageSize(1000000)
    }

    updatePageSize();
    window.addEventListener("resize", updatePageSize);

    return () => window.removeEventListener("resize", updatePageSize);
  }, []);

  return { totalCount, setTotalCount, hasMore, setHasMore, page, setPage, pageSize, setPageSize };
}