import * as React from "react";
import { useEffect, useState, useMemo } from "react";
import "devextreme/dist/css/dx.light.css";
import MDBox from "../../../../components/MDBox";
import { DEButton, getUserInfo } from "utils/services/Helpers";
import { Form, GroupItem, SimpleItem, Label } from "devextreme-react/form";
import { CustomFormFileInput, FormFileUploader, FormDateBox } from "../../../../components/CustomDataGridComponents";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import useApprovals from "utils/hooks/useApprovals";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ActionDropdown from "views/manage-headcounts/components/ActionDropdown";


const HcLeaverFormDetails = React.memo(({ formHcLeaverData, hcDataAccessValues, hcDropdownData, hcEditableFields, apiCallBack, permissions, routeKey, dataSource, setDataSource, dgInstance, canApproveReject, selectedRowKeys, setSelectedRowKeys }) => {
  const { bulkRollback, approveOrReject, handleHoldOrReActivateRequest, cancelGroupRequest } = useApprovals()
  //const { updateOrCreateHeadcountRequest } = useHeadcountRequest(routeKey);
  const [hcData, setHcData] = useState({});
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [haveFormError, setHaveFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState(null);
  const [formClassName, setFormClassName] = useState(null);
  const [masterRequestTypeId, setMasterRequestTypeId] = useState(null);
  const [masterHeadcountTypeId, setMasterHeadcountTypeId] = useState(null);
  const [actionDropdownDatasource, setActionDropdownDatasource] = useState([]);
  const transactionType = "transaction_leaver"
  const masterModuleId = 3
  const MAX_DISPLAY_TAGS = 10

  useEffect(() => {
    if (formHcLeaverData) {
      setHcData(formHcLeaverData)

      let formClass = null
      if (formHcLeaverData.hasOwnProperty('cancelled') && formHcLeaverData.cancelled) formClass = "cancelled"
      else if (formHcLeaverData.hasOwnProperty('needApproval') && formHcLeaverData.needApproval) formClass = "needApproval"
      else if (formHcLeaverData.hasOwnProperty('status') && formHcLeaverData.status) formClass = "approved"
      else if (formHcLeaverData?.status === false && formHcLeaverData?.isLive === false) formClass = "rejected"
      setFormClassName(formClass)

      if (formHcLeaverData?.transaction_headcount_group && formHcLeaverData?.transaction_headcount_group?.masterRequestTypeId) {
        setMasterRequestTypeId(formHcLeaverData.transaction_headcount_group.masterRequestTypeId)
      }

      if (formHcLeaverData?.transaction_headcount_group && formHcLeaverData?.transaction_headcount_group?.masterHeadcountTypeId) {
        setMasterHeadcountTypeId(formHcLeaverData.transaction_headcount_group.masterHeadcountTypeId)
      }
    }

    // cleanup on unmount
    return () => {
      setHcData({})
      setFormClassName(null)
      setMasterRequestTypeId(null)
      setMasterHeadcountTypeId(null)
    }
  }, []);

  useEffect(() => {
    if (Object.keys(hcData).length) {
      const buttons = [
        {
          text: 'Reject',
          visible: hcData?.isLive && canApproveReject && hcData.needApproval && hcData.actionedByApproverId === null && permissions && permissions.canApprove,
          onClick: handleReject
        },
        {
          text: 'Hold Request',
          visible: hcData?.isLive && !hcData?.headcounts?.length && hcData.needApproval && hcData.actionedByApproverId === null && hcData.hold_reason === null ? true : !hcData?.headcounts?.length && hcData.cStatus !== "Closed" && hcData.status && hcData.canHoldOrReactivate && hcData.cancelled !== true,
          onClick: handleHold
        },
        {
          text: 'Reactivate Request',
          visible: hcData?.isLive && !hcData?.headcounts?.length && hcData?.canReactivateForApprover ? true : hcData.needApproval && hcData.actionedByApproverId === getUserInfo()?.id && hcData.activation_reason === null ? true : !hcData?.headcounts?.length && !hcData.needApproval && hcData.cStatus !== "Closed" && hcData.status && hcData.canReactivate,
          onClick: handleReactivate
        },
        {
          text: 'Rollback',
          visible:  hcData?.isLive && hcData.status === null && canApproveReject && hcData.actionedByApproverId === null && hcData.needApproval && permissions && permissions.canApprove,
          onClick: handleRollback
        },
        {
          text: 'Cancel Request',
          visible: hcData?.isLive && !hcData.needApproval && hcData.actionedByApproverId === null && hcData.cStatus !== "Closed" && !hcData.onHold && hcData.status && hcData.canCancel && permissions && permissions.canCreate,
          onClick: handleCancel
        }
      ];

      // Filter out buttons with visible === false
      const filteredButtons = buttons?.filter(button => button?.visible);
      setActionDropdownDatasource(filteredButtons);
    }
  }, [hcData, canApproveReject, hasDataChanged, permissions]);

  /**
   * Handle Form Data Change
   **/
  const formFieldDataChanged = (e) => {
    setHcData({
      ...hcData,
      [e.dataField]: e.value
    })
    setHasDataChanged(true)
  }


  /**
   * Handle Form edit Request
   **/
  const handleFormAdd = async () => {
    const ds = [...dataSource]
    const leaverRequiredFields = hcEditableFields?.length ? hcEditableFields.filter(f => f.required && f.is_visible) : []
    if (leaverRequiredFields?.length && leaverRequiredFields.every(keyObj => hcData[keyObj.dataIndex] !== null && hcData[keyObj.dataIndex] !== undefined)) {
      const updatedDataSource = ds.map(obj => obj.id === hcData.id ? { ...obj, data: { ...obj.data, ['data']: hcData }, isRequiredFieldsFilled: true } : obj);
      setDataSource(updatedDataSource)
      if (selectedRowKeys && selectedRowKeys.length) {
        const sds = [...selectedRowKeys]
        const updatedSelectedDataSource = sds.map(obj => obj.id === hcData.id ? { ...obj, data: { ...obj.data, ['data']: hcData, isRequiredFieldsFilled: true } } : obj);
        setSelectedRowKeys(updatedSelectedDataSource)
      }
      dgInstance?.collapseAll(-1)
    }
    else toast.error("There are some missing values. Please fill them before proceeding!")
  }

  /**
   * Handle Approve Request
   **/
  const handleApprove = async () => {
    await approveOrReject(true, [hcData], transactionType, masterModuleId, null, apiCallBack)
  }

  /**
   * Handle Reject Request
   **/
  const handleReject = async () => {
    await approveOrReject(false, [hcData], transactionType, masterModuleId, null, apiCallBack)
  }

  /**
   * Handle Hold Request
   **/
  const handleHold = async () => {
    await handleHoldOrReActivateRequest(hcData.id, hcData.hold !== true, transactionType, masterModuleId, hcData.needApproval, apiCallBack)
  }

  /**
   * Handle Reactivate Request
   **/
  const handleReactivate = async () => {
    await handleHoldOrReActivateRequest(hcData.id, hcData?.canReactivateForApprover ? false : hcData.needApproval && hcData?.actionedByApproverId === getUserInfo()?.id && hcData.hold === null ? false : hcData.hold !== true, transactionType, masterModuleId, hcData?.canReactivateForApprover ? true : hcData.needApproval, apiCallBack)
  }

  /**
   * Handle Cancel Request
   **/
  const handleCancel = async () => {
    await cancelGroupRequest(hcData.id, transactionType, hcData.replacementRequired && hcData?.headcounts?.filter(h => h.cancelled === null)?.length, false, apiCallBack)
  }

  /**
* Handle Approve Request
**/
  const handleRollback = async () => {
    await bulkRollback([hcData], "transaction_leaver", masterModuleId, null, apiCallBack)
  }

  const onMultiTagPreparing = (args) => {
    const selectedItemsLength = args.selectedItems.length;

    if (selectedItemsLength < MAX_DISPLAY_TAGS) {
      args.cancel = true;
    } else {
      const allClientsSelected = selectedItemsLength === hcDropdownData?.master_clients?.length;
      args.text = allClientsSelected ? `All Clients` : `${selectedItemsLength} Clients`
    }
  };

  return (
    <>
      {
        Object.keys(hcData).length ?
          <MDBox id="HcLeaverFormDetailsContainer">
            <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
            <div style={{ padding: "16px" }} className={formClassName}>
              <Form key={hcData.id} id="HcLeaverFormDetails" className="HcLeaverFormContent"
                onFieldDataChanged={formFieldDataChanged}
                formData={hcData}
                width={"100%"} style={{ marginBottom: "30px" }}>
                <GroupItem colCount={2}>
                  <SimpleItem dataField={"masterEmployeeId"} editorType="dxSelectBox" name={"masterEmployeeId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterEmployeeId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterEmployeeId') && hcData.masterEmployeeId !== null}
                    editorOptions={{
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterEmployeeId,
                      valueExpr: "id",
                      displayExpr: "name",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterEmployeeId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Employee"} />
                  </SimpleItem>

                  <SimpleItem dataField={"jobName"} name={"jobName"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "jobName")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('jobName') && hcData.jobName !== null}
                    editorOptions={{
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "jobName")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Job Title"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterOrgDivisionId"} editorType="dxSelectBox" name={"masterOrgDivisionId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterOrgDivisionId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterOrgDivisionId') && hcData.masterOrgDivisionId !== null}
                    editorOptions={{
                      searchEnabled: true,
                      dataSource: hcDataAccessValues.masterOrgDivisionId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterOrgDivisionId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Division"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterOrgEntityId"} editorType="dxSelectBox" name={"masterOrgEntityId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterOrgEntityId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterOrgEntityId') && hcData.masterOrgEntityId !== null}
                    editorOptions={{
                      searchEnabled: true,
                      dataSource: hcDataAccessValues.masterOrgEntityId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterOrgEntityId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Entity"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterCountryId"} editorType="dxSelectBox" name={"masterCountryId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterCountryId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterCountryId') && hcData.masterCountryId !== null}
                    editorOptions={{
                      searchEnabled: true,
                      dataSource: hcDataAccessValues.masterCountryId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterCountryId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Country"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterOrgHfmId"} editorType="dxSelectBox" name={"masterOrgHfmId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterOrgHfmId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterOrgHfmId') && hcData.masterOrgHfmId !== null}
                    editorOptions={{
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterOrgHfmId,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterOrgHfmId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"HFM"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterEosTypeId"} editorType="dxSelectBox" name={"masterEosTypeId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterEosTypeId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('masterEosTypeId') && hcData.masterEosTypeId !== null}
                    editorOptions={{
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterEosTypeId,
                      valueExpr: "id",
                      displayExpr: "name",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterEosTypeId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"EOS Type"} />
                  </SimpleItem>

                  <SimpleItem dataField={"movingTo"} name={"movingTo"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "movingTo")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "movingTo")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "movingTo")?.is_visible && hcData.hasOwnProperty('masterEosTypeId') && hcData.masterEosTypeId === 1 : hcData && hcData.hasOwnProperty('movingTo') && hcData.movingTo !== null && hcData.hasOwnProperty('masterEosTypeId') && hcData.masterEosTypeId === 1}
                    editorOptions={{
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "movingTo")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Moving To"} />
                  </SimpleItem>

                  <SimpleItem dataField={"masterEosReasonId"} editorType="dxSelectBox" name={"masterEosReasonId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "masterEosReasonId")?.required : true}
                    visible={hcData && hcData.hasOwnProperty('masterEosReasonId') && hcData.masterEosReasonId !== null}
                    editorOptions={{
                      searchEnabled: true,
                      dataSource: hcDropdownData.masterEosReasonId,
                      valueExpr: "id",
                      displayExpr: "name",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "masterEosReasonId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"EOS Reason"} />
                  </SimpleItem>

                  <SimpleItem dataField={"lastWorkingDay"} editorType="dxDateBox" name={"lastWorkingDay"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "lastWorkingDay")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "lastWorkingDay")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "lastWorkingDay")?.is_visible : hcData && hcData.hasOwnProperty('lastWorkingDay') && hcData.lastWorkingDay !== null}
                    component={(props) => FormDateBox(props, hcData, permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "lastWorkingDay")?.editable && !hcData.cancelled && hcData.needApproval ? true : false, true)}
                    editorOptions={{
                      //openOnFieldClick: true,
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "lastWorkingDay")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      setValue: (e, dataIndex) => {
                        hcData[dataIndex] = e
                        setHcData(hcData)
                      }
                    }}>
                    <Label text={"Last Working Day"} />
                  </SimpleItem>

                  <SimpleItem dataField={"leaverNotificationDate"} editorType="dxDateBox" name={"leaverNotificationDate"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "leaverNotificationDate")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "leaverNotificationDate")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "leaverNotificationDate")?.is_visible : hcData && hcData.hasOwnProperty('leaverNotificationDate') && hcData.leaverNotificationDate !== null}
                    component={(props) => FormDateBox(props, hcData, permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "leaverNotificationDate")?.editable && !hcData.cancelled && hcData.needApproval ? true : false, true)}
                    editorOptions={{
                      //openOnFieldClick: true,
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "leaverNotificationDate")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      setValue: (e, dataIndex) => {
                        hcData[dataIndex] = e
                        setHcData(hcData)
                      }
                    }}>
                    <Label text={"Leaver Notification Date"} />
                  </SimpleItem>

                  <SimpleItem dataField={"master_clients"} editorType="dxTagBox" name={"master_clients"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "master_clients")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('master_clients') && hcData.master_clients !== null}
                    editorOptions={{
                      showSelectionControls: true,
                      searchEnabled: true,
                      dataSource: hcDropdownData.master_clients,
                      valueExpr: "id",
                      displayExpr: "label",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "master_clients")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      maxDisplayedTags: MAX_DISPLAY_TAGS,
                      onMultiTagPreparing: (e) => onMultiTagPreparing(e),
                    }}>
                    <Label text={"Client"} />
                  </SimpleItem>

                  <SimpleItem dataField={"redundancy"} editorType="dxCheckBox" name={"redundancy"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "redundancy")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "redundancy")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "redundancy")?.is_visible && hcData.hasOwnProperty('masterEosTypeId') && hcData.masterEosTypeId === 2 : hcData.hasOwnProperty('masterEosTypeId') && hcData.masterEosTypeId === 2}
                    editorOptions={{
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "redundancy")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Redundancy"} />
                  </SimpleItem>

                  <SimpleItem dataField={"regrettable"} editorType="dxCheckBox" name={"regrettable"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "regrettable")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "regrettable")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "regrettable")?.is_visible : true}
                    editorOptions={{
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "regrettable")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Regrettable"} />
                  </SimpleItem>

                  <SimpleItem dataField={"okToRehireId"} editorType="dxSelectBox" name={"okToRehireId"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "okToRehireId")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('okToRehireId') && hcData.okToRehireId !== null}
                    editorOptions={{
                      searchEnabled: true,
                      dataSource: hcDropdownData.okToRehireId,
                      valueExpr: "id",
                      displayExpr: "name",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "okToRehireId")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Ok To Re-Hire"} />
                  </SimpleItem>


                  {/*<SimpleItem dataField={"okToHire"} editorType="dxCheckBox" name={"okToHire"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "okToHire")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "okToHire")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "okToHire")?.is_visible : true}
                    editorOptions={{
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "okToHire")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Ok To Re-Hire"} />
                  </SimpleItem>*/}

                  <SimpleItem dataField={"managerContact"} editorType="dxSelectBox" name={"managerContact"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "managerContact")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "managerContact")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "managerContact")?.is_visible : hcData && hcData.hasOwnProperty('managerContact') && hcData.managerContact !== null}
                    editorOptions={{
                      searchEnabled: true,
                      dataSource: hcDropdownData.managerContact,
                      valueExpr: "id",
                      displayExpr: "name",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "managerContact")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Contact on OOO Manager"} />
                  </SimpleItem>

                  <SimpleItem dataField={"emailProxyRequired"} editorType="dxCheckBox" name={"emailProxyRequired"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "emailProxyRequired")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "emailProxyRequired")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "emailProxyRequired")?.is_visible : true}
                    editorOptions={{
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "emailProxyRequired")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Email Proxy Access Required"} />
                  </SimpleItem>

                  <SimpleItem dataField={"proxyDelegate"} editorType="dxSelectBox" name={"proxyDelegate"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "proxyDelegate")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "proxyDelegate")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "proxyDelegate")?.is_visible && hcData.hasOwnProperty('emailProxyRequired') && hcData.emailProxyRequired === true : hcData && hcData.hasOwnProperty('proxyDelegate') && hcData.proxyDelegate !== null && hcData.hasOwnProperty('emailProxyRequired') && hcData.emailProxyRequired === true}
                    editorOptions={{
                      searchEnabled: true,
                      dataSource: hcDropdownData.proxyDelegate,
                      valueExpr: "id",
                      displayExpr: "name",
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "proxyDelegate")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Proxy Delegate"} />
                  </SimpleItem>

                  <SimpleItem dataField={"justification"} editorType="dxTextArea" name={"justification"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "justification")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "justification")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "justification")?.is_visible && hcData.hasOwnProperty('emailProxyRequired') && hcData.emailProxyRequired === true : hcData && hcData.hasOwnProperty('justification') && hcData.justification !== null && hcData.hasOwnProperty('emailProxyRequired') && hcData.emailProxyRequired === true}
                    editorOptions={{
                      readOnly: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "justification")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      disabled: false,
                    }}>
                    <Label text={"Business Justification"} />
                  </SimpleItem>

                  <SimpleItem dataField={"handoverComments"} editorType="dxTextArea" name={"handoverComments"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "handoverComments")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "handoverComments")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "handoverComments")?.is_visible && hcData.hasOwnProperty('emailProxyRequired') && hcData.emailProxyRequired === true : hcData && hcData.hasOwnProperty('handoverComments') && hcData.handoverComments !== null && hcData.hasOwnProperty('emailProxyRequired') && hcData.emailProxyRequired === true}
                    editorOptions={{
                      readOnly: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "handoverComments")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      disabled: false,
                    }}>
                    <Label text={"Handover Comments"} />
                  </SimpleItem>

                  <SimpleItem dataField={"oooContactComments"} editorType="dxTextArea" name={"oooContactComments"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "oooContactComments")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "oooContactComments")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "oooContactComments")?.is_visible && hcData.hasOwnProperty('emailProxyRequired') && hcData.emailProxyRequired === true : hcData && hcData.hasOwnProperty('oooContactComments') && hcData.oooContactComments !== null && hcData.hasOwnProperty('emailProxyRequired') && hcData.emailProxyRequired === true}
                    editorOptions={{
                      readOnly: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "oooContactComments")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      disabled: false,
                    }}>
                    <Label text={"Contact OOO Comments"} />
                  </SimpleItem>

                  <SimpleItem dataField={"addDetails"} editorType="dxTextArea" name={"addDetails"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "addDetails")?.required : false}
                    visible={hcEditableFields.length && hcEditableFields.find(item => item.dataIndex === "addDetails")?.is_visible !== undefined ? hcEditableFields.find(item => item.dataIndex === "addDetails")?.is_visible : hcData && hcData.hasOwnProperty('addDetails') && hcData.addDetails !== null}
                    editorOptions={{
                      readOnly: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "addDetails")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                      disabled: false,
                    }}>
                    <Label text={"Additional Details"} />
                  </SimpleItem>

                  <SimpleItem dataField={"attachment"} name={"attachment"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "attachment")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('attachment') && hcData.attachment && hcData.attachment.length !== 0 && permissions && permissions.canCreate}
                    component={CustomFormFileInput}
                    editorOptions={{
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "attachment")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Attachment"} />
                  </SimpleItem>

                  <SimpleItem dataField={"attachments"} name={"attachments"}
                    isRequired={hcEditableFields.length ? hcEditableFields.find(item => item.dataIndex === "attachments")?.required : false}
                    visible={hcData && hcData.hasOwnProperty('attachments') && hcData.attachments && hcData.attachments.length !== 0 && permissions && permissions.canCreate}
                    component={CustomFormFileInput}
                    editorOptions={{
                      disabled: permissions && permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === "attachments")?.editable && !hcData.cancelled && hcData.needApproval ? false : true,
                    }}>
                    <Label text={"Attachment"} />
                  </SimpleItem>

                </GroupItem>
              </Form>
              {haveFormError ? <MDBox className="form-error">{formErrorMsg}</MDBox> : null}
              <MDBox style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", alignItems: "stretch" }}>
                {
                  !hcData.hasOwnProperty('newRow') && hcData.isDraft && permissions && permissions.canCreate
                    ? <Link to={`/views/group/${hcData?.transaction_headcount_group?.id}/request/${hcData?.transaction_headcount_group?.masterRequestTypeId}/type/${hcData?.transaction_headcount_group?.masterHeadcountTypeId ?? "leaver"}/manage-headcount`}>
                      <DEButton visible={!hcData.hasOwnProperty('newRow') && hcData.isDraft && permissions && permissions.canCreate} stylingMode={"contained"} type={"default"} text={""} title={"edit"} icon={"edit"} style={{ marginRight: "10px" }} />
                    </Link>
                    : ""
                }
                <DEButton visible={!canApproveReject && hasDataChanged && hcData.needApproval && hcData.actionedByApproverId === null && permissions && permissions.canApprove} stylingMode={"contained"} type={"default"} text={"Add"} onClick={handleFormAdd} style={{ marginRight: "10px" }} />
                <DEButton visible={canApproveReject && hcData.needApproval && hcData.actionedByApproverId === null && permissions && permissions.canApprove} stylingMode={"contained"} type={"success"} text={"Approve"} onClick={handleApprove} style={{ marginRight: "10px" }} />
                {actionDropdownDatasource && actionDropdownDatasource.length > 0 && (
                  <ActionDropdown
                    permissions={permissions}
                    actionDropdownDatasource={actionDropdownDatasource}
                    isFormFormView={true}
                  />
                )}
              </MDBox>
            </div>
          </MDBox>
          : null
      }
    </>
  );
})
HcLeaverFormDetails.displayName = "HcLeaverFormDetails"
export default HcLeaverFormDetails;
