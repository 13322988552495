import DataGrid, {
  AsyncRule,
  Column,
  Editing,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import * as React from "react";
import { useMemo } from "react";
import { CustomSimpleTextArea } from "../../../components/CustomDataGridComponents";

export default function ViewClientVitalGrid({ rows, columns, defaultKey = "id", isLoading, defaultPageSize = 25 }) {

  const TEXT_LENGTH_THRESHOLD = 84;

  function renderEditColorCell(props, editMode) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "4px",
            backgroundColor: props?.data?.color ? props.data.color : "transparent",
            marginRight: "8px",
          }}
        ></div>
        <div>{props.value}</div>
      </div>
    );
  }

  const CustomCell = (cellInfo) => {
    const textLength = cellInfo.value ? cellInfo.value.split(/\s+/).length : 0;
    const isLongText = textLength > TEXT_LENGTH_THRESHOLD || (cellInfo.value && cellInfo.value.length > 100);

    return (
      <div className={`custom-cell-content ${isLongText ? 'scrollable' : ''}`}>
        {cellInfo.value}
      </div>
    );
  };

  function renderField(col) {
    if (col.dataIndex === "masterCvLightId") {
      return (
        <Column
          dataType={col.type}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          cellRender={renderEditColorCell}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    }
    else if (col.dataIndex === "comments") {
      return (
        <Column
          // width={"500px"}
          dataType={col.type}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          cellRender={CustomCell}
        ></Column>
      );
    }
    else if (col.type === "string") {
      return (
        <Column
          dataType={col.type}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    }
    else if (col.type === "date") {
      return (
        <Column
          dataType={col.type}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          format={"dd-MM-yyyy HH:mm:ss"}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    }
    else if (col.type === "radio-group") {
      return (
        <Column
          dataType={col.type}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          cellRender={renderEditColorCell}>
        </Column>
      );
    }
    else if (col.type === "textarea") {
      return (
        <Column
          // encodeHtml={col.encodeHtml}
          wordWrapEnabled={true}
          minWidth={'100%'}
          editCellComponent={CustomSimpleTextArea}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          cellRender={CustomCell}
        ></Column>
      );
    }
    else {
      return (
        <Column
          allowEditing={false}
          visible={col.type === "actions" ? false : col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    }
  }

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return (
        <div id="data-grid-demo1">
          <DataGrid
            id="ViewCVGrid"
            showBorders
            // columnAutoWidth
            showColumnLines
            showRowLines
            rowAlternationEnabled
            allowColumnResizing
            disabled={isLoading}
            dataSource={rows}
            key={defaultKey ?? "id"}
            keyExpr={defaultKey ?? "id"}
          >
            <Scrolling showScrollbar="always" mode="standard" />
            <SearchPanel visible />
            <Paging defaultPageSize={defaultPageSize} />
            <Pager visible showNavigationButtons showInfo displayMode={"full"} />
            <Editing
              refreshMode={"repaint"}
              mode="cell"
              allowUpdating={false}
              allowAdding={false}
              allowDeleting={false}
            />
            {columns && columns.length ? columns.map((d, i) => renderField(d)) : null}
          </DataGrid>
        </div>
      );
    } catch (e) {
      console.log("error from CV", e);
    }
  }, [rows, columns, isLoading]);

  return <>{Comp}</>;
}
