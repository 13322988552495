import React from "react";
import { Grid } from "@mui/material";
import Select from "react-select";
import { selectCustomStyles, DEButton } from "utils/services/Helpers";
import FormField from "components/FormField";
import MDBox from "components/MDBox";
import { Switch } from "antd";
import MDTypography from "components/MDTypography";

export function NotificationConditionStep({ props }) {
  const {
    tablesList,
    permissions,
    tableName,
    setTableName,
    tableColumnName,
    setTableColumnName,
    columnOperation,
    setColumnOperation,
    conditionValue,
    setConditionValue,
    dropDownData,
    getRecordByField,
    isLoading,
    getOptions,
    records,
    handleSubmit,
    handleReset,
    usePredefinedValue,
    setUsePredefinedValue,
    predefinedValues,
    predefinedValue,
    setPredefinedValue,
  } = props;
  return (
    <>
      {predefinedValues.length > 0 && (
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} display={"flex"}>
              <Grid container rowSpacing={1} columnSpacing={6} alignItems="center">
                <Grid item xs={1}>
                  <Switch
                    checked={usePredefinedValue}
                    onChange={(c) => {
                      setUsePredefinedValue((wasOpened) => !wasOpened);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDTypography variant="subtitle">Use Predefined Value</MDTypography>
                </Grid>
              </Grid>
            </Grid>
            {usePredefinedValue && (
              <Grid item xs={12} sm={6} md={6}>
                <Select
                  options={predefinedValues}
                  isClearable
                  value={predefinedValue}
                  placeholder="Please Select a predefined value..."
                  styles={selectCustomStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  onChange={setPredefinedValue}
                />
              </Grid>
            )}
          </Grid>
        </MDBox>
      )}
      {!usePredefinedValue && (
        <>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Select
                  options={tablesList ?? []}
                  isDisabled={permissions && !permissions.canCreate}
                  placeholder="Please Select Table..."
                  isClearable
                  value={tableName}
                  styles={selectCustomStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  onChange={(val) => {
                    setTableName(val);
                    setTableColumnName(null);
                    setColumnOperation(null);
                    setConditionValue(null);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  options={tableName ? tableName.tableColumns : []}
                  isClearable
                  value={tableColumnName}
                  placeholder="Please Select Column..."
                  styles={selectCustomStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  isDisabled={!tableName}
                  onChange={(val) => {
                    setTableColumnName(val);
                    setColumnOperation(null);
                    setConditionValue(null);
                  }}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Select
                  options={dropDownData?.tableColumnOperators[tableColumnName?.type] ?? []}
                  placeholder="Please Select Operator..."
                  isClearable
                  value={columnOperation}
                  styles={selectCustomStyles}
                  isSearchable
                  menuPortalTarget={document.body}
                  isDisabled={!tableColumnName}
                  onInputChange={() => {}}
                  onChange={async (val) => {
                    setColumnOperation(val);
                    setConditionValue(null);
                    if (
                      tableColumnName?.type === "STRING" &&
                      (val.value === "=" || val.value === "in")
                    ) {
                      await getRecordByField(tableName.modelName, tableColumnName.name);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {columnOperation && tableColumnName?.type === "STRING" ? (
                  columnOperation && columnOperation?.value === "=" ? (
                    <Select
                      options={records ? records : []}
                      isClearable
                      value={conditionValue}
                      styles={selectCustomStyles}
                      isSearchable
                      isLoading={isLoading}
                      placeholder="Please Select Value..."
                      menuPortalTarget={document.body}
                      onChange={setConditionValue}
                    />
                  ) : columnOperation && columnOperation?.value === "in" ? (
                    <Select
                      options={getOptions(records)}
                      value={conditionValue}
                      defaultValue={conditionValue}
                      isLoading={isLoading}
                      styles={selectCustomStyles}
                      isMulti
                      isSearchable
                      menuPortalTarget={document.body}
                      onChange={(val) => {
                        if (val.length && val.find((option) => option.value === "all")) {
                          if (records?.length > 0) {
                            const r = [...getOptions(records).slice(1), ...records];
                            const fr = [...new Map(r?.map((item) => [item["id"], item])).values()];
                            setConditionValue(fr);
                            return fr;
                          }
                        }
                        setConditionValue(val);
                        return val;
                      }}
                    />
                  ) : (
                    <FormField
                      isForWF
                      label={`Please enter ${tableColumnName.label}`}
                      value={conditionValue}
                      onChange={(e) => {
                        const { value: inputValue } = e.target;
                        setConditionValue(inputValue);
                      }}
                    />
                  )
                ) : columnOperation && tableColumnName?.type === "NUMBER" ? (
                  <FormField
                    isForWF
                    type={tableColumnName?.type === "NUMBER" ? "number" : null}
                    label={`Please enter ${tableColumnName.label}`}
                    value={conditionValue}
                    onChange={(e) => {
                      const { value: inputValue } = e.target;
                      setConditionValue(inputValue);
                    }}
                  />
                ) : null}
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
      <MDBox
        display="flex"
        justifyContent="right"
        alignItems="center"
        position="relative"
        zIndex={1}
        pt={3}
      >
        <DEButton
          text="Add"
          disabled={!tableName && !predefinedValue}
          onClick={() => {
            handleSubmit();
          }}
          useSubmitBehavior
          stylingMode="contained"
          type="default"
          style={{ marginRight: "5px" }}
        />
        <DEButton
          text="Reset"
          disabled={!tableName && !predefinedValue}
          onClick={handleReset}
          stylingMode="contained"
          type="danger"
        />
      </MDBox>
    </>
  );
}
