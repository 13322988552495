import request from "../services/Http";
import { useContext } from "react";
import { renderComp, renderIcons } from "../services/NavigationHelper";
import { Context } from "../context/store/Store";
import { SET_MODULES, SET_PAGES_WITH_PERMISSIONS, SET_ROUTES } from "../context/store/Constants";
import { getUserInfo, initFireBase, isJSONValid } from "../services/Helpers";
import { useIsMounted } from './useIsMounted';

export default function useNavigation() {

  const [{ routes }, dispatch] = useContext(Context)
  const isMounted = useIsMounted();

  function convertFirstLetterToLower(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  async function fetchNavigations() {
    // const url = localStorage.getItem('hasProfile') === "false"
    //   ? 'navigation' : `navigation?id=${getUserInfo().id}`
    const url = 'navigation'
    try {
      const res = await request.get(url)

      if (res) {
        const routesFromApi = res.data.data
        const modules = res.data.modules
        const pagePermissions = res.data.pagePermissions;
        if(isMounted()) {

        dispatch({
          type: SET_PAGES_WITH_PERMISSIONS,
          payload: pagePermissions
        })

        routesFromApi.map((route) => {
          if (route.hasOwnProperty('icon') && route.icon !== "") {
            route.icon = renderIcons(route.icon)
          }
          if (route.hasOwnProperty('noCollapse') && route.noCollapse === true) {
            route.component = renderComp(route.comp_name, route.key, pagePermissions)
          }
          if (route.hasOwnProperty('collapse') && route.collapse.length > 0) {
            getNestedRoutes(route, pagePermissions)
          }
          if (route.route?.includes('HeadcountTypeId')){
            route.route = route.route.replace(/:([A-Za-z]+)/g, (match, paramName) => {
              return `:${convertFirstLetterToLower(paramName)}`;
            });
          }
          return route;
        })
        routesFromApi.push(
          {
            "isVisible": false,
            "key": "error-comp",
            "name": "Error",
            "icon": renderIcons("LockOpenIcon"),
            "route": "views/not-found",
            "comp_name": "NotFound",
            "component": renderComp("NotFound"),
            "noCollapse": true
          },
          // {
          //   "key": "analytics",
          //   "icon": renderIcons("AnalyticsIcon"),
          //   "name": "Analytics",
          //   "type": "modal",
          //   "noCollapse": true
          // },
          // {
          //   "key": "support-feedback-center",
          //   "icon": renderIcons("HelpIcon"),
          //   "name": "Support & Feedback Center",
          //   "type": "modal",
          //   "noCollapse": true
          // },
          { "type": "divider", "key": "divider-1" }
        )
        dispatch({
          type: SET_ROUTES,
          payload: routesFromApi
        })
        dispatch({
          type: SET_MODULES,
          payload: modules
        })
      }
      }
      await initFireBase()
    }
    catch (err) {
      console.error(err);
    }
  }

  const getNestedRoutes = (routes, pagePermissions) => {
    // Get nested routes
    routes.collapse.map(nestedRoute => {
      if (nestedRoute.hasOwnProperty('icon') && nestedRoute.icon !== "") {
        nestedRoute.icon = renderIcons(nestedRoute.icon)
      }
      if (nestedRoute.hasOwnProperty('noCollapse') && nestedRoute.noCollapse === true) {
        nestedRoute.component = renderComp(nestedRoute.comp_name, nestedRoute.key, pagePermissions)
      }
      if (nestedRoute.hasOwnProperty('collapse') && nestedRoute.collapse.length > 0) {
        getNestedRoutes(nestedRoute, pagePermissions)
      }
    })
  }

  return { fetchNavigations, routes }
}
