import request from "../services/Http";
import { useContext } from "react";
import { addKeyAndIndexFieldToGenericArrayOfObjects, isJSONValid } from "../services/Helpers";
import useRoles from "./useRoles";
import { toast } from 'react-toastify'
import { useIsLoading } from "./useIsLoading";
import { Context } from "../context/store/Store";
import { SET_FA_VARIABLE, SET_NEW_FA_VARIABLE } from "../context/store/Constants";
import { v4 as uuidv4 } from "uuid";

export default function useFAVariable(role, masterModuleId, routeKey) {

  const { isLoading, setIsLoading } = useIsLoading()
  const [{ faVariable, newFaVariable }, dispatch] = useContext(Context)
  const { getRoles } = useRoles(routeKey)

  async function getVariableByFA(role, modUle) {
    setIsLoading(true)
    dispatch({
      type: SET_FA_VARIABLE,
      payload: { ...faVariable, rows: [] }
    })
    try {
      const res = await request.get(`functional-access/${role}/variable`)
      let columns = res.data.object.columns
      let rows = res.data.data
      let dropDownData = res.data.dropdownValues

      rows.map((r) => {
        r.key = uuidv4()
      })

      if (newFaVariable && newFaVariable.hasOwnProperty('apiDelete') && newFaVariable.apiDelete && newFaVariable.rows && newFaVariable.rows.length) {
        rows = [...rows, ...newFaVariable.rows]

        dispatch({
          type: SET_NEW_FA_VARIABLE,
          payload: {}
        });

      }

      const dataTableData = { columns: columns, rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), dropDownData: dropDownData }

      dispatch({
        type: SET_FA_VARIABLE,
        payload: dataTableData
      })
    }
    catch (e) { }
    setIsLoading(false)
  }

  async function updateOrCreateVariable(functionalAccessId, data) {
    setIsLoading(true)
    try {
      const res = await request.put(`functional-access/${functionalAccessId}/variable`, {
        variables: data
      })
      await getVariableByFA(functionalAccessId, masterModuleId)
      await getRoles()
      toast.success(res.data.data)
    }
    catch (e) { }
    setIsLoading(false)
  }

  async function deleteVariable(variables, functionalAccessId = null, masterModuleId) {
    setIsLoading(true)
    try {
      const res = await request.delete(`fa-variable`, {
        data: {
          variables: variables
        }
      })
      if (res) {
        await getRoles()
        // await getVariableByFA(functionalAccessId, masterModuleId)
        toast.success(res.data.data)
      }
    }
    catch (e) { }
    setIsLoading(false)
  }

  return { getVariableByFA, deleteVariable, updateOrCreateVariable, isLoading }
}