import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Context } from "utils/context/store/Store";
import { SET_MANAGE_NOTIFICATIONS } from "utils/context/store/Constants";
import DataGrid, {
  AsyncRule,
  MasterDetail,
  Column,
  Editing,
  Lookup,
  Export,
  Paging,
  Pager,
  Scrolling,
  SearchPanel,
  HeaderFilter,
  Button,
  RequiredRule,
  Form,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import { SimpleItem } from "devextreme-react/form";
import { toast } from "react-toastify";
import { CustomTextArea } from "components/CustomDataGridComponents";
import { onKeyDown, onRowExpanding } from "../../../utils/services/DatagridHelpers";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import NotificationStep from "./notification-step";
import { v4 as uuidv4 } from "uuid";
import { createSanitizeAsyncRule } from "utils/services/Helpers";

function ManageNotificationGrid({
  rows,
  columns,
  dropDownData,
  isLoading,
  permissions,
  routeKey,
  hitApi,
}) {
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  const [{ notificationsManagement }, dispatch] = useContext(Context);

  const dataGridRef = useRef();

  useEffect(() => {
    setIsDataChanged(false);
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([]);
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  // /**
  //  * @param dataColumns
  //  * @param dropDownData
  //  * function use to handle rendering of fields
  //  **/
  function renderField(dataColumns, dropDownData) {
    return dataColumns.map((col, index) => {
      if (col.type === "select") {
        if (!col.hasOwnProperty("filtrationKey")) {
          return (
            <Column
              key={index}
              allowEditing={col.editable}
              visible={col.is_visible}
              allowSearch={col.is_searchable}
              allowSorting={col.is_sortable}
              dataField={col.dataIndex}
              caption={col.title}
              setCellValue={function (rowData, value) {
                if (col.hasOwnProperty("bindedTo")) rowData[col.bindedTo] = null;
                this.defaultSetCellValue(rowData, value);
              }}
            >
              {col.required ? <RequiredRule /> : null}
              <Lookup
                allowClearing
                dataSource={
                  dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                    ? dropDownData[col.dataIndex]
                    : []
                }
                displayExpr="label"
                valueExpr="id"
              />
            </Column>
          );
        } else if (col.hasOwnProperty("filtrationKey")) {
          return (
            <Column
              key={index}
              dataField={col.dataIndex}
              caption={col.title}
              setCellValue={function (rowData, value) {
                this.defaultSetCellValue(rowData, value);
                if (col.hasOwnProperty("bindedTo")) {
                  rowData[col.bindedTo] = null;
                }
              }}
            >
              <Lookup
                allowClearing
                dataSource={(options) => {
                  const data = {
                    store:
                      dropDownData && dropDownData.hasOwnProperty(col.dataIndex)
                        ? dropDownData[col.dataIndex]
                        : [],
                    filter: options.data
                      ? [col.filtrationKey, "=", options.data[col.filtrationKey]]
                      : null,
                  };
                  return data;
                }}
                displayExpr="label"
                valueExpr="id"
              />
              {col.required ? <RequiredRule /> : null}
            </Column>
          );
        }
      } else if (col.type === "checkbox") {
        return (
          <Column
            key={index}
            dataType="boolean"
            showEditorAlways
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
            setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
            }}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      } else if (col.type === "actions") {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            type="buttons"
            dataField={col.dataIndex}
            caption={col.title}
            width={"80"}
          >
            <Button hint="Delete" name="delete" icon={"trash"} />
            <Button
              hint="Clone"
              icon={"copy"}
              visible={(e) => permissions && permissions.canCreate}
              onClick={(e) => {
                let ds = [...dataSource];
                const notificationId = uuidv4();
                let clonedItem = {
                  ...e.row.data,
                  newRow: true,
                  name: "",
                  id: notificationId,
                  sequence: e.row.data.sequence + 1,
                  notification_steps: e.row.data.notification_steps?.map((e) => ({
                    ...e,
                    tableId: null,
                    id: uuidv4(),
                    uniqueKey: notificationId,
                    notificationId: notificationId,
                    newRow: true,
                  })),
                };
                ds.splice(0, 0, clonedItem);
                setDataSource(ds);
                e.event.preventDefault();
              }}
            />
          </Column>
        );
      } else if (col.type === "button") {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            type={"buttons"}
            dataField={col.dataIndex}
            caption={col.title}
          ></Column>
        );
      } else if (col.type === "textarea") {
        return (
          <Column
            key={index}
            editCellComponent={CustomTextArea}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule {...sanitizeAsyncRule} />
          </Column>
        );
      } else if (col.type === "string"){
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
            <AsyncRule {...sanitizeAsyncRule} />
          </Column>
        );
      } else {
        return (
          <Column
            key={index}
            allowEditing={col.editable}
            visible={col.is_visible}
            allowSearch={col.is_searchable}
            allowSorting={col.is_sortable}
            dataField={col.dataIndex}
            caption={col.title}
          >
            {col.required ? <RequiredRule /> : null}
          </Column>
        );
      }
    });
  }
  // /**
  //  * @param e
  //  * function to define logic to view/hide the delete icon across each row
  //  **/
  const allowDeleting = (e) => {
    return !e.row.data.hasOwnProperty("id");
  };

  // /**
  //  * @param e
  //  * function use to prepare toolbar
  //  **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "save",
        text: "SUBMIT",
        disabled: !isDataChanged,
        onClick: function () {
          pushData();
        },
      },
    });
  }

  // /**
  //  * function use to call the api to post data
  //  **/
  const pushData = async () => {
    let newData = [...dataSource];
    let isEmpty = newData.some(
      (obj) => !obj.notification_steps || obj.notification_steps?.length === 0
    );
    if (isEmpty) {
      toast.error("Please add a step before submitting the notification!");
    } else {
      newData = newData.map(({ workflowStepTableData, ...rest }) => ({ ...rest }));
      await hitApi(newData);
      setIsDataChanged(false);
    }
  };

  // /**
  //  * get selected rows
  //  **/
  function onRowInserted(e) {
    e.component.navigateToRow(e.key);
  }

  // /**
  //  * @param e
  //  * function to perform actions on row click
  //  **/
  const onRowClick = (e) => {
    if (e && e.hasOwnProperty("data")) {
      // e["data"]["workflowStepTableData"] = workflowStepTableData;
    }
  };

  // /**
  //  * @param e
  //  * initialize new row in the data-grid
  //  **/
  const onInitNewRow = (e) => {
    e.data.newRow = true;
    e.data.status = true;
    setIsDataChanged(false);
    let gridInstance = dataGridRef.current.instance;
    gridInstance.expandRow((e) => e.component.collapseAll(-1));
  };

  // /**
  //  * handle save rows
  //  **/
  function onSave(e) {
    if (
      e &&
      e.changes &&
      e.changes.length &&
      (e.changes[0].type === "insert" || e.changes[0].type === "update")
    ) {
      const data = e.changes[0].data;
      let rows = [];
      let result = [];
      if (notificationsManagement.rows.length) {
        result = [data, ...notificationsManagement.rows];
        if (e.changes[0].type === "update") {
          result = [...notificationsManagement.rows, data];
        }
        rows = [...new Map(result.map((item) => [item["sequence"], item])).values()];
      } else {
        rows.push(data);
      }

      dispatch({
        type: SET_MANAGE_NOTIFICATIONS,
        payload: { ...notificationsManagement, rows: rows },
      });
      setIsDataChanged(true);
    }
  }

  // /**
  //  * custom function using useMemo to avoid re-renders unless the states listed are changed
  //  **/
  const Comp = useMemo(() => {
    return (
      <>
        <div id="data-grid-demo">
          <DataGrid
            cacheEnabled
            onSaved={onSave}
            dataSource={dataSource}
            showColumnLines
            showRowLines
            rowAlternationEnabled
            ref={dataGridRef}
            onInitNewRow={onInitNewRow}
            allowColumnResizing
            disabled={isLoading}
            keyExpr="sequence"
            showBorders
            onRowClick={onRowClick}
            onRowExpanding={onRowExpanding}
            onKeyDown={onKeyDown}
            onToolbarPreparing={onToolbarPreparing}
            onRowInserted={onRowInserted}
            repaintChangesOnly
          >
            <HeaderFilter visible allowSearch />
            <SearchPanel visible />
            <Paging defaultPageSize={25} />
            <Pager visible showNavigationButtons showInfo displayMode={"full"} />
            <Scrolling showScrollbar="always" mode="standard" />
            <Export enabled allowExportSelectedData />
            <MasterDetail
              enabled
              component={(props) => (
                <NotificationStep
                  data={props.data}
                  routeKey={routeKey}
                  updateButtonStateOfParent={setIsDataChanged}
                  permissions={permissions}
                  tablesList={notificationsManagement.tableData}
                  dropDownData={notificationsManagement.accordionDropdownData}
                />
              )}
            />
            <Editing
              newRowPosition={"first"}
              refreshMode={"repaint"}
              mode="cell"
              allowUpdating={permissions && permissions.canCreate}
              allowAdding={permissions && permissions.canCreate}
              allowDeleting={allowDeleting}
            >
              <Form>
                <SimpleItem dataField={"name"} visible />
                <SimpleItem dataField={"description"} visible />
                <SimpleItem dataField={"masterModuleId"} visible />
                <SimpleItem dataField={"status"} visible />
              </Form>
            </Editing>
            {dataColumns && dataColumns.length > 0 ? renderField(dataColumns, dropDownData) : null}
          </DataGrid>
        </div>
      </>
    );
  }, [dataSource, dataColumns, isDataChanged, dropDownData, isLoading, rows]);

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setIsDataChanged} isDataChanged={isDataChanged} />
      {Comp}
    </React.Fragment>
  );
}

export default ManageNotificationGrid;
