import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDAlert from "components/MDAlert";
import {
  handleDeleteRequest, DEButton
} from "utils/services/Helpers";
import { toast } from "react-toastify";
import MDTypography from "components/MDTypography";
import "assets/datatable-css/index.css"
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import DataGrid, {
  AsyncRule, Button,
  Column,
  Export, HeaderFilter, Lookup, Pager, Paging,
  RequiredRule, SearchPanel,
  Selection, LoadPanel
} from "devextreme-react/data-grid";
import {
  CustomDateBox,
  CustomDTag, CustomNumberBox,
} from "../../../../components/CustomDataGridComponents";
import useApprovals from "../../../../utils/hooks/useApprovals";
import CircularProgress from '@mui/material/CircularProgress';
import { SET_MANAGE_JOBS } from "../../../../utils/context/store/Constants";
import { Context } from "../../../../utils/context/store/Store";

const ManageJobsDataGrid = ({ columns, rows, isLoading, fetchFromApi, handleDelete, canAdd = true, isDeleteable = true, dropdownValues = null, permissions, handleApproveOrReject, hasMore }) => {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [{ manageJobs }, dispatch] = useContext(Context)
  const dataGridRef = useRef();
  const { approveOrReject } = useApprovals()
  let navigate = useNavigate();

  useEffect(() => {

    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
      dispatch({
        type: SET_MANAGE_JOBS,
        payload: {}
      })
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => { setDataColumns(columns) }, [columns]);// use for confirm button on approve and reject

  /**
 * @param selectedRowKeys
 * @param selectedRowsData
 * used to get selected rows detail of data-grid
 **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
  * get selected rows
  **/
  const hasSelected = selectedRowKeys.length > 0;

  /**
* @param col
* function use to handle rendering of fields
**/
  function renderField(col, dropdownValues) {
    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
          setCellValue={function (rowData, value) {

            if (col.dataIndex === "masterOrgDivisionId") {
              rowData['masterOrgEntityId'] = null
              rowData['masterCountryId'] = null
              rowData['masterOrgVerticalId'] = null
              rowData['masterOrgFunctionId'] = null
              rowData['masterLevelId'] = null
            }
            if (col.dataIndex === "masterJobTypeId") {
              let gridInstance = dataGridRef.current.instance;
              let editRowKey = gridInstance.option("editing.editRowKey");
              let index = gridInstance.getRowIndexByKey(editRowKey);
              const jobType = gridInstance.cellValue(index, "masterJobTypeId");
              const status = gridInstance.cellValue(index, "status");

              if (!status && jobType) {
                if (jobType !== value) {
                  if (value === 2) {
                    rowData['master_clients'] = []
                    rowData['billable'] = false
                    rowData['hireDate'] = null
                    rowData['critical'] = false
                    // rowData['revenue'] = null
                  }

                  if (value === 1) {
                    rowData['masterGigId'] = []
                    rowData['billable'] = false
                    rowData['startDate'] = null
                    rowData['endDate'] = null
                    rowData['justification'] = null
                    // rowData['revenue'] = null
                  }
                }
                // rowData['masterClients'] = []
              }
            }

            if (col.hasOwnProperty("bindedTo"))
              rowData[col.bindedTo] = null;
            this.defaultSetCellValue(rowData, value);
          }}>
          {
            col.required ? <RequiredRule /> : null
          }
          {
            col.dataIndex === "masterLevelId" && col.required ? <AsyncRule
              message={"No JD found with the combination"}
              validationCallback={async (e) => {
                if (e && e.data && e.data.hasOwnProperty('jd_transaction')) {
                  return true
                }
                else {
                  let gridInstance = dataGridRef.current.instance;
                  let editRowKey = gridInstance.option("editing.editRowKey");
                  let index = gridInstance.getRowIndexByKey(editRowKey);
                  const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");
                  const masterOrgEntityId = gridInstance.cellValue(index, "masterOrgEntityId");
                  const masterCountryId = gridInstance.cellValue(index, "masterCountryId");
                  const masterOrgVerticalId = gridInstance.cellValue(index, "masterOrgVerticalId");
                  const masterOrgFunctionId = gridInstance.cellValue(index, "masterOrgFunctionId");
                  const status = gridInstance.cellValue(index, "status");
                  if (masterOrgDivisionId && masterOrgVerticalId && masterOrgFunctionId) {
                    const combination = { masterOrgDivisionId, masterOrgFunctionId, masterOrgVerticalId, masterLevelId: e.value }

                    if (masterOrgEntityId) {
                      combination['masterOrgEntityId'] = masterOrgEntityId
                    }
                    if (masterCountryId) {
                      combination['masterCountryId'] = masterCountryId
                    }

                    const hasJd = await getJDByCombination(combination, false, null);
                    if (typeof hasJd === "object" && Object.keys(hasJd).length) {
                      jdObj.transactionJdId = hasJd.transactionJdId
                      jdObj.job_name = hasJd.job_name
                      jdObj.view_jd = hasJd.job_name
                      jdObj.hasJd = true
                      e.data['transactionJdId'] = hasJd.transactionJdId
                      e.data['hasJd'] = true
                      return true
                    }
                    else {
                      delete e.data['transactionJdId']
                      e.data['hasJd'] = false
                      return false
                    }
                  }
                  else {
                    return false
                  }
                }

              }} /> : null
          }
          <Lookup allowClearing
            dataSource={dropdownValues && dropdownValues.hasOwnProperty(col.dataIndex) ? dropdownValues[col.dataIndex] : []}
            displayExpr="label" valueExpr="id" />
        </Column>;
      }
      else if (col.hasOwnProperty("filtrationKey")) {
        if (col.dataIndex === "masterOrgVerticalId") {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getVerticalsByEntityAndDivision(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else if (col.dataIndex === "masterOrgFunctionId") {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getFunctionsByVerticals(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else if (col.dataIndex === "masterCountryId") {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.dataIndex === "masterCountryId") {
                rowData['masterLevelId'] = null
                rowData['masterOrgVerticalId'] = null
                rowData['masterOrgFunctionId'] = null
              }
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getCountriesByDivisionOrEntity(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else if (col.dataIndex === "masterOrgEntityId") {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.dataIndex === "masterOrgEntityId") {
                rowData['masterLevelId'] = null
                rowData['masterCountryId'] = null
                rowData['masterOrgVerticalId'] = null
                rowData['masterOrgFunctionId'] = null
              }
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return getEntitiesByDivision(options)
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
        else {
          return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
            allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
              this.defaultSetCellValue(rowData, value);
              if (col.hasOwnProperty("bindedTo")) {
                rowData[col.bindedTo] = null;
              }
            }}>
            <Lookup allowClearing dataSource={(options) => {
              return {
                store: dropdownValues && dropdownValues.hasOwnProperty(col.dataIndex) ? dropdownValues[col.dataIndex] : [],
                filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
              };
            }} displayExpr="label" valueExpr="id" />
            {
              col.required ? <RequiredRule /> : null
            }
          </Column>;
        }
      }
    }
    else if (col.type === "multi-select") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTag}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === "search" && typeof (filterValue) === "string") {
            return [col.dataIndex, "contains", filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
        <Lookup allowClearing
          dataSource={dropdownValues && dropdownValues.hasOwnProperty(col.dataIndex) ? dropdownValues[col.dataIndex] : null}
          displayExpr="label" valueExpr="id" />
      </Column>;
    }
    else if (col.type === "checkbox" || col.type === "toggle") {
      return <Column dataType="boolean"
        showEditorAlways allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false}>
        <Button name="view" icon={'eye'} component={(props) => {
          return <>
            {
              permissions && (permissions.canCreate || permissions.canView)
                ?
                <Link to={`/views/job/${props.data.data.task_code}/version/${props.data.data.version}${props.data.data?.delegatedUserId ? `?dg=${props.data.data?.delegatedUserId}` : ""}`} state={props.data.data}>
                  <DEButton stylingMode={"contained"} type={"normal"} text={"View"} />
                </Link>
                : null
            }
          </>
        }}
        />
      </Column>
    }
    else if (col.type === "date") {
      return <Column format={"dd-MM-yyyy"} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex === "endDate" ? <AsyncRule
            message="EndDate should be greater than StartDate"
            validationCallback={async (e) => {
              return e.value > e.data.startDate
            }}
          /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column dataType={col.type} editCellComponent={CustomNumberBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule message={"Invalid value or value too long"} validationCallback={async (e) => {
          return e.value.toString().length <= 10
        }} />
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
* @param e
* function use to prepare toolbar
**/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "add",
          // text: "SUBMIT",
          disabled: !canAdd,
          visible: canAdd,
          onClick: () => navigate('/views/job-description')
        }
      }
    );
  }

  // bulk delete using row selection
  const manageDelete = async () => {
    const final = selectedRowKeys.filter((val) => val.status === 1);
    if (final && final.length > 0) {
      toast.error(`${final.length} records cannot be deleted as it has already been submitted!`);
    } else {
      const result = selectedRowKeys.map(a => a.id);
      handleDeleteRequest(async () => await handleDelete(result));
    }
    setSelectedRowKeys([]);
    await fetchFromApi();
  };

  /**
    * @param type {'approve' | 'reject'}
    * call approve or reject api
  **/
  const handleApproveReject = (type) => {
    approveOrReject(
      type == 'approve',
      selectedRowKeys.filter((e) => !e.approved),
      'jd_transaction',
      1,
      setSelectedRowKeys,
      fetchFromApi,
      'needApproval'
    )
  }

  /**
    * custom function using useMemo to avoid re-renders unless the states listed are changed
  **/
  const Comp = useMemo(() => {
    try {
      return (
        <div id="data-grid-demo">
          {hasSelected > 0 ? (
            <React.Fragment>
              <br />
              <MDAlert color="light">
                <MDTypography variant="subtitle2">
                  {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? 'item' : 'items'
                    }`}
                </MDTypography>

                <Divider orientation="vertical" color="dark" flexItem />

                <MDBox>
                  <Grid container spacing={2}>
                    {permissions &&
                      permissions.canApprove &&
                      dataSource?.length &&
                      selectedRowKeys?.some((d) => d?.needApproval === true) ? (
                      <>
                        {selectedRowKeys.some((e) => !e.approved && e.status) && (
                          <>
                            <Grid item>
                              <DEButton
                                stylingMode={'contained'}
                                type={'success'}
                                text={'Approve'}
                                onClick={(e) =>
                                  handleApproveReject('approve')
                                }
                              />
                            </Grid>
                            <Grid item>
                              <DEButton
                                stylingMode={'contained'}
                                type={'danger'}
                                text={'Reject'}
                                onClick={(e) =>
                                  handleApproveReject('reject')
                                }
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    ) : null}
                    {isDeleteable ? (
                      permissions && permissions.canDelete ? (
                        <Grid item>
                          <DEButton
                            stylingMode={'contained'}
                            type={'danger'}
                            icon="trash"
                            onClick={() => manageDelete()}
                          />
                        </Grid>
                      ) : null
                    ) : null}
                  </Grid>
                </MDBox>
              </MDAlert>
            </React.Fragment>
          ) : (
            ''
          )}
          {
            !isLoading ?
              <DataGrid
                onToolbarPreparing={onToolbarPreparing}
                showColumnLines showRowLines rowAlternationEnabled ref={dataGridRef} onSelectionChanged={onSelectionChanged}
                allowColumnResizing dataSource={dataSource} key="id" keyExpr="id" showBorders>
                <LoadPanel enabled={isLoading} visible={isLoading} />
                <Selection
                  allowSelectAll
                  mode="multiple"
                  selectAllMode={'allPages'}
                  showCheckBoxesMode={'always'}
                />
                <HeaderFilter visible allowSearch />
                <SearchPanel visible={true} />
                <Paging defaultPageSize={25} />
                <Pager
                  visible
                  showNavigationButtons
                  showInfo
                  displayMode={'full'}
                />
                <Export enabled allowExportSelectedData />
                {dataColumns && dataColumns.length
                  ? dataColumns.map((d) => renderField(d, dropdownValues))
                  : null}
              </DataGrid>
              : null
          }
        </div>
      );
    }
    catch (e) { }
  }, [dataSource, dataColumns, dropdownValues, selectedRowKeys, isLoading, hasMore])

  return (
    <React.Fragment>
      {Comp}
    </React.Fragment>
  );
};

export default ManageJobsDataGrid;
