import React, { useContext, useEffect, useState } from "react"
import { Context } from "utils/context/store/Store";
import useJobs from 'utils/hooks/useJobs';
import usePagePermissions from "utils/hooks/usePagePermissions";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Switch } from "antd";
import Grid from "@mui/material/Grid";
import useDataAccess from "utils/hooks/useDataAccess";
import { getUserInfo } from "utils/services/Helpers";
import ManageJobsDataGrid from "./components/DataTable/ManageJobsDataGrid";
import useScroll from "../../utils/hooks/useScroll";
import { SET_MANAGE_JOBS } from "../../utils/context/store/Constants";
import loadingGif from "assets/images/gifs/loading.gif";
import { Image } from 'antd';

function ManageJobs({ routeKey, pageName }) {

  const { getJobs, deleteJobs, approveOrRejectJobs, isLoading, hasMore } = useJobs(routeKey)
  const { getUserDataAccessByModule } = useDataAccess(routeKey)
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const [{ manageJobs }, dispatch] = useContext(Context)

  const [dataSource, setDataSource] = React.useState(manageJobs.rows ?? []);
  const [searchStatus, setSearchStatus] = useState(0);
  const [searchApproval, setSearchApproval] = useState(0);
  const scrollPosition = useScroll()

  useEffect(async () => {
    await getUserDataAccessByModule(getUserInfo()?.id, 1)
    setDataSource([])
    await getJobs()
    return () => {
      setDataSource([])
      dispatch({
        type: SET_MANAGE_JOBS,
        payload: {}
      })
    }
  }, [])
  useEffect(() => { }, [manageJobs])
  useEffect(() => {
    setDataSource(manageJobs.rows ?? [])
  }, [manageJobs])
  useEffect(() => { }, [dataSource])
  useEffect(async () => {
    if (scrollPosition >= document.body.offsetHeight - window.innerHeight && !isLoading) {
      // await getJobs()
    }
  }, [scrollPosition])

  return (
    <BaseLayOut pageTitle={"Manage Jobs"}>
      <MDBox mt={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Switch checked={searchApproval}
              onChange={(checked) => {
                setSearchApproval(wasOpened => wasOpened ? 0 : 1)
                if (searchStatus === 1) {
                  setSearchStatus(0)
                }
                if (checked) {
                  const newData = manageJobs.rows ?? []
                  const filter = newData.filter(obj => obj.status === 1 && obj.is_live === 0)
                  setDataSource(filter)
                } else {
                  setDataSource(manageJobs.rows ?? [])
                }
              }}
            />
          </Grid>
          <Grid item>
            <MDTypography variant="body2">
              Pending Jobs
            </MDTypography>
          </Grid>
          <Grid item>
            <Switch checked={searchStatus}
              onChange={(checked) => {
                setSearchStatus(wasOpened => wasOpened ? 0 : 1)
                if (searchApproval === 1) {
                  setSearchApproval(0)
                }
                if (checked) {
                  const newData = manageJobs.rows ?? []
                  const filter = newData.filter(obj => obj.is_live === 1)
                  setDataSource(filter)
                } else {
                  setDataSource(manageJobs.rows ?? [])
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <MDTypography variant="body2">
              Active Jobs
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <ManageJobsDataGrid isLoading={isLoading} columns={manageJobs?.columns ?? []}
                          rows={dataSource} fetchFromApi={getJobs}
                          handleDelete={deleteJobs} isDeleteable={true}
                          permissions={getPermissionsForPage(routeKey)}
                          handleApproveOrReject={approveOrRejectJobs} hasMore={hasMore} />
    </BaseLayOut>
  );
}

export default ManageJobs;
