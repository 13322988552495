import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'utils/context/store/Store';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Card from '@mui/material/Card';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import useBulkUpload from 'utils/hooks/useBulkUpload';
import StructureManagementDataGrid from "../../structure-management/DataTable/StructureManagementDataGrid";
import useStructureManagement from "../../../utils/hooks/useStructureManagement";

let value = "department"
function StructureManagement({ routeKey, pageName }) {
  const { bulkUpload } = useBulkUpload(routeKey);
  const {
    getStructureManagement,
    deleteStructureManagement,
    updateOrCreateStructureManagement,
    isLoading
  } = useStructureManagement(routeKey);
  const [{ structureManagement }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const tablesForBulkUpload = ['department'];

  useEffect(async () => {
    await getStructureManagement(value);
  }, []);

  useEffect(() => {}, [structureManagement]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox pt={3} pl={3} pr={3} pb={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
              {pageName.replace(/-/g, ' ') ?? 'Manage Agency/Practice'}
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pl={3} pr={3} pb={3}>
            <MDBox sx={{ width: '100%' }}>
              <StructureManagementDataGrid
                isLoading={isLoading}
                rows={structureManagement.rows ?? []}
                columns={structureManagement.columns}
                permissions={getPermissionsForPage(routeKey)}
                dropDownData={structureManagement.dropdownValues}
                postData={updateOrCreateStructureManagement}
                handleDelete={deleteStructureManagement}
                valueToFetch={value}
                allowSelection
                allowAdding={value !== 'entity-country-hfm'}
                allowDeletingFromApi={value !== 'entity-country-hfm'}
                bulkUploadApi={bulkUpload}
                apiCallback={getStructureManagement}
                tableName={structureManagement.tableName}
                uploadTemplateLink={structureManagement.uploadTemplateLink}
                orgStructureLink={structureManagement.orgStructureLink}
                allowBulkUploading={tablesForBulkUpload.indexOf(value) !== -1}
              />
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StructureManagement;
