import React, { useContext, useEffect } from "react";
import { Context } from "utils/context/store/Store";
import useNavListing from 'utils/hooks/useNavListing';
import NavigationDataGrid from "views/ui-management/DataGrid/NavigationDataGrid";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import usePagePermissions from "../../utils/hooks/usePagePermissions";

function ManageNavListing({ routeKey, pageName }) {

  const { getNavListing, updateOrCreateNav, isLoading } = useNavListing(routeKey)
  const [{ navListing }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)

  useEffect(async () => {
    await getNavListing()
  }, [])

  return (
    <BaseLayOut pageTitle={pageName && pageName === 'ui-management' ? "UI Management" : pageName ?? 'Manage UI Properties'}>
      <NavigationDataGrid isLoading={isLoading} columns={navListing.columns ?? []} rows={navListing.rows ?? []}
        permissions={getPermissionsForPage(routeKey)} dropDownData={navListing.dropdownValues ?? []} hitApi={updateOrCreateNav}
        allowAdding={false} allowDeletingFromApi={false} pagePropertyColumns={navListing.pagePropertyColumns ?? []} getNavListingOfParent={getNavListing} />
    </BaseLayOut>
  );
}

export default ManageNavListing;