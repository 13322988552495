import request from "../services/Http"
import { useContext } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_NAV_LISTING } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setColumnsForDataTable, isJSONValid, getPageProperties, getTableColumns } from "../services/Helpers";
import { useNavigate } from "react-router-dom";
import { useIsLoading } from "./useIsLoading";

export default function useNavListing(routeKey) {

    const [{ navListings, pagesAndPermissions }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()
    let navigate = useNavigate();

    async function getNavListing() {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.get('nav-listing')
            const properties = getPageProperties(pagesAndPermissions, routeKey)
            const table = getTableColumns(properties.page.table, routeKey)
            let columns = table.columns
            let rows = isJSONValid(res.data.data) ?? {}
            let dropdownValues = isJSONValid(res.data.dropdownValues)
            let pagePropertyColumns = getTableColumns(properties.page.table, "page-properties").columns
            rows.forEach((row) => {
                row.sequence = row.id
                row.index = row.id
                row.key = row.id
            })
            const dataTableData = { columns: setColumnsForDataTable(columns, dropdownValues), rows: rows, dropdownValues: dropdownValues, pagePropertyColumns: setColumnsForDataTable(pagePropertyColumns) }
            dispatch({ type: SET_NAV_LISTING, payload: dataTableData });
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function updatePageProperty(data, nav) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.put(`nav-listing/${nav}`, {
                data: data
            })
            //await getNavListing()
            toast.success(res.data.data);
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function updateOrCreateNav(data, val) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.post('nav-listing', {
                nav: data
            })
            await getNavListing()
            toast.success(res.data.data)
        }
        catch (e) { }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    return { getNavListing, updatePageProperty, updateOrCreateNav, isLoading }
}