import request from "../services/Http";
import { useContext, useEffect } from "react";
import { Context } from "../context/store/Store";
import {
  addKeyAndIndexFieldToGenericArrayOfObjects,
  getPageProperties, getTableColumns, isJSONValid,
  setColumnsForDataTable,
} from "../services/Helpers";
import { SET_IS_LOADING, SET_USERS } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIsLoading } from "./useIsLoading";

export default function useUsers(routeKey) {

  const [{ users, pagesAndPermissions }, dispatch] = useContext(Context)
  const { isLoading, setIsLoading } = useIsLoading()

  async function fetchUsers() {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const res = await request.get('user')
      if (res) {
        let rows = res.data.data
        let orgRows = JSON.parse(JSON.stringify(res.data.data));
        const properties = getPageProperties(pagesAndPermissions, routeKey)
        const table = getTableColumns(properties.page.table, routeKey)
        let columns = table.columns
        const dropdownValues = res.data.dropdownValues
        const finalObj = isJSONValid(table.row.defaultObj)
        const dropdownList = isJSONValid(properties.page.elements.dropdowns)
        let uploadTemplateLink = res.data.uploadTemplate ?? null
        let orgStructureLink = res.data.orgStructureLink ?? null
        let tableName = res.data.tableName ?? null

        if (users && users.hasOwnProperty('apiDelete') && users.apiDelete) {
          rows = [...users.rows, ...rows]
        }
        const dataTableData = { columns: setColumnsForDataTable(columns, dropdownValues), rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows), newRowObject: finalObj, dropdownValues: dropdownValues, apiDelete: false, dropdownList: dropdownList, orgRows, uploadTemplateLink, orgStructureLink, tableName }
        dispatch({
          type: SET_USERS,
          payload: dataTableData
        })
      }
    }
    catch (e) { }

    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function updateOrCreateAccess(data) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const res = await request.post('user', {
        users: data
      })
      toast.success(res.data.data)
      await fetchUsers()
    }
    catch (e) { }
    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  async function deleteUsers(users) {
    setIsLoading(true)
    dispatch({ type: SET_IS_LOADING, payload: true })
    try {
      const res = await request.delete('user', {
        data: {
          userIds: users
        }
      })
      if (res) {
        await fetchUsers()
        toast.success(res.data.data)
      }
    }
    catch (e) { }
    setIsLoading(false)
    dispatch({ type: SET_IS_LOADING, payload: false })
  }

  return { fetchUsers, updateOrCreateAccess, deleteUsers, isLoading }
}