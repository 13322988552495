import request from "../services/Http"
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import { SET_JD_VIEW, SET_IS_LOADING, SET_JD_PAGE, SET_JD_PAGE_LEVELS } from "../context/store/Constants";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isJSONValid } from "../services/Helpers";
import useJobs from "./useJobs";
import { useLocation, useNavigate } from "react-router-dom";

export default function useJobDescriptionPage() {

    const [jd_values, setJDValues] = useState({})
    const [canApprove, setCanApprove] = useState(false)
    const [{ jdTransaction }, dispatch] = useContext(Context)
    const { getJobs } = useJobs()
    let navigate = useNavigate();
    const search = useLocation().search;
    const dgId = new URLSearchParams(search).get("dg");

    async function getJobDescriptionPage() {
        dispatch({ type: SET_IS_LOADING, payload: true })

        try {
            const res = await request.get(`jd-transaction`)
            dispatch({ type: SET_JD_PAGE, payload: isJSONValid(res.data.data) ?? {} });
        }
        catch (e) { }

        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function getJDByLevel(level) {
        dispatch({ type: SET_IS_LOADING, payload: true })

        try {
            const res = await request.get(`jd-transaction/${level}`)
            dispatch({
                type: SET_JD_PAGE_LEVELS,
                payload: isJSONValid(res.data.data) ?? {}
            });
        }
        catch (e) { }

        dispatch({ type: SET_IS_LOADING, payload: false })

    }

    async function updateOrCreateJobDescriptionPage(data, jdType = false, route) {
        let flag = false
        dispatch({ type: SET_IS_LOADING, payload: true })
        try {
            const res = await request.post('jd-transaction', { body: data, type: jdType })
            await getJobs()
            toast.success(res.data.data)
            navigate(route)
            flag = true
        }
        catch (e) { }

        dispatch({ type: SET_IS_LOADING, payload: false })


        return flag
    }

    async function getValueByTransaction(transaction) {

        dispatch({ type: SET_IS_LOADING, payload: true })

        try {
            const res = await request.get(`jd-transaction/${transaction}/value`)
            let rows = isJSONValid(res.data.data) ?? {}
            let levelData = isJSONValid(res.data.levelData) ?? {}
            let jdval = {}
            rows.forEach((seg) => {
                seg.elements.forEach((ele) => {
                    let element_score = 0
                    ele.jd_values.forEach((val) => {
                        jdval = {
                            id: val.id,
                            element_score: val.element_score,
                            masterJdElementId: val.masterJdElementId,
                            value: val.value,
                            read_only: val.read_only,
                            transactionJdId: val.transactionJdId
                        }
                        element_score = val.element_score

                    })
                    ele.value = jdval
                    ele.element_score = element_score
                    const [levelValue] = levelData.filter(obj => { return obj.masterJdElementId === ele.id })
                    ele.masterLevelId = levelValue && levelValue.masterLevelId ? levelValue.masterLevelId : null
                })
            })
            setJDValues(rows)
            setCanApprove(res.data.canApprove)
        }
        catch (e) { }

        dispatch({ type: SET_IS_LOADING, payload: false })

    }

    async function getJDById(id, isForJD=false, isLocationNull=false, version) {
        dispatch({ type: SET_IS_LOADING, payload: true })
        dispatch({ type: SET_JD_VIEW, payload: {} })
        try {
            const res = await request.get(`headcount/request/jd/${id}/${version}`, { params: { isForJD, masterModuleId: isForJD ? 1 : 3 } })
            let data = res.data.data
            let value = res.data.value

            dispatch({ type: SET_JD_VIEW, payload: { data: data, value: value, jdDropdownData: res.data.jdDropdownData } })

            if (isForJD && res)
                await getValueByTransaction(data.id)

            if (isLocationNull && res)
                navigate(`/views/job/${id}/version/${version}${dgId ? `?dg=${dgId}` : ""}`, { state: data });



        }
        catch (e) { }

        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    async function deleteJobDescriptionPage(transaction) {
        dispatch({ type: SET_IS_LOADING, payload: true })

        try {
            const res = await request.delete(`jd-transaction/${transaction}`)
            await getJobs()
            toast.success(res.data.data);
            navigate('/views/manage-jobs')
        }
        catch (e) {
        }

        dispatch({ type: SET_IS_LOADING, payload: false })

    }

    async function withdrawJob(transaction) {
        try {
            const res = await request.put(`jd-transaction/${transaction}/withdraw`)
            toast.success(res.data.data);
            await getJobs()
            navigate('/views/manage-jobs')
        }
        catch (e) {

        }
    }

    return { getJobDescriptionPage, getJDByLevel, getValueByTransaction, getJDById, updateOrCreateJobDescriptionPage, deleteJobDescriptionPage, withdrawJob, jd_values, canApprove }
}
