import request from "../services/Http";
import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING, SET_MANAGE_NOTIFICATIONS } from "../context/store/Constants";
import {
  setColumnsForDataTable,
  isJSONValid,
  addKeyAndIndexFieldToGenericArrayOfObjects,
  getPageProperties,
  getTableColumns,
} from "../services/Helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useIsLoading } from "./useIsLoading";

const API_ENDPOINT = "notif-mgt";

export default function useManageNotifications(routeKey) {
  const { isLoading, setIsLoading } = useIsLoading();
  const [{ notificationsManagement, pagesAndPermissions }, dispatch] = useContext(Context);
  const [records, setRecords] = useState(null);

  async function getNotifications() {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    dispatch({
      type: SET_MANAGE_NOTIFICATIONS,
      payload: [],
    });
    try {
      const res = await request.get(API_ENDPOINT);
      const properties = getPageProperties(pagesAndPermissions, routeKey);
      const table = getTableColumns(properties.page.table, "notification");
      const notificationStepTable = getTableColumns(properties.page.table, "notification-step");
      const columns = table.columns;
      const notificationStepColumns = notificationStepTable.columns;
      let rows = isJSONValid(res.data.data) ?? {};
      const dropdownValues = isJSONValid(res.data.dropdownValues) ?? {};
      const accordionDropdownData = isJSONValid(res.data.accordionDropdownValues) ?? {};
      const tableData = isJSONValid(res.data.tableData) ?? {};
      if (
        notificationsManagement &&
        notificationsManagement.hasOwnProperty("apiDelete") &&
        notificationsManagement.apiDelete &&
        notificationsManagement.rows &&
        notificationsManagement.rows.length
      ) {
        rows = [...rows, ...notificationsManagement.rows];
      }
      const dataTableData = {
        columns,
        rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows),
        dropdownValues,
        apiDelete: false,
        accordionDropdownData,
        tableData,
        notificationStepColumns,
      };
      dispatch({
        type: SET_MANAGE_NOTIFICATIONS,
        payload: dataTableData,
      });
    } catch (e) {}
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function getRecordByField(table, field) {
    setIsLoading(true);
    setRecords(null);
    try {
      const res = await request.get(`master-table/${table}/${field}`);
      let rows = isJSONValid(res.data.data) ?? {};
      setRecords(rows);
    } catch (e) {}
    setIsLoading(false);
  }

  async function updateOrCreateNotifications(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.post(API_ENDPOINT, { notifications: data });
      toast.success(res.data.data);
      await getNotifications();
    } catch (e) {}
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function deleteNotificationSteps(steps, notificationId = null) {
    setIsLoading(true);
    try {
      const res = await request.delete(API_ENDPOINT, {
        data: {
          steps,
          notificationId,
        },
      });
      toast.success(res.data.data);
      await getNotifications();
    } catch (e) {}
    setIsLoading(false);
  }

  return {
    getNotifications,
    deleteNotificationSteps,
    isLoading,
    getRecordByField,
    updateOrCreateNotifications,
    records,
  };
}
