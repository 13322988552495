import { useState } from 'react';
import './toggle-button.scss';

export const ToggleButton = ({ setSelectedOption }) => {
  const options = ['Tree View', 'Graph View'];
  const [activeOption, setActiveOption] = useState(options[0]);

  const handleButtonClick = (option) => {
    setActiveOption(option);
    setSelectedOption(option);
  };
  return (
    <div className="toggle-button">
      {options.map((option) => (
        <button
          key={option}
          onClick={() => handleButtonClick(option)}
          className={option === activeOption ? 'active' : ''}
        >
          {option}
        </button>
      ))}
    </div>
  );
};
