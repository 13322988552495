import { useContext, useEffect, useMemo, useRef, useState } from "react";
import DataGrid, {
  AsyncRule,
  Column, Editing, Export, HeaderFilter,
  Lookup, Pager, Paging,
  RequiredRule,
  Scrolling, SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { onRowExpanding } from "../../../../utils/services/DatagridHelpers";
import { CustomDateBox, CustomFileInput, CustomNumberBox, CustomTextArea } from "../../../CustomDataGridComponents";
import { handleDeleteRequest, DEButton, createSanitizeAsyncRule } from "../../../../utils/services/Helpers";
import * as React from "react";
import MDAlert from "../../../MDAlert";
import MDTypography from "../../../MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "../../../MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DeleteIcon from "@mui/icons-material/Delete";
import DetectNavigationBlocker from "../../../navigationdetector/DetectNavigationBlocker";
import { Context } from "../../../../utils/context/store/Store";

export default function PermissionDataGrid({ rows, columns, dropDownData, defaultKey = "id", isLoading, permissions, allowAdding = true, showSubmitButton = true, allowUpdating = true, allowSelection = false, allowDeleting = true, postData, handleDelete, masterModuleId, userId, showRestButtons = false, rolesObj, routeKey, isForNewRole, roleData, rolesDataSource }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [autoWidth, setAutoWidth] = useState(true)
  const dataGridRef = useRef();
  const [addEditMode, setAddEditMode] = useState(false)
  const [{ role_permissions }, dispatch] = useContext(Context)  // store role data to global state
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => {
    setDataColumns(columns)
  }, [columns]);
  useEffect(() => { }, [role_permissions])

  /**
   * @param e
   * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
   **/
  async function onEditorPreparing(e) {
    if (e && e.dataField === "masterOrgEntityId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");
      if (!masterOrgDivisionId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }

    if (e && e.dataField === "masterLevelId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgDivisionId = gridInstance.cellValue(index, "masterOrgDivisionId");

      if (!masterOrgDivisionId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false

    }

    if (e && e.dataField === "masterCountryId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterOrgEntityId = gridInstance.cellValue(index, "masterOrgEntityId");
      if (!masterOrgEntityId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }

    if (e && e.dataField === "masterOrgVerticalId") {
      let gridInstance = dataGridRef.current.instance;
      let editRowKey = gridInstance.option("editing.editRowKey");
      let index = gridInstance.getRowIndexByKey(editRowKey);
      const masterCountryId = gridInstance.cellValue(index, "masterCountryId");
      if (!masterCountryId)
        e.editorOptions.disabled = true
      else
        e.editorOptions.disabled = false
    }
  }

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    setSelectedRowKeys(selectedRowsData)
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
          setCellValue={
            function (rowData, value) {
              if (col.hasOwnProperty("bindedTo"))
                rowData[col.bindedTo] = null;
              this.defaultSetCellValue(rowData, value);
            }
          } >
          {
            col.required ? <RequiredRule /> : null
          }
          < Lookup allowClearing
            dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : []
            }
            displayExpr="label" valueExpr="id" />
        </Column>;
      }
      else if (col.hasOwnProperty("filtrationKey")) {
        return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
          allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
            this.defaultSetCellValue(rowData, value);
            if (col.hasOwnProperty("bindedTo")) {
              rowData[col.bindedTo] = null;
            }
          }}>
          <Lookup allowClearing dataSource={(options) => {
            return {
              store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
              filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
            };
          }} displayExpr="label" valueExpr="id" />
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>;
      }
    }
    else if (col.type === "multi-select") {
      return <Column width={"250"} editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTagThisComp}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === "search" && typeof (filterValue) === "string") {
            return [col.dataIndex, "contains", filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}
      >
        <Lookup allowClearing
          dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null}
          displayExpr="label" valueExpr="id" />
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "checkbox" || col.type === "toggle") {
      return <Column dataType="boolean"
        
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return null
    }
    else if (col.type === "date") {
      return <Column dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column dataType={col.type} /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} />}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} type={"buttons"} fixed={false} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomFileInput} />;
    }
    else if (col.type === "textarea") {
      return <Column encodeHtml={col.encodeHtml} editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.type === "string"){
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        {/* <AsyncRule {...sanitizeAsyncRule} /> */}
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    window.scrollTo(0, 0)
    e.data.newRow = true
    setAutoWidth(false)
    setAddEditMode(true)
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !hasDataChanged,
          visible: showSubmitButton,
          onClick: async () => {
            await postData(roleData?.id, dataSource)
            setHasDataChanged(false)
          }
        }
      }
    );
  }

  /**
   * @param e
   * Manage post api call to save data and validation for if any request is missing headcount while having replacement true
   **/
  function onSave(e) {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dsCopy = [...dataSource]
        const filteredDs = dsCopy.length ? dsCopy.filter(ds => ds.id !== e.changes[0].key) : []
        if (filteredDs && filteredDs.length) {
          setDataSource(filteredDs)
          setHasDataChanged(filteredDs.some(fd => fd.hasOwnProperty('newRow')))
        }

      }
      else {
        // if (e && e.changes[0].type === "update")
        //   e.changes[0]["data"]["rowEdited"] = true


        const dd = e.changes[0].data;

        if (dd.hasOwnProperty('newRow') && dd.status || dd.can_view || dd.can_create || dd.can_delete || dd.can_approve) {
          dd['toAdd'] = true
        }

        let finalData = []
        finalData.push(dd)
        let result = []

        if (dataSource && dataSource.length) {
          result = _.unionBy(dataSource, finalData);
        }
        else result.push(dd)
        setDataSource(result)
        if (isForNewRole) {
          const ds = [...dataSource]
          if (roleData.hasOwnProperty("page_permissions") && roleData.page_permissions.length) {
            const fData = [...roleData.page_permissions, ...result];
            roleData.page_permissions = [...new Map(fData.map(item => [item['key'], item])).values()];
          }
          else {
            roleData.page_permissions = [];
            roleData.page_permissions = result;
          }
        }

        setHasDataChanged(true)
      }
    }
    setAutoWidth(true)
    setAddEditMode(false)
  }

  /**
   * @param e
   * validate row before saving
   **/
  function onRowValidating(e) {
    if (e && e.isValid) {
      if (e.newData) {
        const { name } = e.newData
        if (name) {
          const d = [...dataSource];
          if (name && d.length) {
            const c = d.some((r) => r.name === name);
            if (c) {
              e.isValid = false;
              e.errorText = "Duplicate Combination Found";
            }

          }
        }
      }
    }
  }

  /**
   * function used to handle delete part of data-grid
   **/
  const manageDelete = async () => {
    const newRecords = dataSource.filter(ds => ds.hasOwnProperty('newRow'))
    let newData = [...dataSource]
    handleDeleteRequest(async () => {
      const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow'))
      const deleteFromTable = selectedRowKeys.filter(a => a.hasOwnProperty('newRow'))
      const result = deleteFromApi.map(a => a.id);
      if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {
        await handleDelete(result, userId, selectedRowKeys.length === dataSource.length, masterModuleId)
      }
      else if (deleteFromApi && deleteFromApi.length > 0) {
        await handleDelete(result, userId, selectedRowKeys.length === dataSource.length, masterModuleId)
      }
      else if (deleteFromTable && deleteFromTable.length > 0) {
        deleteFromTable.map(a => {
          newData = newData.filter((item) => item.key !== a.key);
        })
        setDataSource(newData)
      }
      setHasDataChanged(false)
      setSelectedRowKeys([])
    })
  };

  /**
 * custom function using useMemo to avoid re-renders unless the states listed are changed
 **/
  const Comp = useMemo(() => {
    try {
      return <div id="data-grid-demo">
        {
          hasSelected > 0
            ?
            <React.Fragment>
              <br />
              <MDAlert color="light">

                <MDTypography variant="subtitle2">
                  {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
                </MDTypography>

                <Divider orientation="vertical" color="dark" flexItem />

                <MDBox>
                  <Grid container spacing={2}>
                    {
                      permissions && permissions.canDelete
                        ? <Grid item >
                          <Button icon="trash" />
                          <DEButton stylingMode={"contained"} type={"danger"} icon="trash" onClick={() => manageDelete()} />
                        </Grid> : null
                    }
                  </Grid>
                </MDBox>
              </MDAlert>
            </React.Fragment>
            : null
        }
        <Card>
          <MDBox pt={2} pl={3} pr={3} pb={1}>
            <MDTypography variant="h6" fontWeight="medium">Pages</MDTypography>
          </MDBox>
          <MDBox pl={3} pr={3}>
            <DataGrid id="grid"
              onToolbarPreparing={onToolbarPreparing} showBorders={true}
              onRowExpanding={onRowExpanding}
              columnAutoWidth={true} onSaved={onSave}
              showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
              ref={dataGridRef} onInitNewRow={onInitNewRow}
              onSelectionChanged={onSelectionChanged} allowColumnResizing={true}
              disabled={isLoading} dataSource={dataSource} key={defaultKey} keyExpr={defaultKey}
              onEditorPreparing={onEditorPreparing}
              onRowValidating={onRowValidating}>
              {
                addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />
              }

              {
                allowSelection ?
                  <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
                  : null
              }
              <HeaderFilter visible={true} allowSearch={true} />
              <SearchPanel visible={true} />
              <Paging defaultPageSize={25} />
              <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
              <Export enabled={true} allowExportSelectedData={true} />
              <Editing newRowPosition={"first"} refreshMode={"repaint"} mode="cell" allowUpdating={permissions && permissions.canCreate && allowUpdating} allowAdding={permissions && permissions.canCreate && allowAdding} allowDeleting={allowDeleting} />
              {
                dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
              }
            </DataGrid>
          </MDBox>
        </Card>
      </div>
    }
    catch (e) {
      console.log('error from ORG STRUCTURE', e)
    }
  }, [dataSource, dataColumns, dropDownData, hasDataChanged, selectedRowKeys, isLoading, autoWidth, addEditMode])

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      {Comp}
    </React.Fragment>
  );
}