import * as React from 'react';
import { useContext, useState } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { ToggleButton } from '../toggle-button/toggle-button';
import { CapabilityContext } from '../../contexts/capability-context';
import { dataNodes } from '../../models/nodes.model';
import './filters.scss';

const verticalsOptions = dataNodes
  .filter((node) => node.id !== 'Parent Company' && node.parentId !== 'Parent Company' && node.children?.length)
  .map((e) => ({ label: e.id, value: e.id, parentId: e.parentId }))
  .sort((e, a) => e.label.localeCompare(a.label));

const capabilityOptions = dataNodes
  .filter((node) => !node.children?.length)
  .map((e) => ({ label: e.id, value: e.id, parentId: e.parentId }))
  .sort((e, a) => e.label.localeCompare(a.label));

const practiciesOptions = dataNodes
  .filter((node) => node.parentId === 'Parent Company')
  .map((e) => ({ label: e.id, value: e.id, parentId: e.parentId }))
  .sort((e, a) => e.label.localeCompare(a.label));

export const Filters = ({ selectedView }) => {
  const {
    selectedCompany,
    reset,
    hardReset,
    selectedPractice,
    selectedVertical,
    selectedCapability,
    setSelectedPractice,
    setSelectedCompany,
    setSelectedCapability,
    setSelectedVertical
  } = useContext(CapabilityContext);

  const [practicies, setPracticies] = useState(practiciesOptions);
  const [verticals, setVerticals] = useState(verticalsOptions);
  const [capabilities, setCapabilities] = useState(capabilityOptions);

  const onPracticeSelected = (value) => {
    handleReset();
    setSelectedPractice(value.label);
    const filteredVerticals = verticalsOptions.filter((e) => e.parentId === value.label);
    const filteredCapabilities = capabilityOptions.filter((e) =>
      filteredVerticals.map((e) => e.label).includes(e.parentId)
    );
    setVerticals(filteredVerticals);
    setCapabilities(filteredCapabilities);
    setSelectedCompany(value.label);
  };

  const onVerticalSelected = (value) => {
    handleReset();
    const filteredCapabilities = capabilityOptions.filter((e) => e.parentId === value.label);
    setCapabilities(filteredCapabilities);
    setSelectedVertical(value.label);
    setSelectedCompany(value.label);
  };

  const onCapabilitySelected = (value) => {
    handleReset();
    setSelectedCapability(value.label);
    setSelectedCompany(value.label);
  };

  function handleReset() {
    reset();
    setPracticies(practiciesOptions);
    setVerticals(verticalsOptions);
    setCapabilities(capabilityOptions);
  }

  function handleResetClicked() {
    hardReset();
    setPracticies(practiciesOptions);
    setVerticals(verticalsOptions);
    setCapabilities(capabilityOptions);
  }

  return (
    <header className="filters-navbar">
      <div className="filters-navbar__container">
        <div>
          <Dropdown
            id={'Entity'}
            options={practicies}
            onChange={onPracticeSelected}
            value={selectedPractice}
            placeholder={'Entity'}
          />
        </div>

        <div>
          <Dropdown
            options={verticals}
            onChange={onVerticalSelected}
            value={selectedVertical}
            placeholder={'Vertical'}
          />
        </div>

        <div>
          <Dropdown
            options={capabilities}
            onChange={onCapabilitySelected}
            value={selectedCapability}
            placeholder={'Capability'}
          />
        </div>

        <div>
          {selectedCompany && (
            <a className="clear-filters" onClick={handleResetClicked}>
              clear filters
            </a>
          )}
        </div>
        <div className="switch-view">
          <ToggleButton setSelectedOption={selectedView} />
        </div>
      </div>
    </header>
  );
};
