import React, { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'

import { Context } from "utils/context/store/Store";
import usePagePermissions from "utils/hooks/usePagePermissions";
import useJobs from "../../utils/hooks/useJobs";
import useDataAccess from "utils/hooks/useDataAccess";
import useJobDescriptionPage from 'utils/hooks/useJobDescriptionPage';
import {
  getUserInfo,
  isObjectEmpty,
  selectCustomStyles,
  handleDeleteRequest,
  getLevels,
  DEButton,
  reactSelectCustomPlaceHolderJD,
} from "utils/services/Helpers";

import BaseLayOut from "components/Layout/BaseLayOut";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from '@mui/material/Tooltip';
import { Checkbox } from "@mui/material";

import { Editor } from 'react-draft-wysiwyg';
import { toast } from "react-toastify";
import Select from 'react-select';
import { ContentState, convertToRaw, EditorState, convertFromHTML } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Swal from 'sweetalert2'

import { PDFDownloadLink } from '@react-pdf/renderer';
import ToPrint from './ToPrint'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'antd/dist/antd.css';
import 'draft-js/dist/Draft.css';
import 'assets/datatable-css/editorstyles.css'
import './styles.css'
import { sanitizeInput } from "utils/services/Helpers";

const _ = require('lodash')

const JobDescription = ({ routeKey, pageName }) => {
  const location = useLocation()
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const { getJobDescriptionPage, getJDByLevel, updateOrCreateJobDescriptionPage, deleteJobDescriptionPage, getValueByTransaction
    , withdrawJob, jd_values, canApprove } = useJobDescriptionPage()
  const { getUserDataAccessByModule } = useDataAccess(routeKey)
  const [{ jdPage, jdPageLevels, dataAccess, isLoading }, dispatch] = useContext(Context)
  const permissions = getPermissionsForPage(routeKey)
  const { deActivateJD } = useJobs(routeKey)

  const [formValues, setFormValues] = useState({});
  const [formSubmitValues, setFormSubmitValues] = useState({});
  const [jdData, setJDData] = useState({});
  const [copyJobObject, setCopyJobObject] = useState({});
  const [reviseJobObject, setReviseJobObject] = useState({});
  const [deletedValues, setDeletedValues] = useState([]);
  const [mainTaskColorBar, setMainTaskColorBar] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jdSubmit, setJDSubmit] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isDataAccessValid, setIsDataAccessValid] = useState(true);
  const [routePath, setRoutePath] = useState(null);
  const [inActiveJD, setInActiveJD] = useState(null)

  const initialValues = {
    userId: location.state && !isObjectEmpty(location.state) ? location.state.userId === null ? getUserInfo()?.id : location.state.userId : getUserInfo()?.id,
    id: location.state && !isObjectEmpty(location.state) ? location.state.id : 0,
    approvalId: location.state && !isObjectEmpty(location.state) ? location.state.approvalId : 0,
    masterLevelId: location.state && !isObjectEmpty(location.state) ? location.state.masterLevelName : '',
    masterOrgDivisionId: location.state && !isObjectEmpty(location.state) ? location.state.masterOrgDivisionName : '',
    masterOrgEntityId: location.state && !isObjectEmpty(location.state) ? location.state.masterOrgEntityName : '',
    masterCountryId: location.state && !isObjectEmpty(location.state) ? location.state.masterCountryName : '',
    masterOrgFunctionId: location.state && !isObjectEmpty(location.state) ? location.state.masterOrgFunctionName : '',
    masterOrgVerticalId: location.state && !isObjectEmpty(location.state) ? location.state.masterOrgVerticalName : '',
    job_name: location.state && !isObjectEmpty(location.state) ? location.state.job_name : '',
    approved: location.state && !isObjectEmpty(location.state) ? location.state.approved : false,
    status: location.state && !isObjectEmpty(location.state) ? location.state.status : false,
    is_temp: location.state && !isObjectEmpty(location.state) ? location.state.is_temp === null ? false : location.state.is_temp : false,
    is_live: location.state && !isObjectEmpty(location.state) ? location.state.is_live === null ? false : location.state.is_live : false,
    can_approve: location.state && !isObjectEmpty(location.state) ? location.state.can_approve : false,
    transactionJdId: location.state && !isObjectEmpty(location.state) ? location.state.transactionJdId : null,
    approver_comments: location.state && !isObjectEmpty(location.state) ? location.state.approver_comments : ''
  };

  const initialSubmitValues = {
    userId: location.state && !isObjectEmpty(location.state) ? location.state.userId === null ? getUserInfo()?.id : location.state.userId : getUserInfo()?.id,
    id: location.state && !isObjectEmpty(location.state) ? location.state.id : null,
    approvalId: location.state && !isObjectEmpty(location.state) ? location.state.approvalId : 0,
    masterLevelId: location.state && !isObjectEmpty(location.state) ? location.state.masterLevelId : '',
    masterOrgDivisionId: location.state && !isObjectEmpty(location.state) ? location.state.masterOrgDivisionId : '',
    masterOrgEntityId: location.state && !isObjectEmpty(location.state) ? location.state.masterOrgEntityId : '',
    masterCountryId: location.state && !isObjectEmpty(location.state) ? location.state.masterCountryId : '',
    masterOrgFunctionId: location.state && !isObjectEmpty(location.state) ? location.state.masterOrgFunctionId : '',
    masterOrgVerticalId: location.state && !isObjectEmpty(location.state) ? location.state.masterOrgVerticalId : '',
    job_name: location.state && !isObjectEmpty(location.state) ? location.state.job_name : '',
    approved: location.state && !isObjectEmpty(location.state) ? location.state.approved : false,
    status: location.state && !isObjectEmpty(location.state) ? location.state.status : false,
    is_temp: location.state && !isObjectEmpty(location.state) ? location.state.is_temp === null ? false : location.state.is_temp : false,
    is_live: location.state && !isObjectEmpty(location.state) ? location.state.is_live === null ? false : location.state.is_live : false,
    can_approve: location.state && !isObjectEmpty(location.state) ? location.state.can_approve : false,
    restricted: location.state && !isObjectEmpty(location.state) ? location.state.restricted : false,
    transactionJdId: location.state && !isObjectEmpty(location.state) ? location.state.transactionJdId : null,
    updated_by: location.state && !isObjectEmpty(location.state) ? location.state.updated_by : null,
    approver_comments: location.state && !isObjectEmpty(location.state) ? location.state.approver_comments : ''
  };

  useEffect(async () => {

    if (dataAccess && isObjectEmpty(dataAccess)) {
      await getUserDataAccessByModule(getUserInfo()?.id, 1)
    }
    setJDData({})
    if (location && location.state && location.state.id && location.state.id !== 0) {
      await getValueByTransaction(location.state.id)
    }
    else {
      await getJobDescriptionPage()
    }

    setFormValues({})
    setFormSubmitValues({})
    setMainTaskColorBar([])
    setFormValues(initialValues)
    setFormSubmitValues(initialSubmitValues)
  }, [])

  useEffect(() => {
    setDataFromGenericAPI()
  }, [jdPage])

  useEffect(() => {
    setDataFromGenericAPI()
  }, [jd_values])

  useEffect(async () => {
    if (location && location.state && location.state.formValues && location.state.formValues.id === 0) {
      setJDSubmit(false)
      if (location.state.formSubmitValues.transactionJdId) {
        setFormValues(location.state.formValues)
        setFormSubmitValues(location.state.formSubmitValues)
        await getJobDescriptionPage()
        await getJDByLevel(location.state.formSubmitValues.masterLevelId)
        toast.success('JD in Draft Mode!!')
      } else {
        setFormValues(location.state.formValues)
        setFormSubmitValues(location.state.formSubmitValues)
        toast.success('Job Copied Successfully!!')
      }
      window.scrollTo(0, 0)
    }
  }, [location])

  useEffect(() => {
    updateColorBar()
    checkIfDataAccessIsValid()
    copyJob()
    reviseJob()
  }, [formSubmitValues])

  useEffect(async () => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      await submit();
    }
  }, [formErrors]);

  useEffect(() => {
    setJDData(jdData)
  }, [jdData])

  useEffect(() => {
    if (formSubmitValues.masterLevelId !== '') {
      setDataFromLevelAPI(formSubmitValues.masterLevelId)
    }
  }, [jdPageLevels])

  useEffect(() => { }, [copyJobObject])
  useEffect(() => { }, [reviseJobObject])
  useEffect(() => { }, [isSubmitting])
  useEffect(() => { }, [routePath])
  useEffect(() => { }, [mainTaskColorBar])
  useEffect(() => { }, [inActiveJD])
  useEffect(() => { }, [dataAccess])
  useEffect(() => { }, [isDataAccessValid])
  useEffect(() => { }, [initialValues])
  useEffect(() => { }, [initialSubmitValues])
  useEffect(() => { }, [dataAccess])
  useEffect(() => { }, [formValues])

  // handle form submit
  const submit = async () => {
    if (!formSubmitValues.values || formSubmitValues.id === 0) {
      formSubmitValues.values = jdData
    }
    formSubmitValues.deletedVal = deletedValues
    await updateOrCreateJobDescriptionPage(formSubmitValues, jdSubmit, routePath)
  };

  // handle form submit button
  const handleSubmit = (e) => {
    formSubmitValues.updated_by = formSubmitValues.userId !== getUserInfo()?.id ? getUserInfo()?.id : null
    formSubmitValues.values = jdData
    setRoutePath('/views/manage-jobs')
    setFormErrors(validate(formSubmitValues, "submit"))
    setIsSubmitting(true)
  };

  // handle save as draft button
  const handleSaveDraft = (e) => {
    setRoutePath('/views/manage-jobs')
    formSubmitValues.updated_by = formSubmitValues.userId !== getUserInfo()?.id ? getUserInfo()?.id : null
    setFormErrors(validate(formSubmitValues));
    setIsSubmitting(true);
  };

  // check data access validation
  const checkIfDataAccessIsValid = async () => {
    if (dataAccess && isObjectEmpty(dataAccess)) {
      await getUserDataAccessByModule(getUserInfo()?.id, 1)
    } else {
      setIsDataAccessValid(!dataAccess?.limitedDataAccess)
      /*const filEntities = dataAccess && dataAccess.allDataAccess && dataAccess.allDataAccess.length > 0 ? dataAccess.allDataAccess.filter(b => b.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId) : []
      const uniqueEntities = [...new Map(filEntities.map(item => [item.masterOrgEntityId, item])).values()];
      const filCountry = formSubmitValues.masterCountryId !== null && formSubmitValues.masterCountryId !== ""
        ? dataAccess && dataAccess.allDataAccess && dataAccess.allDataAccess.length > 0
          ? dataAccess.allDataAccess.filter(b => b.masterOrgEntityId === formSubmitValues.masterOrgEntityId)
          : []
        : dataAccess && dataAccess.allDataAccess && dataAccess.allDataAccess.length > 0
          ? dataAccess.allDataAccess.filter(b => b.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId)
          : []
      const uniqueCountry = [...new Map(filCountry.map(item => [item.masterCountryId, item])).values()];
      const divisions = dataAccess['divisions'].some(obj => obj.id === formSubmitValues.masterOrgDivisionId)
      const entities = formSubmitValues.masterOrgEntityId != null && formSubmitValues.masterOrgEntityId !== ""
        ? dataAccess['Entities'].some(obj => obj.id === formSubmitValues.masterOrgEntityId)
        : getEntitiesByDivision().length === uniqueEntities.length ? true : false
      const countries = formSubmitValues.masterCountryId != null && formSubmitValues.masterCountryId !== ""
        ? dataAccess['countries'].some(obj => obj.id === formSubmitValues.masterCountryId)
        : getCountryByEntitiesOrDivision().length === uniqueCountry.length ? true : false
      const verticals = dataAccess['Verticals'].some(obj => obj.masterOrgVerticalId === formSubmitValues.masterOrgVerticalId)
      const func = dataAccess['functions'].some(obj => obj.masterOrgFunctionId === formSubmitValues.masterOrgFunctionId)
      const levels = dataAccess['levels'].some(obj => obj.id === formSubmitValues.masterLevelId)
      setIsDataAccessValid(true)
      if (!divisions || !entities || !countries || !verticals || !func || !levels) {
        setIsDataAccessValid(false)
      }*/
    }
  }

  // handle form validation
  const validate = (values, type) => {

    let errors = {};
    let colorCheck = 0;
    let jobNameValid = true;

    if (!isDataAccessValid && values.id === 0) {
      errors.copyJob = "You do not have access to the data in Job Summary. Please re-select data from the dropdown!"
    }

    if (!values.masterOrgDivisionId) {
      errors.masterOrgDivisionId = "Division cannot be blank";
    }

    if (!values.masterOrgVerticalId) {
      errors.masterOrgVerticalId = "Vertical cannot be blank";
    }

    if (!values.masterOrgFunctionId) {
      errors.masterOrgFunctionId = "Function cannot be blank";
    }

    if (!values.masterLevelId) {
      errors.masterLevelId = "Level cannot be blank";
    }

    if (!values.job_name) {
      errors.job_name = "Job Name cannot be blank";
    }

    if (!sanitizeInput(values.job_name)) {
      jobNameValid = false
      errors.job_name = "Invalid characters detected. Please remove any special characters.";
    }

    if (type === "submit" || type === "reject") {
      if (jdData && jdData.length > 0) {
        const submitJDData = jdData
        submitJDData.map((seg) => {
          seg.elements.map((ele) => {
            const contentBlocks = htmlToDraft(ele.value != null ? ele.value.value != null ? ele.value.value : '' : '')
            const contentState = ContentState.createFromBlockArray(contentBlocks)
            const rawHtml = convertToRaw(contentState)
            if (ele.mandatory) {
              if (rawHtml.blocks.length === 1 & rawHtml.blocks[0].text === '') {
                errors[ele.id] = "Value cannot be blank"
              }
            }
          })
        })
      }
    }

    if (Object.keys(errors).length !== 0) {
      window.scrollTo(0, 0)
      if (colorCheck === 1) {
        toast.error("Main Tasks weightage bar should be 100% accross. Please divide accordingly!!")
      } else if (!isDataAccessValid && values.id === 0) {
        toast.error(errors.copyJob)
      } else if (!jobNameValid) {
        toast.error(errors.job_name)
      } else {
        toast.error("Please enter data")
      }
    } else {
      setJDSubmit(false)
      if (type === "submit") {
        setJDSubmit(true)
      }
      setIsSubmitting(true);
    }

    return errors;
  };

  // handle upsert
  const upsert = (array, item, type = null) => {
    let i = null

    if (type === "deletedVal") {
      i = array.findIndex(_item => _item.id === item.id);
    } else {
      i = array.findIndex(_item => _item.masterJdElementId === item.masterJdElementId);
    }

    if (i > -1) array[i] = item;
    else array.push(item);

    return array
  }

  // handle copy job button
  const copyJob = () => {
    if (formSubmitValues && !isObjectEmpty(formSubmitValues)) {
      setCopyJobObject(
        {
          formValues: {
            ...formValues, id: 0, userId: getUserInfo()?.id, is_live: false, is_temp: false, status: false, approved: false, transactionJdId: null, values: jdData
          },
          formSubmitValues: {
            ...formSubmitValues, id: 0, userId: getUserInfo()?.id, is_live: false, is_temp: false, status: false, approved: false, transactionJdId: null, values: jdData
          }
        })
    }
  }

  // handle revise job
  const reviseJob = () => {
    if (formSubmitValues && !isObjectEmpty(formSubmitValues)) {
      setReviseJobObject(
        {
          formValues: {
            ...formValues, id: 0, transactionJdId: formSubmitValues.id, status: false, approved: false, is_temp: false, is_live: false, values: jdData
          },
          formSubmitValues: {
            ...formSubmitValues, id: 0, transactionJdId: formSubmitValues.id, status: false, approved: false, is_temp: false, is_live: false, values: jdData
          }
        })
    }
  }

  // get entities by division
  const getEntitiesByDivision = () => {
    const entities = []
    dataAccess?.masterOrgDivisionId?.length && dataAccess?.masterOrgEntityId?.length ?
      dataAccess?.masterOrgEntityId?.map((entity) => {
        if (formSubmitValues.masterOrgDivisionId === entity.masterOrgDivisionId)
          entities.push(entity)
      }) : []
    return entities.length > 0 ? entities.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) : []
  }

  // get country by entities or division
  const getCountryByEntitiesOrDivision = () => {
    let countries = []
    const jdCountries = dataAccess?.masterCountryId?.length ? dataAccess.masterCountryId : []
    if (formSubmitValues.masterOrgEntityId) {
      const filteredCountry = jdCountries.filter(b => b.masterOrgEntityId === formSubmitValues.masterOrgEntityId) ?? []
      countries = [...new Map(filteredCountry.map(item => [item.id, item])).values()];
    }
    else {
      const filteredCountry = jdCountries.filter(b => b.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId) ?? []
      countries = [...new Map(filteredCountry.map(item => [item.id, item])).values()];
    }
    return countries.length > 0 ? countries.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) : []
  }

  // get verticals by entity or country
  const getVerticalsByEntityOrCountry = () => {
    let verticals = []
    let filteredVerticals = dataAccess?.masterOrgVerticalId?.length ? dataAccess.masterOrgVerticalId : []
    if (formSubmitValues.masterCountryId !== '') {
      filteredVerticals = formSubmitValues.masterOrgEntityId !== "" ? filteredVerticals.filter((fv) => fv.masterCountryId === formSubmitValues.masterCountryId && fv.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId && fv.masterOrgEntityId === formSubmitValues.masterOrgEntityId) : filteredVerticals.filter((fv) => fv.masterCountryId === formSubmitValues.masterCountryId && fv.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId)
      verticals = [...new Map(filteredVerticals.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else if (formSubmitValues.masterOrgEntityId !== '') {
      filteredVerticals = filteredVerticals.filter((obj) => obj.masterOrgEntityId === formSubmitValues.masterOrgEntityId && obj.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId)
      verticals = [...new Map(filteredVerticals.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    else {
      filteredVerticals = filteredVerticals.filter((fv) => fv.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId)
      verticals = [...new Map(filteredVerticals.map(item => [item['masterOrgVerticalId'], item])).values()];
    }
    return verticals.length > 0 ? verticals.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) : []
  }

  // get capabilites by verticals
  const getCapabilitesByVerticals = () => {
    let func = []
    if (formSubmitValues.masterOrgDivisionId && formSubmitValues.masterOrgEntityId && formSubmitValues.masterOrgVerticalId) {
      const filteredCap = dataAccess?.masterOrgFunctionId?.length ? dataAccess.masterOrgFunctionId.filter((cap) => cap.masterOrgVerticalId === formSubmitValues.masterOrgVerticalId && cap.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId && cap.masterOrgEntityId === formSubmitValues.masterOrgEntityId) : []
      func = [...new Map(filteredCap.map(item => [item['masterOrgFunctionId'], item])).values()];
    }
    else if (formSubmitValues.masterOrgVerticalId) {
      const filteredCap = dataAccess?.masterOrgFunctionId?.length ? dataAccess.masterOrgFunctionId.filter((cap) => cap.masterOrgVerticalId === formSubmitValues.masterOrgVerticalId && cap.masterOrgDivisionId === formSubmitValues.masterOrgDivisionId) : []
      func = [...new Map(filteredCap.map(item => [item['masterOrgFunctionId'], item])).values()];
    }
    return func.length > 0 ? func.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) : []
  }

  // set data from generic API
  const setDataFromGenericAPI = () => {
    let jdGenericData = []
    let colorbarObject = []

    if (location && location.state && location.state.id && location.state.id != 0) {
      jdGenericData = jd_values
    } else {
      jdGenericData = jdPage
    }

    if (formSubmitValues.is_temp) {
      let oldData = formSubmitValues && formSubmitValues.values ? [...formSubmitValues.values] : []
      if (jdGenericData.length > 0) {
        jdGenericData.map((segment) => {
          segment.elements.map((element, index) => {

            oldData.map(oldseg => {
              oldseg.elements.map(oldele => {

                if (element && element.value && element.value.value === '') {
                  oldData.map(oldseg => {
                    oldseg.elements.map(oldele => {
                      if (oldele.id === element.id) {
                        element.displayValue = oldele.displayValue
                        element.value = oldele.value
                      }
                    })
                  })
                }

                if (element.id === oldele.id) {
                  element.element_score = oldele.element_score
                }

                const contentBlocks = htmlToDraft(element && element.value && element.value.value != null ? element.value.value : '')
                const contentState = ContentState.createFromBlockArray(contentBlocks)
                const rawHtml = convertToRaw(contentState)

                element.displayValue = rawHtml
                if (element.element_score === undefined) {
                  element.element_score = 0
                }
                if (element.value === null) {
                  element.value = {
                    read_only: false,
                    value: ''
                  }
                }
                if (segment.main_task) {
                  colorbarObject = upsert(mainTaskColorBar, { masterJdElementId: element.id, value: element.element_score, color: element.color, showPercentage: element.element_score != 0 ? true : false, textColor: "black", fontSize: 12 })
                }
              })
            })
          })
        })
      }
    } else {
      if (jdGenericData.length > 0) {
        jdGenericData.map((segment) => {
          segment.elements.map((element, index) => {
            const contentBlocks = htmlToDraft(element && element.value && element.value.value != null ? element.value.value : '')
            const contentState = ContentState.createFromBlockArray(contentBlocks)
            const rawHtml = convertToRaw(contentState)

            element.displayValue = rawHtml
            if (element.element_score === undefined) {
              element.element_score = 0
            }
            if (element.value === null) {
              element.value = {
                read_only: false,
                value: ''
              }
            }
            if (segment.main_task) {
              let cBar = [...mainTaskColorBar]
              colorbarObject = upsert(cBar, { masterJdElementId: element.id, value: element.element_score, color: element.color, showPercentage: element.element_score != 0 ? true : false, textColor: "black", fontSize: 12 })
            }
          })
        })
        if (colorbarObject.length > 0) {
          setMainTaskColorBar(colorbarObject)
        }
      }
    }
    setJDData(jdGenericData)
  }

  // handle insertAt
  const insertAt = (array, index, levId, ...elementsArray) => {
    let ele = []
    let flag = 0

    if (array && array.length > 0) {

      array.map((oldseg, ind) => {
        elementsArray.map((newseg) => {
          if (oldseg.id === newseg.id) {
            oldseg.elements.map((oldele, oldelei) => {

              newseg.elements.map((newele) => {
                if (oldele.id === newele.id) {
                  if (newele.masterLevelId === levId) {
                    oldele.displayValue = newele.displayValue
                    oldele.masterLevelId = newele.masterLevelId
                    oldele.value.read_only = newele.value.read_only
                    oldele.value.value = newele.value.value
                  }
                }
                flag = 1
              })
            })
          }
        })
      })

      if (flag !== 1) {
        array.splice(index, 0, ...elementsArray);
      }

      return array
    }
  }
  // update color bar
  const updateColorBar = () => {
    if (jdData && jdData.length > 0) {
      let updatedColorbarObject = []
      jdData.map((segment) => {
        segment.elements.map((element) => {
          if (segment.main_task) {
            let cBar = [...mainTaskColorBar]
            updatedColorbarObject = upsert(mainTaskColorBar, { masterJdElementId: element.id, value: element.element_score, color: element.color, showPercentage: element.element_score != 0 ? true : false, textColor: "black", fontSize: 12 })
          }
        })
      })
    }
  }

  // set data from level API
  const setDataFromLevelAPI = (levId) => {
    const jdLevelData = jdData ?? []
    let colorbarObject = []

    if (jdPageLevels && jdPageLevels.length > 0) {
      jdPageLevels.map((segment, index) => {
        segment.elements.map((element) => {

          const contentBlocks = htmlToDraft(element && element.value && element.value.value && element.value.value != null ? element.value.value : '')
          const contentState = ContentState.createFromBlockArray(contentBlocks)
          const rawHtml = convertToRaw(contentState)

          element.displayValue = rawHtml
          element.element_score = 0
          element.masterLevelId = levId

          if (formSubmitValues.is_temp) {
            let oldData = formSubmitValues && formSubmitValues.values ? [...formSubmitValues.values] : []
            oldData.map(oldseg => {
              oldseg.elements.map(oldele => {
                if (element.id === oldele.id) {
                  element.element_score = oldele.element_score
                }
              })
            })
          }

          const data = jdLevelData && jdLevelData.length > 0 ? [...jdLevelData] : []
          if (element.value !== null) {
            const obj = insertAt(data, segment.sequence - 1, levId, segment)
          }

          if (segment.main_task) {
            let cBar = [...mainTaskColorBar]
            colorbarObject = upsert(cBar, { masterJdElementId: element.id, value: element.element_score, color: element.color, showPercentage: element.element_score != 0 ? true : false, textColor: "black", fontSize: 12 })
          }
        })
      })
      setJDData(jdLevelData)
      if (colorbarObject.length > 0) {
        setMainTaskColorBar(colorbarObject)
      }
    }
    else {
      setJDData(jdLevelData)
    }
  }

  // handle job delete
  const manageDelete = () => {
    if (isDataAccessValid) {
      handleDeleteRequest(async () => await deleteJobDescriptionPage(location.state.id));
    } else {
      toast.error("You cannot delete this JD as you do not have the right data access!")
    }
  }

  // handle job withdraw button
  const manageWithdraw = () => {
    if (isDataAccessValid) {
      handleDeleteRequest(async () => await withdrawJob(location.state.id), "", "You want to withdraw this JD!", "Yes, withdraw JD!");
    } else {
      toast.error("You cannot withdraw this JD as you do not have the right data access!")
    }
  }

  // handle job approve button
  const handleApprove = () => {
    const errors = validate(formSubmitValues, "submit")
    setIsSubmitting(false);
    setFormErrors(errors);
    if (isObjectEmpty(errors)) {
      Swal.fire({
        title: "Approve JD!",
        text: "Please enter your comments:",
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Approve',
        confirmButtonColor: '#00B0A3'
      }).then((result) => {
        if (result.isConfirmed) {
          let jdApproved = {}
          if (formSubmitValues.restricted) {
            jdApproved = {
              ...formSubmitValues,
              approver_comments: result.value,
              updated_by: isEditable ? getUserInfo()?.id : null
            }
          } else {
            jdApproved = {
              ...formSubmitValues,
              approver_comments: result.value,
              approved: true,
              updated_by: isEditable ? getUserInfo()?.id : null
            }
          }
          setFormSubmitValues(jdApproved)
          setRoutePath('/views/manage-jobs')
          setFormErrors(validate(formSubmitValues));
          setIsSubmitting(true);
        }
      })
    }
  }

  // handle job reject button
  const handleReject = () => {
    const errors = validate(formSubmitValues, "reject")
    setIsSubmitting(false);
    setFormErrors(errors);
    if (isObjectEmpty(errors)) {
      Swal.fire({
        title: "Reject JD!",
        text: "Please enter your comments:",
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Reject',
        confirmButtonColor: 'red',
        inputValidator: (value) => {
          return !value && 'Please enter comments!'
        }
      }).then((result) => {
        let jdRejected = {}
        if (result.isConfirmed) {
          if (formSubmitValues.restricted) {
            jdRejected = {
              ...formSubmitValues,
              rejected: true,
              approver_comments: result.value,
              updated_by: isEditable ? getUserInfo()?.id : null
            }
          } else {
            jdRejected = {
              ...formSubmitValues,
              status: false,
              approved: false,
              rejected: true,
              approver_comments: result.value,
              updated_by: isEditable ? getUserInfo()?.id : null
            }
          }
          setFormSubmitValues(jdRejected)
          setRoutePath('/views/manage-jobs')
          setFormErrors(validate(formSubmitValues, "reject"));
          setIsSubmitting(true);
        }
      })
    }
  }

  return (
    <BaseLayOut pageTitle={formSubmitValues.job_name !== "" ? formSubmitValues.job_name : pageName ?? `Create Job`} isForJD={true}>
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                {formSubmitValues.job_name !== "" ? formSubmitValues.job_name : `Create Job`}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        <div className="jd-container">
          <form onSubmit={(e) => { e.preventDefault() }} noValidate>
            <MDBox py={3}>
              <Card>
                <Card>
                  <MDBox p={3}>
                    {
                      permissions && permissions.canCreate && formSubmitValues.status && formSubmitValues.approved && !formSubmitValues.restricted && isDataAccessValid
                        ? <p style={{ "float": "right" }}>
                          <Checkbox checked={inActiveJD ?? formSubmitValues.status} value={inActiveJD ?? formSubmitValues.status} onChange={(e) => {
                            Swal.fire({
                              title: 'Are you sure?',
                              text: "This will delete any existing draft version of same combination JD after the approval.",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: '#3085d6',
                              cancelButtonColor: '#d33',
                              confirmButtonText: "Yes, dectivate JD",
                              heightAuto: false,
                              height: '200px'
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deActivateJD(location.state.id)
                                setInActiveJD(false)
                              }
                            })
                          }} />
                          Active</p>
                        : null
                    }
                    <MDTypography variant="h5" fontWeight="medium">Job Summary</MDTypography>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Select
                            inputId="divisionSelect"
                            id="divisionSelect"
                            isClearable={true}
                            placeholder={reactSelectCustomPlaceHolderJD('Please Select Division...')}
                            styles={selectCustomStyles}
                            options={dataAccess?.masterOrgDivisionId?.length ? dataAccess.masterOrgDivisionId.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())) ?? [] : []}
                            value={formValues.masterOrgDivisionId ? { "id": formSubmitValues.masterOrgDivisionId, "value": formValues.masterOrgDivisionId, "label": formValues.masterOrgDivisionId } : null}
                            getOptionValue={(option) => option.label}
                            onChange=
                            {(newValue) => {
                              if (newValue !== null) {
                                const filteredObject = dataAccess.masterOrgDivisionId.filter((val) => val.id === newValue.id)
                                setFormSubmitValues({ ...formSubmitValues, masterOrgDivisionId: filteredObject[0].id, masterOrgEntityId: "", masterCountryId: "", masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" })
                                setFormValues({ ...formValues, masterOrgDivisionId: newValue.label, masterOrgEntityId: "", masterCountryId: "", masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" });
                              } else {
                                setFormSubmitValues({ ...formSubmitValues, masterOrgDivisionId: "", masterOrgEntityId: "", masterCountryId: "", masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" })
                                setFormValues({ ...formValues, masterOrgDivisionId: "", masterOrgEntityId: "", masterCountryId: "", masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" });
                              }
                            }}
                            className={formErrors.masterOrgDivisionId && "input-error"}
                            isDisabled={formSubmitValues.status || formSubmitValues.is_temp}
                          />
                          {formErrors.masterOrgDivisionId && (
                            <span className="error">{formErrors.masterOrgDivisionId}</span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Select
                            inputId="entitySelect"
                            id="entitySelect"
                            isClearable={true}
                            placeholder="Please Select Entity..."
                            styles={selectCustomStyles}
                            options={formSubmitValues.masterOrgDivisionId !== "" || formSubmitValues.masterOrgDivisionId !== null ? getEntitiesByDivision() : null}
                            value={formValues.masterOrgEntityId ? { "id": formSubmitValues.masterOrgEntityId, "value": formValues.masterOrgEntityId, "label": formValues.masterOrgEntityId } : null}
                            getOptionValue={(option) => option.label}
                            onChange=
                            {(newValue) => {
                              if (newValue !== null) {
                                const filteredObject = getEntitiesByDivision().filter((val) => val.id === newValue.id)
                                setFormSubmitValues({ ...formSubmitValues, masterOrgEntityId: filteredObject[0].id, masterCountryId: "", masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" })
                                setFormValues({ ...formValues, masterOrgEntityId: newValue.label, masterCountryId: "", masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" });
                              } else {
                                setFormSubmitValues({ ...formSubmitValues, masterOrgEntityId: "", masterCountryId: "", masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" })
                                setFormValues({ ...formValues, masterOrgEntityId: "", masterCountryId: "", masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" });
                              }
                            }}
                            className={formErrors.masterOrgEntityId && "input-error"}
                            isDisabled={formSubmitValues.status || formSubmitValues.masterOrgDivisionId === "" || formSubmitValues.is_temp}
                          />
                          {formErrors.masterOrgEntityId && (
                            <span className="error">{formErrors.masterOrgEntityId}</span>
                          )}
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Select
                            inputId="countrySelect"
                            id="countrySelect"
                            isClearable={true}
                            placeholder="Please Select Country..."
                            styles={selectCustomStyles}
                            options={formSubmitValues.masterOrgEntityId !== null || formSubmitValues.masterOrgEntityId !== "" || formSubmitValues.masterOrgDivisionId !== null || formSubmitValues.masterOrgDivisionId !== "" ? getCountryByEntitiesOrDivision() : null}
                            value={formValues.masterCountryId ? { "id": formSubmitValues.masterCountryId, "value": formValues.masterCountryId, "label": formValues.masterCountryId } : null}
                            getOptionValue={(option) => option.label}
                            onChange=
                            {(newValue) => {
                              if (newValue !== null) {
                                const filteredObject = getCountryByEntitiesOrDivision().filter((val) => val.id === newValue.id)
                                setFormSubmitValues({ ...formSubmitValues, masterCountryId: filteredObject[0].id, masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" })
                                setFormValues({ ...formValues, masterCountryId: newValue.label, masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" });
                              } else {
                                setFormSubmitValues({ ...formSubmitValues, masterCountryId: "", masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" })
                                setFormValues({ ...formValues, masterCountryId: "", masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" });
                              }
                            }}
                            className={formErrors.masterCountryId && "input-error"}
                            isDisabled={formSubmitValues.status || (formSubmitValues.masterOrgEntityId === "" && formSubmitValues.masterOrgDivisionId === "") || formSubmitValues.is_temp}
                          />
                          {formErrors.masterCountryId && (
                            <span className="error">{formErrors.masterCountryId}</span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Select
                            isClearable={true}
                            inputId="verticalSelect"
                            id="verticalSelect"
                            placeholder={reactSelectCustomPlaceHolderJD("Please Select Vertical...")}
                            styles={selectCustomStyles}
                            options={formSubmitValues.masterOrgDivisionId !== "" || formSubmitValues.masterOrgDivisionId !== null ? getVerticalsByEntityOrCountry() : null}
                            value={formValues.masterOrgVerticalId ? { "id": formSubmitValues.masterOrgVerticalId, "value": formValues.masterOrgVerticalId, "label": formValues.masterOrgVerticalId } : null}
                            getOptionValue={(option) => option.label}
                            onChange=
                            {(newValue) => {
                              if (newValue !== null) {
                                const filteredObject = getVerticalsByEntityOrCountry().filter((val) => val.id === newValue.id)
                                setFormSubmitValues({ ...formSubmitValues, masterOrgVerticalId: filteredObject[0].id, masterOrgFunctionId: "", masterLevelId: "" })
                                setFormValues({ ...formValues, masterOrgVerticalId: newValue.label, masterOrgFunctionId: "", masterLevelId: "" });
                              } else {
                                setFormSubmitValues({ ...formSubmitValues, masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" })
                                setFormValues({ ...formValues, masterOrgVerticalId: "", masterOrgFunctionId: "", masterLevelId: "" });
                              }
                            }}
                            className={formErrors.masterOrgVerticalId && "input-error"}
                            isDisabled={formSubmitValues.status || formSubmitValues.masterOrgDivisionId === "" || formSubmitValues.is_temp}
                          />
                          {formErrors.masterOrgVerticalId && (
                            <span className="error">{formErrors.masterOrgVerticalId}</span>
                          )}
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox mt={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Select
                            isClearable={true}
                            inputId="functionSelect"
                            id="functionSelect"
                            placeholder={reactSelectCustomPlaceHolderJD("Please Select Function...")}
                            styles={selectCustomStyles}
                            options={formSubmitValues.masterOrgVerticalId !== "" || formSubmitValues.masterOrgVerticalId !== null ? getCapabilitesByVerticals() : []}
                            value={formValues.masterOrgFunctionId ? { id: formSubmitValues.masterOrgFunctionId, code: formValues.masterOrgFunctionId, label: formValues.masterOrgFunctionId } : null}
                            onChange=
                            {(newValue) => {
                              if (newValue !== null) {
                                const filteredObject = getCapabilitesByVerticals().filter((val) => val.id === newValue.id)
                                setFormSubmitValues({ ...formSubmitValues, masterOrgFunctionId: filteredObject[0].id })
                                setFormValues({ ...formValues, masterOrgFunctionId: newValue.label });
                              } else {
                                setFormSubmitValues({ ...formSubmitValues, masterOrgFunctionId: "" })
                                setFormValues({ ...formValues, masterOrgFunctionId: "" });
                              }
                            }}
                            className={formErrors.masterOrgFunctionId && "input-error"}
                            isDisabled={(formSubmitValues.masterOrgVerticalId === "" && getCapabilitesByVerticals() && getCapabilitesByVerticals().length === 0 || formSubmitValues.status) || formSubmitValues.is_temp} />
                          {formErrors.masterOrgFunctionId && (
                            <span className="error">{formErrors.masterOrgFunctionId}</span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Select
                            inputId="levelSelect"
                            id="levelSelect"
                            isClearable={true}
                            placeholder={reactSelectCustomPlaceHolderJD("Please Select Level...")}
                            isDisabled={formSubmitValues.masterOrgDivisionId === null || formSubmitValues.masterOrgDivisionId === "" && formSubmitValues.masterOrgFunctionId === null || formSubmitValues.masterOrgFunctionId === "" || formSubmitValues.status || formSubmitValues.is_temp}
                            styles={selectCustomStyles}
                            options={getLevels(dataAccess?.masterLevelId ?? [], dataAccess?.masterOrgVerticalId ?? [], formSubmitValues.masterOrgDivisionId !== "" ? formSubmitValues.masterOrgDivisionId : null, formSubmitValues.masterOrgEntityId !== "" ? formSubmitValues.masterOrgEntityId : null, formSubmitValues.masterCountryId !== "" ? formSubmitValues.masterCountryId : null, formSubmitValues.masterOrgVerticalId !== "" ? formSubmitValues.masterOrgVerticalId : null, [], dataAccess.hasOwnProperty('LIds') ? dataAccess.LIds : [])}
                            value={formValues.masterLevelId ? { "id": formSubmitValues.masterLevelId, "value": formSubmitValues.masterLevelId, "label": formValues.masterLevelId } : null}
                            getOptionValue={(option) => option.id}
                            defaultMenuIsOpen={false}
                            onChange=
                            {async (newValue) => {
                              if (newValue !== null) {
                                // if (formSubmitValues.id === null) {
                                //   await getJobDescriptionPage()
                                // }
                                const filteredObject = dataAccess.masterLevelId.filter((val) => val.id === newValue.id)
                                setFormSubmitValues({ ...formSubmitValues, masterLevelId: filteredObject[0].id })
                                setFormValues({ ...formValues, masterLevelId: newValue.label });
                                await getJDByLevel(filteredObject[0].id)
                              } else {
                                setFormSubmitValues({ ...formSubmitValues, masterLevelId: "" })
                                setFormValues({ ...formValues, masterLevelId: "" });
                              }
                            }}
                            className={formErrors.masterLevelId && "input-error"}
                          />
                          {formErrors.masterLevelId && (
                            <span className="error">{formErrors.masterLevelId}</span>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField type="text" placeholder="Please Enter Job Name...*" label=""
                            defaultValue={formValues.job_name ? formValues.job_name : null}
                            inputValue={formValues.job_name ? formValues.job_name : null}
                            value={formValues.job_name ? formValues.job_name : null}
                            disabled={formSubmitValues.status}
                            id="JobName"
                            onChange={(e, newValue) => {
                              setFormSubmitValues({
                                ...formSubmitValues,
                                job_name: e.target.value
                              })
                              setFormValues({ ...formValues, job_name: e.target.value });
                            }}
                            MDBoxMarginBottom={0}
                            className={`custom-text-field ${formErrors.job_name ? " input-error" : ""} ${formSubmitValues.status ? " disabled-input" : ""}`} />
                          {formErrors.job_name && (
                            <span className="error">{formErrors.job_name}</span>
                          )}
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </Card>
              </Card>
            </MDBox>

            {
              jdData && jdData.length > 0 ?
                jdData.map((segment, index) => {
                  return <MDBox py={3}>
                    <Card>
                      <MDBox p={3} lineHeight={1}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={4}>
                            <MDTypography variant="h6" fontWeight="medium">{segment.name} {segment?.elements?.some(s => s.summary && s.mandatory) ? <span style={{color: 'red'}}>*</span> : ""}</MDTypography>
                          </Grid>
                        </Grid>
                        {
                          segment.elements.map((element) => {
                            return <MDBox pt={2} lineHeight={1}>
                              <MDBox pb={2} display="flex" justifyContent="right">
                                {
                                  element.value && !element.value.read_only && permissions && permissions.canApprove && location && formSubmitValues.status && !formSubmitValues.approved && (location.state.approvalId && location.state.approver === location.state.workflowAccessId)
                                    ? <Tooltip title={"Edit"}>
                                      <DEButton hint="Edit" icon="edit" stylingMode="contained" type="default"
                                        onClick={() => { setIsEditable(editable => !editable); }} disabled={isEditable} />
                                    </Tooltip>
                                    : null
                                }
                              </MDBox>
                              <Grid container spacing={2} alignItems="center">
                                {
                                  !element.summary
                                    ? <Grid item xs={12}>
                                      <MDTypography variant="button" style={{ color: element.color }} fontWeight="medium">
                                        {element.name} {element.mandatory ? <span style={{color: 'red'}}>*</span> : ""} <br />
                                      </MDTypography>
                                      {
                                        element.description !== "" && element.description !== null
                                          ? <MDTypography variant="button" fontWeight="medium"><i>{element.description}</i></MDTypography>
                                          : null
                                      }
                                    </Grid>
                                    : null
                                }
                              </Grid>
                              <MDBox pt={2} lineHeight={1}>
                                <MDTypography variant="button" >
                                  {
                                    formSubmitValues.status && !isEditable
                                      ? <MDBox pl={3} dangerouslySetInnerHTML={{ __html: element.value.value }} />
                                      : element.value && element.value.read_only
                                        ? <MDBox pl={3} dangerouslySetInnerHTML={{ __html: element.value.value }} />
                                        : <Editor
                                          defaultContentState={"Default"}
                                          defaultEditorState={element.value.value ? EditorState.createWithContent(
                                            ContentState.createFromBlockArray(
                                              convertFromHTML(element.value.value)
                                            )
                                          ) : null}
                                          wrapperId={index}
                                          handlePastedText={() => false}
                                          stripPastedStyles={true}
                                          key={element.id}
                                          toolbar={{
                                            options: ['inline', 'list', 'textAlign'],
                                            inline: {
                                              inDropdown: false,
                                              options: ['bold', 'italic', 'underline',],
                                            },
                                            list: {
                                              inDropdown: false,
                                              options: ['unordered', 'ordered'],
                                            },
                                            textAlign: {
                                              inDropdown: false,
                                              options: ['left', 'center', 'right', 'justify'],
                                            },
                                          }}
                                          contentState={element.displayValue}
                                          onEditorStateChange={(contentState) => {
                                            let object = jdData.map((seg) => {
                                              if (seg.id === segment.id) {
                                                seg.elements.map((ele) => {
                                                  if (ele.id === element.id) {
                                                    ele.value.value = draftToHtml(convertToRaw(contentState.getCurrentContent()))
                                                  }
                                                })
                                              }
                                              return seg
                                            })
                                            setJDData(object)
                                          }}
                                        />
                                  }
                                  {formErrors[element.id] && (<span className="error">{formErrors[element.id]}</span>)}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          })
                        }
                      </MDBox>
                    </Card>
                  </MDBox>
                })
                : null
            }

            {
              !isLoading && jdData && jdData.length > 0 && permissions && (permissions.canCreate || permissions.canView) && formSubmitValues.approved && !formSubmitValues.restricted
                ? <MDBox component="span" m={1} display="flex" justifyContent="flex-end" alignItems="center">
                  <MDBox mr={1}>
                    <>
                      <PDFDownloadLink document={<ToPrint job_name={formSubmitValues.job_name} jdData={jdData} division_name={formValues.masterOrgDivisionId} />} fileName={formSubmitValues.job_name}>
                        {({ loading }) => (<DEButton text="Export to PDF" disabled={loading} stylingMode="contained" type="normal" style={{ marginRight: "10px" }} />)}
                      </PDFDownloadLink>
                    </>
                    <DEButton text="Copy Job" stylingMode="contained" type="default" state={copyJobObject} to="/views/job-description" style={{ marginRight: "10px" }} />
                    {
                      isDataAccessValid
                        ?
                        <DEButton text="Edit Job" stylingMode="contained" type="danger"
                          state={reviseJobObject} to="/views/job-description" style={{ marginRight: "10px" }} />
                        : null
                    }
                    <DEButton text="Cancel" stylingMode="contained" type="normal" to="/views/manage-jobs" />
                  </MDBox>
                </MDBox>
                : null
            }

            {
              !isLoading && jdData && jdData.length > 0 && permissions && permissions.canCreate
                ? location && !location.state
                  ? <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1}
                    sx={{ marginBottom: 5 }}>
                    <MDBox mr={1}>
                      <DEButton text="Save As Draft" onClick={handleSaveDraft} useSubmitBehavior={false} stylingMode="contained" type="default" style={{ marginRight: "10px" }} />
                      <DEButton text="Submit" onClick={handleSubmit} useSubmitBehavior={false} stylingMode="contained" type="success" style={{ marginRight: "10px" }} />
                      <DEButton text="Cancel" stylingMode="contained" type="normal" to="/views/manage-jobs" />
                    </MDBox>
                  </MDBox>
                  : !formSubmitValues.status
                    ? <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1} sx={{ marginBottom: 5 }}>
                      <MDBox mr={1}>
                        <DEButton text="Save As Draft" disabled={isLoading} onClick={handleSaveDraft} useSubmitBehavior={false} stylingMode="contained" type="default" style={{ marginRight: "10px" }} />
                        <DEButton text="Submit" disabled={isLoading} onClick={handleSubmit} useSubmitBehavior={false} stylingMode="contained" type="success" style={{ marginRight: "10px" }} />
                        {
                          !formSubmitValues.status && formSubmitValues.id !== 0
                            ? <DEButton text="Delete" disabled={isLoading} onClick={manageDelete} stylingMode="contained" type="danger" style={{ marginRight: "10px" }} />
                            : null
                        }
                        <DEButton text="Cancel" disabled={isLoading} stylingMode="contained" type="normal" to="/views/manage-jobs" />
                      </MDBox>
                    </MDBox>
                    : formSubmitValues.status && !formSubmitValues.approved && formSubmitValues.id !== 0 && formSubmitValues.userId === getUserInfo()?.id
                      ? <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1} sx={{ marginBottom: 5 }}>
                        <MDBox mr={1}>
                          <DEButton disabled={isLoading} text="Withdraw JD" stylingMode="contained" type="danger" onClick={manageWithdraw} style={{ marginRight: "10px" }} />
                          <DEButton disabled={isLoading} text="Cancel" stylingMode="contained" type="normal" to="/views/manage-jobs" />
                        </MDBox>
                      </MDBox>
                      : null
                : null
            }

            {
              !isLoading && jdData && jdData.length > 0 && permissions && permissions.canApprove && formValues.status && (!formSubmitValues.approved || formSubmitValues.restricted) && (location.state.approvalId && location.state.approver === location.state.workflowAccessId) && canApprove
                ? <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1} sx={{ marginBottom: 5 }}>
                  <MDBox mr={1}>
                    <DEButton disabled={isLoading} text="Approve" stylingMode="contained" type="success" style={{ marginRight: "10px" }}
                      onClick={handleApprove} />
                    <DEButton disabled={isLoading} text="Reject" stylingMode="contained" type="danger" style={{ marginRight: "10px" }}
                      onClick={handleReject} />
                    <DEButton disabled={isLoading} text="Cancel" stylingMode="contained" type="normal" to="/views/manage-jobs" />
                  </MDBox>
                </MDBox>
                : null
            }

          </form>
        </div>
      </MDBox>
    </BaseLayOut>
  );
};

export default JobDescription;
