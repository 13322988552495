import React, { useEffect, useContext } from 'react'
import { Context } from "../../utils/context/store/Store";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import HcTypeDataGrid from './DataGrid/HcTypeDataGrid';
import useHeadcountType from "../../utils/hooks/useHeadcountType";
import usePagePermissions from "../../utils/hooks/usePagePermissions";
import useBulkUpload from 'utils/hooks/useBulkUpload';

export default function ManageHcType({ routeKey, pageName }) {
  const { fetchHeadcountType, updateOrCreateHeadcountType, deleteHeadcountType, isLoading } = useHeadcountType(routeKey)
  const { bulkUpload } = useBulkUpload(routeKey)
  const [{ headcountType }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const permissions = getPermissionsForPage()

  useEffect(async () => {
    await fetchHeadcountType()
  }, [])

  return (
    <BaseLayOut pageTitle={pageName ?? "Manage Headcount Type"}>
      <HcTypeDataGrid
        columns={headcountType && headcountType.columns ? headcountType.columns : []}
        rows={headcountType && headcountType.rows ? headcountType.rows : []}
        dropdownValues={headcountType && headcountType.dropdownValues ? headcountType.dropdownValues : []}
        isLoading={isLoading}
        permissions={permissions}
        hitApi={updateOrCreateHeadcountType}
        handleDelete={deleteHeadcountType}
        bulkUploadApi={bulkUpload} apiCallback={fetchHeadcountType} tableName={headcountType?.tableName}
        uploadTemplateLink={headcountType?.uploadTemplateLink}
      />
    </BaseLayOut>
  )
}