import React, { useContext, useEffect, useMemo, useState } from "react";
import { Context } from "utils/context/store/Store";
import useWorkflows from 'utils/hooks/useWorkflows';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import usePagePermissions from "../../utils/hooks/usePagePermissions";
import { Grid } from "@mui/material";
import Select from "react-select";
import { selectCustomStyles, workflowAccessOptions } from "../../utils/services/Helpers";
import WfDataGrid from "./DataTable/WfDataGrid";
import useWorkflowStep from 'utils/hooks/useWorkflowStep';
import CustomSkelton from "components/Skelton/CustomSkelton";
import loadingGif from "assets/images/gifs/loading.gif";
import { Image } from 'antd';
import useBulkUpload from "utils/hooks/useBulkUpload";

function ManageWorkflows({ routeKey, pageName }) {

  const [value, setValue] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);
  const [dValue, setDValue] = useState(null);
  const [currentRec, setCurrentRec] = useState({})

  const { getWorkflows, deleteWorkflows, updateOrCreateWorkflows, isLoading } = useWorkflows(routeKey)
  const { getTableData } = useWorkflowStep(routeKey)
  const { bulkUpload } = useBulkUpload(routeKey)
  const { getPermissionsForPage } = usePagePermissions(routeKey)

  const [{ workflows, workflowStepTableData }, dispatch] = useContext(Context)

  useEffect(async () => {
    if (value !== null) {
      if (value !== "workflow-access") await getTableData()
      await getWorkflows(value)
    }
  }, [])

  useEffect(async () => {
    if (value !== null) {
      if (value !== "workflow-access") await getTableData()
      await getWorkflows(value)
    }
  }, [value])

  useEffect(() => { }, [dValue])
  useEffect(() => { }, [workflows])
  useEffect(() => { }, [workflowStepTableData])
  useEffect(() => { }, [currentRec])

  const setEditState = (data) => {
    setCurrentRec(data)
  }

  /**
 * custom function using useMemo to avoid re-renders unless the states listed are changed
 **/
  const comp = useMemo(() => {

    return <DashboardLayout>
      <DashboardNavbar />
      <CustomSkelton>
        <MDBox my={3}>
          <Card>
            <MDBox pt={3} pl={3} pr={3} pb={2} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                {pageName.replace(/-/g, " ") ?? "Manage Approval Setup"}
              </MDTypography>
            </MDBox>
            <MDBox pt={2} pl={3} pr={3} pb={3}>
              <Grid item xs={6} sm={2}>
                <Select options={workflowAccessOptions} value={dValue} styles={selectCustomStyles} isSearchable menuPortalTarget={document.body} onChange={(val) => {
                  setDValue(val)
                  setValue(val.route)
                  setDisplayValue(val.label)
                }} />
              </Grid>
              {value
                ?
                <>
                  <br />
                  {workflows.rows && workflows.columns ?
                    <>
                      <MDTypography variant="h5" fontWeight="medium">
                        Manage {displayValue}
                      </MDTypography>
                      <WfDataGrid loading={isLoading} columns={workflows.columns} rows={workflows.rows}
                        hitApi={updateOrCreateWorkflows} fetchFromApi={getWorkflows} handleDelete={deleteWorkflows}
                        isWorkflows={value === "workflow"} permissions={getPermissionsForPage(routeKey)}
                        dropDownData={workflows.dropdownValues} valueToFetch={value} isAccess={value === "workflow-access"}
                        workflowStepTableData={workflowStepTableData} currentRec={currentRec} setCurrentRec={setEditState}
                        bulkUploadApi={bulkUpload}
                        apiCallback={getWorkflows}
                        tableName={workflows.tableName}
                        uploadTemplateLink={workflows.uploadTemplateLink}
                        orgStructureLink={workflows.orgStructureLink}
                        allowBulkUploading={value === "workflow-access"} />
                    </>
                    : <MDBox mt={2} mb={2} style={{ textAlign: "center" }}>
                      <Image
                        title="Loading"
                        alt="Loading"
                        width={70}
                        src={loadingGif}
                        preview={false}
                      />
                    </MDBox>
                  }
                </>
                : null
              }

            </MDBox>
          </Card>
        </MDBox>
      </CustomSkelton>
      <Footer />
    </DashboardLayout>

  }, [dValue, workflows, workflowStepTableData, currentRec]);

  return <>{comp}</>
}

export default ManageWorkflows;