import { CustomDateBox } from "components/CustomDataGridComponents";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  RequiredRule,
  RowDragging,
  Scrolling,
  SearchPanel,
  MasterDetail,
} from "devextreme-react/data-grid";
import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { cloneIconClick, onRowExpanding } from "utils/services/DatagridHelpers";
import { CategoriesDetailView } from "./categories-detail-view";
import { toast } from "react-toastify";
import BulkUploaderModal from "components/Modal/BulkUploader/BulkUploaderModal";
import { createSanitizeAsyncRule } from "utils/services/Helpers";

export default function ManageCategoriesGrid({
  rows,
  columns,
  dropDownData,
  defaultKey = "id",
  isLoading,
  permissions,
  allowAdding = true,
  allowUpdating = true,
  postData,
  handleDelete,
  itemsColumns,
  lightsColumns,
  bulkUploadApi,
  uploadTemplateLink,
  orgStructureLink,
  apiCallback,
  tableName,
  allowBulkUploading = false
}) {
  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [newRowKey, setNewRowKey] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false)

  const dataGridRef = useRef();

  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);
    // cleanup on unmount
    return () => {
      setDataSource([]);
      setDataColumns([]);
    };
  }, []);

  useEffect(() => {
    setDataSource(rows);
  }, [rows]);

  useEffect(() => {
    setDataColumns(columns);
  }, [columns]);

  useEffect(() => {
    if (newRowKey !== null) {
      dataGridRef.current.instance.expandRow(newRowKey);
      setNewRowKey(null);
    }
  }, [newRowKey]);

  /**
   * @param col
   * function use to handle rendering of fields
   **/
  function renderField(col) {
    if (col.type === "checkbox" || col.type === "toggle") {
      return (
        <Column
          dataType="boolean"
          showEditorAlways
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          setCellValue={function (rowData, value) {
            this.defaultSetCellValue(rowData, value);
          }}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    } else if (col.type === "actions") {
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          type="buttons"
          dataField={col.dataIndex}
          caption={col.title}
          fixed={false}
          width={"auto"}
        >
          <Button
            name="delete"
            icon={"trash"}
            visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true}
          />
          <Button
            hint="Clone"
            icon="copy"
            visible={(e) => permissions && permissions.canCreate}
            onClick={(e) => cloneIconClick(e, dataSource, setDataSource)}
          />
        </Column>
      );
    } else if (col.type === "date") {
      return (
        <Column
          dataType={"date"}
          editCellComponent={CustomDateBox}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          format={"dd-MM-yyyy"}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    } else if (col.type === "string") {
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
        >
          {col.required ? <RequiredRule /> : null}
          <AsyncRule {...sanitizeAsyncRule} />
        </Column>
      );
    } else {
      return (
        <Column
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
        >
          {col.required ? <RequiredRule /> : null}
        </Column>
      );
    }
  }

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    e.data.newRow = true;
    e.data.status = true;
    e.data.sequence = dataSource.length + 1;
    setHasDataChanged(false);
  };

  /**
   * function use to call the api to post data
   **/
  const pushData = async () => {
    let newData = [...dataSource]
    if (newData) {
      let noItem = newData.some(item => !item.master_cv_items)
      let noColor = newData.some(item => !item.master_cv_lights)
      if (noItem) {
        toast.error("Please add an item before submitting the category!");
      } else if (noColor) {
        toast.error("Please add a color before submitting the category!");
      } else {
        await postData(dataSource);
        setHasDataChanged(false);
      }
    } else {
      await postData(dataSource);
      setHasDataChanged(false);
    }
  };

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "upload",
          text: "BULK UPLOAD",
          visible: permissions && permissions.canCreate && allowBulkUploading,
          onClick: function () { setBulkUploadModalVisible(true) },
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !hasDataChanged,
          visible: permissions && permissions.canCreate && (allowAdding || showButton),
          onClick: function () {
            pushData();
          },
        },
      });
  }

  // save new row in the data-grid
  const onSave = (e) => {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dataSourceArray = [...dataSource];
        const deleteItemId = e.changes[0].key;
        if (deleteItemId) {
          const deleteFromTable = dataSourceArray.length
            ? dataSourceArray.filter((data) => data.id !== deleteItemId)
            : [];
          setDataSource(deleteFromTable);
        }
      } else {
        const insertMode = e && e.changes[0].type === "insert";
        const updateMode = e && e.changes[0].type === "update";
        if (updateMode) e.changes[0]["data"]["rowEdited"] = true;
        const updatedData = e.changes[0].data;
        let finalData = [];
        if (insertMode) {
          finalData.push(updatedData);
        }
        let finalResult = [];
        if (dataSource && dataSource.length) {
          finalResult = _.unionBy(dataSource, insertMode ? finalData : updatedData);
        } else {
          finalResult.push(updatedData);
        }
        setDataSource(finalResult);
        setHasDataChanged(true);
      }
    }
  };

  /**
   * function used to reorder rows based on sequence
   **/
  const onReorder = (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newTasks = [...dataSource];

    const toIndex = newTasks.findIndex(
      (item) => item.sequence === visibleRows[e.toIndex].data.sequence
    );
    const fromIndex = newTasks.findIndex((item) => item.sequence === e.itemData.sequence);

    newTasks.splice(fromIndex, 1);
    newTasks.splice(toIndex, 0, e.itemData);
    // Update the sequence property of each item based on its new index
    newTasks.forEach((item, index) => {
      item.sequence = index + 1;
    });

    setDataSource(newTasks);
    setHasDataChanged(true);
  };

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return (
        <div id="data-grid-demo">
          <BulkUploaderModal title={"CV KPI - Bulk Upload"} isModalVisible={bulkUploadModalVisible} setIsModalVisible={setBulkUploadModalVisible} bulkUploadApi={bulkUploadApi} apiCallback={apiCallback} tableName={tableName} downloadLink={uploadTemplateLink} orgStructureLink={orgStructureLink} />
          <DataGrid
            id="grid"
            onToolbarPreparing={onToolbarPreparing}
            showBorders
            onRowExpanding={onRowExpanding}
            onSaved={onSave}
            showColumnLines
            showRowLines
            rowAlternationEnabled
            ref={dataGridRef}
            onInitNewRow={onInitNewRow}
            allowColumnResizing
            disabled={isLoading}
            dataSource={dataSource}
            key={defaultKey ?? "id"}
            keyExpr={defaultKey ?? "id"}
            onRowInserted={(e) => {
              setNewRowKey(e.key);
            }}
          >
            <Scrolling showScrollbar="always" mode="standard" />
            <RowDragging allowReordering onReorder={onReorder} />
            <HeaderFilter visible allowSearch />
            <SearchPanel visible />
            <Paging defaultPageSize={25} />
            <Pager visible showNavigationButtons showInfo displayMode={"full"} />
            <Export enabled allowExportSelectedData />
            <Editing
              refreshMode={"repaint"}
              mode="cell"
              allowUpdating={permissions && permissions.canCreate && allowUpdating}
              allowAdding={permissions && permissions.canCreate && allowAdding}
              allowDeleting
            />
            {dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d)) : null}
            <MasterDetail
              autoExpandAll={false}
              enabled
              component={(props) => {
                return (
                  <CategoriesDetailView
                    itemsColumns={itemsColumns}
                    permissions={permissions}
                    isLoading={isLoading}
                    handleDelete={handleDelete}
                    lightsColumns={lightsColumns}
                    dropDownData={dropDownData}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    setHasDataChanged={setHasDataChanged}
                    data={props.data.data}
                  />
                );
              }}
            />
          </DataGrid>
        </div>
      );
    } catch (e) {
      console.log("error from CV", e);
    }
  }, [dataSource, dataColumns, dropDownData, hasDataChanged, isLoading, selectedTab, bulkUploadModalVisible]);

  return (
    <React.Fragment>
      <DetectNavigationBlocker
        setIsDataChanged={setHasDataChanged}
        isDataChanged={hasDataChanged}
      />
      {Comp}
    </React.Fragment>
  );
}
