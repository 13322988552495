const openpgp = require('openpgp');
export const handlePayloadEncryption = async (jsonData) => {
  try {
    let key = process.env.REACT_APP_ENCRYPTION_KEY

    if (process.env.NODE_ENV.toLowerCase() !== 'development')
    {
      key = key.replace(/\\n/g, "")
      // Add line breaks after BEGIN and before END
      key = key.replace(/-----BEGIN PGP PUBLIC KEY BLOCK-----/, "-----BEGIN PGP PUBLIC KEY BLOCK-----\n\n");
      key = key.replace(/-----END PGP PUBLIC KEY BLOCK-----/, "\n-----END PGP PUBLIC KEY BLOCK-----");

    }

    if (key)
    {
      const publicKey = await openpgp.readKey({ armoredKey: key });
      const message = await openpgp.createMessage({text: JSON.stringify(jsonData)}) // input as Message object

      // Encrypt the message
      return await openpgp.encrypt({
        message,
        encryptionKeys: publicKey,
        format: 'armored'
      })
    }

  }
  catch (e) {
    console.log('error', e)
  }

};
