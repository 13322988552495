import { ScrollableFormTextArea } from "components/CustomDataGridComponents";
import { CustomFormFileInput } from "components/CustomDataGridComponents";
import { SimpleItem, Label } from "devextreme-react/form";

const getEditorType = (type) => {
    switch (type) {
        case 'select':
            return 'dxSelectBox';
        case 'multi-select':
            return 'dxTagBox';
        case 'toggle':
            return 'dxSwitch';
        case 'checkbox':
            return 'dxCheckBox';
        case 'date':
            return 'dxDateBox';
        case 'int':
            return 'dxNumberBox';
        case 'button':
            return 'SelectJD';
        case 'file':
            return 'CustomFileInput';
        case 'textarea':
            return 'dxTextArea';
        default:
            return 'dxTextBox';
    }
};

const getComponentByType = (type) => {
    switch (type) {
      case 'file':
        return CustomFormFileInput;
      case 'textarea':
        return ScrollableFormTextArea;
      default:
        return null;
    }
  };

const getEditorOptions = (col, dropDownData) => {
    const options = {};

    switch (col.type) {
        case 'select':
            options.dataSource = dropDownData[col.dataIndex];
            options.valueExpr = 'id';
            options.displayExpr = 'name';
            break;
        case 'multi-select':
            options.dataSource = dropDownData[col.dataIndex];
            options.valueExpr = 'id';
            options.displayExpr = 'label';
            break;
        case 'date':
            options.openOnFieldClick = true;
            break;
    }

    return options;
};

const renderFormField = (col, dropDownData, formHcNewData, formHcLeaverData) => {
    const editorType = getEditorType(col.type);
    const editorOptions = getEditorOptions(col, dropDownData);

    let isVisible = col.is_visible && col.is_form_visible;

    switch (col.dataIndex) {
        case 'masterEosReasonId':
            isVisible = isVisible && formHcLeaverData?.masterEosTypeId === null;
            editorOptions.dataSource = dropDownData[col.dataIndex]?.filter(d => d.masterEosTypeId === formHcNewData?.masterEosTypeId) ?? [];
            break;
        case 'proxyDelegate':
            isVisible = isVisible && formHcNewData && formHcNewData.emailProxyRequired;
            break;
        case 'justification':
        case 'handoverComments':
        case 'oooContactComments':
            isVisible = isVisible && formHcNewData && formHcNewData.emailProxyRequired;
            break;
        case 'redundancy':
            isVisible = isVisible && formHcNewData && formHcNewData.masterEosTypeId === 2;
            break;
        case 'movingTo':
            isVisible = isVisible && formHcNewData && formHcNewData.masterEosTypeId === 1;
            break;
    }

    return (
        <SimpleItem
            editorType={editorType}
            dataField={col.dataIndex}
            isRequired={col.required}
            visible={isVisible}
            disabled={col.disabled}
            editorOptions={editorOptions}>
            <Label text={col.title} />
        </SimpleItem>
    );
};

export { renderFormField, getEditorType, getEditorOptions, getComponentByType };
