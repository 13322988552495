import BaseLayOut from "../../components/Layout/BaseLayOut";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../utils/context/store/Store";
import usePagePermissions from "../../utils/hooks/usePagePermissions";
import useDataAccess from "../../utils/hooks/useDataAccess";
import useHcRequestType from 'utils/hooks/useHcRequestType';
import GroupsDataGrid from "components/Datagrid/GroupsDataGrid";
import AllTransfersDataGrid from "./Datagrid/AllTransfersDataGrid";
import { getUserInfo, a11yProps } from "utils/services/Helpers";
import { SET_GROUPS, SET_All_HEADCOUNTS, SET_IS_LOADING } from "../../utils/context/store/Constants";
import MDBox from "components/MDBox";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from "@mui/material/Card";
import TabPanel from "components/TabPanel";
import loadingGif from "assets/images/gifs/loading.gif";
import { Image } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/all-hc.scss";
import useManageTransfers from "../../utils/hooks/useManageTransfers";

export default function ManageTransfers({ routeKey, pageName }) {

  const [{ groups, allTransfers, isLoading, dataAccess }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const permissions = getPermissionsForPage()
  const { getAllTransferGroups, getAllTransfers, hasMore } = useManageTransfers(routeKey)
  const { getUserDataAccessByModule } = useDataAccess(routeKey)
  const { getHcRequestType } = useHcRequestType(routeKey)
  const [tabValue, setTabValue] = useState(0);
  const [pageView, setPageView] = useState(localStorage.getItem('pageView') === "true" ?? false);
  const [dataSource, setDataSource] = useState([]);
  const [allHcDs, setAllHcDs] = useState([]);
  const [requestIdFilter, setRequestIdToFilter] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const navigate = useNavigate()
  const search = useLocation().search;
  const rId = new URLSearchParams(search).get("rId") ?? localStorage.getItem('rId') ?? null;
  const dgId = new URLSearchParams(search).get("dg") ?? localStorage.getItem('dgId') ?? null;
  const transactionType = new URLSearchParams(search).get("transactionType") ?? localStorage.getItem('transactionType') ?? null;
  if (rId && transactionType) {
    localStorage.setItem('rId', rId)
    localStorage.setItem('transactionType', transactionType)
  }

  useEffect(() => {
    dispatch({ type: SET_GROUPS, payload: {} })
    localStorage.removeItem('clonedHCS');

    if (localStorage.getItem('rId') && localStorage.getItem('dgId') && localStorage.getItem('transactionType')) {
      setTabValue(0)
      setRequestIdToFilter(parseInt(rId))
    }
    // cleanup on unmount
    return () => {
      localStorage.removeItem('pageView');
      localStorage.removeItem('rId');
      localStorage.removeItem('dgId');
      localStorage.removeItem('transactionType');
      dispatch({ type: SET_GROUPS, payload: {} })
      dispatch({ type: SET_All_HEADCOUNTS, payload: {} })
    }
  }, [])

  useEffect(() => {
    const fetchData = async (transactionType = null) => {
      dispatch({ type: SET_IS_LOADING, payload: true });

      try {
        await Promise.all([
          getAllTransfers(transactionType),
          getUserDataAccessByModule(getUserInfo().id, 7)
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
    };

    setSelectedStatuses([]);
    if ((rId !== null && rId !== "null" && rId !== undefined) && (transactionType !== null && transactionType !== "null" && transactionType !== undefined)) {
      setTabValue(0);
      setRequestIdToFilter(parseInt(rId));
      fetchData();
      localStorage.removeItem('rId');
      localStorage.removeItem('dgId');
      localStorage.removeItem('transactionType');
    } else if ((transactionType !== null && transactionType !== "null" && transactionType !== undefined)) {
      fetchData(transactionType);
      localStorage.removeItem('rId');
      localStorage.removeItem('dgId');
      localStorage.removeItem('transactionType');
    } else {
      if (!pageView && !tabValue) {
        fetchData('transaction_transfer');
      } else if (!pageView && tabValue) {
        fetchData('transaction_transfer');
      } else if (pageView) {
        getAllTransferGroups();
      }
    }
  }, [tabValue, pageView]);
  useEffect(() => {
    setDataSource(groups?.rows);
  }, [groups])
  useEffect(() => {
    setAllHcDs(allTransfers?.rows)
  }, [allTransfers])
  useEffect(() => { }, [dataSource])
  useEffect(() => { }, [requestIdFilter]);
  useEffect(() => {
    let data = allTransfers?.rows;

    if (selectedStatuses.length) {
      data = filterByActiveStatus(selectedStatuses, data);
    }

    setAllHcDs(data);
  }, [selectedStatuses, allTransfers]);


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if (!pageView && (newValue !== 1 || newValue !== 0)) setRequestIdToFilter(null)
    if (rId) navigate(`/views/manage-supervisors${dgId !== null ? `?dg=${dgId}` : ""}`, { replace: true })
  };

  const handleLegendClicked = (filter) => {
    if (selectedStatuses.includes(filter.value)) {
      setSelectedStatuses((prevValue) => {
        return prevValue.filter((e) => e !== filter.value);
      });
    }
    else {
      setSelectedStatuses((prevValue) => {
        return [...prevValue, filter.value];
      });
    }
  };

  const filterByActiveStatus = (activeStatuses, arr) => {
    return arr && arr.length && arr?.filter(e => activeStatuses?.includes(e.filterStatus))
  };

  return (
    <BaseLayOut pageTitle={pageName ?? "Manage Supervisors"} isForTransfer={true} pageView={pageView} setPageView={setPageView} setTabValue={setTabValue}>
      <MDBox my={2}>
        <Card>
          <MDBox>
            <MDBox sx={{ width: "100%" }}>
              <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
                  {
                    pageView ? <Tab label="Request View" {...a11yProps(0)} /> : ""
                  }
                  {
                    !pageView ? <Tab label="Employee View" {...a11yProps(0)} /> : ""
                  }
                </Tabs>
              </MDBox>
              <TabPanel value={tabValue} index={pageView ? 0 : null}>
                {
                  groups && groups.columns && groups.columns.length > 0
                    ? <GroupsDataGrid rows={dataSource} columns={groups && groups.columns ? groups.columns : []} routeKey={routeKey} permissions={permissions} isLoading={isLoading} hasMore={hasMore} setTab={setTabValue} setrIdToFilter={setRequestIdToFilter} setPage={setPageView} transactionType='transaction_transfer' fetchDataFromApi={getAllTransferGroups} />
                    : <MDBox mt={1} mb={1} style={{ textAlign: "center" }}>
                      <Image
                        title="Loading"
                        alt="Loading"
                        width={70}
                        src={loadingGif}
                        preview={false}
                      />
                    </MDBox>
                }
              </TabPanel>
              <TabPanel value={tabValue} index={!pageView ? 0 : null}>
                {
                  allTransfers && allTransfers.columns && allTransfers.columns.length > 0
                    ? <div>
                      <div className="lights-legend__container">
                        {allTransfers?.allHcFilters?.map((filter) => (
                          <div
                            key={filter.value}
                            onClick={() => {
                              handleLegendClicked(filter);
                            }}
                            className={`lights-legend__item ${selectedStatuses?.includes(filter.value) ? "lights-legend__item--active" : ""
                              }`}
                          >
                            <div
                              className="lights-legend__color"
                              style={{
                                backgroundColor: filter?.color ? filter.color : "transparent",
                              }}
                            ></div>
                            {filter?.value}
                          </div>
                        ))}
                      </div>

                      <AllTransfersDataGrid dataAccess={dataAccess} transactionType={'transaction_transfer'} setrIdToFilter={setRequestIdToFilter} requestIdFilter={requestIdFilter} rows={requestIdFilter !== null && allHcDs?.length ? allHcDs?.filter(l => l?.transactionTransferGroupId === requestIdFilter) : allHcDs} columns={allTransfers && allTransfers.columns ? allTransfers.columns : []} dropDownData={allTransfers && allTransfers.dropdownValues ? allTransfers.dropdownValues : {}} transferFormColumns={allTransfers && allTransfers.transferFormColumns ? allTransfers.transferFormColumns : {}} fields={allTransfers && allTransfers.fields ? allTransfers.fields : {}} routeKey={routeKey} permissions={permissions} isLoading={isLoading}
                        apiCallBack={async () => {
                          await getAllTransfers()
                          await getUserDataAccessByModule(getUserInfo().id, 7)
                        }} />
                    </div>
                    : <MDBox mt={1} mb={1} style={{ textAlign: "center" }}>
                      <Image
                        title="Loading"
                        alt="Loading"
                        width={70}
                        src={loadingGif}
                        preview={false}
                      />
                    </MDBox>
                }
              </TabPanel>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </BaseLayOut>
  )
}
