import React, { useContext, useEffect } from "react"
import { Context } from "utils/context/store/Store";
import useMyTasks from 'utils/hooks/useMyTasks';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import usePagePermissions from "utils/hooks/usePagePermissions";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import NumberChart from "examples/Charts/LineCharts/NumberChart";
import CustomSkelton from "../../components/Skelton/CustomSkelton";
import DataGridTable from "components/Datagrid/DataGridTable";
import { SET_HOMEPAGE_TASKS } from "../../utils/context/store/Constants";

function Home({ routeKey, pageName }) {

  const { getMyTasks, getDashboardObject, isLoading } = useMyTasks(routeKey)
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const [{ myTasks, dashboardObject }, dispatch] = useContext(Context)

  useEffect(() => {
    // handle breadcrumbs home icon click
    localStorage.setItem('openItemsData', JSON.stringify(['home']))
    localStorage.removeItem('tasks')
    localStorage.removeItem('moduleId')
    const callback = async () => {
      dispatch({ type: SET_HOMEPAGE_TASKS, payload: {} });
      await getDashboardObject();
      await getMyTasks()
    }
    callback()

    return () => {
      localStorage.removeItem('tasks')
      localStorage.removeItem('moduleId')
    }
  }, [])
  useEffect(() => { }, [dashboardObject, myTasks])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CustomSkelton>
        <MDBox pt={6} pb={3}>
          <MDBox py={3}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {"Dashboard"}
                </MDTypography>
              </MDBox>
              <MDBox p={3} lineHeight={1}>
                {
                  dashboardObject
                    ? <Grid container spacing={3}>
                      <Grid item xs={12} lg={4}>
                        <MDBox mb={3}>
                          <NumberChart color="secondary" title="Total JDs" cardNum={dashboardObject.totalJD ?? 0} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <MDBox mb={3}>
                          <NumberChart color="success" title={`My JDs`} cardNum={dashboardObject.createdJD ?? 0} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <MDBox mb={3}>
                          <NumberChart color="dark" title={`My Approved JDs`} cardNum={dashboardObject.approvedJD ?? 0} />
                        </MDBox>
                      </Grid>
                    </Grid>
                    : null
                }
              </MDBox>
            </Card>
          </MDBox>

          <Card>
            <MDBox pt={3} pl={3} pr={3} pb={1} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                My Tasks
              </MDTypography>
              {myTasks && myTasks.columns && myTasks.columns.length > 0
                ?
                <DataGridTable defaultKey={"masterModuleId"} columns={myTasks.columns}
                  rows={myTasks.rows} isLoading={isLoading} permissions={getPermissionsForPage(routeKey)}
                  isForHome={true} allowAdding={false} allowDeleting={false} allowUpdating={false} />
                : null
              }
            </MDBox>
          </Card>
        </MDBox>
      </CustomSkelton>
      <Footer />
    </DashboardLayout>
  );
}

export default Home;
