import * as React from "react";
import { useEffect, useState } from "react";
import "devextreme/dist/css/dx.light.css";
import MDBox from "components/MDBox";
import { DEButton, getUserInfo } from "utils/services/Helpers";
import { Form, GroupItem, SimpleItem, Label } from "devextreme-react/form";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import useApprovals from "utils/hooks/useApprovals";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ActionDropdown from "./ActionDropdown";
import { getEditorType, getComponentByType } from "views/manage-transfer/services/formHelper";


const TransferDetailForm = React.memo(({ formHcLeaverData, hcDataAccessValues, hcDropdownData, hcEditableFields, apiCallBack, permissions, routeKey, dataSource, setDataSource, dgInstance, canApproveReject, selectedRowKeys, setSelectedRowKeys, columns }) => {
  const { bulkRollback, approveOrReject, handleHoldOrReActivateRequest, cancelGroupRequest } = useApprovals()
  const [hcData, setHcData] = useState({});
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [haveFormError, setHaveFormError] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState(null);
  const [formClassName, setFormClassName] = useState(null);
  const [actionDropdownDatasource, setActionDropdownDatasource] = useState([]);
  const transactionType = "transaction_transfer"
  const masterModuleId = 7

  useEffect(() => {
    if (formHcLeaverData) {
      setHcData(formHcLeaverData)

      let formClass = null
      if (formHcLeaverData.hasOwnProperty('cancelled') && formHcLeaverData.cancelled) {
        formClass = "cancelled"
      }
      else if (formHcLeaverData.hasOwnProperty('needApproval') && formHcLeaverData.needApproval) {
        formClass = "needApproval"
      }
      else if (formHcLeaverData.hasOwnProperty('status') && formHcLeaverData.status) {
        formClass = "approved"
      }
      setFormClassName(formClass)

      // if (formHcLeaverData?.transaction_headcount_group && formHcLeaverData?.transaction_headcount_group?.masterRequestTypeId) {
      //   setMasterRequestTypeId(formHcLeaverData.transaction_headcount_group.masterRequestTypeId)
      // }

      // if (formHcLeaverData?.transaction_headcount_group && formHcLeaverData?.transaction_headcount_group?.masterHeadcountTypeId) {
      //   setMasterHeadcountTypeId(formHcLeaverData.transaction_headcount_group.masterHeadcountTypeId)
      // }
    }

    // cleanup on unmount
    return () => {
      setHcData({})
      setFormClassName(null)
      // setMasterRequestTypeId(null)
      // setMasterHeadcountTypeId(null)
    }
  }, []);

  useEffect(() => {
    if (Object.keys(hcData).length) {
      const buttons = [
        {
          text: 'Reject',
          visible: canApproveReject && hcData.needApproval && hcData.actionedByApproverId === null && permissions && permissions.canApprove,
          onClick: handleReject
        },
        // {
        //   text: 'Hold Request',
        //   visible: hcData.needApproval && hcData.actionedByApproverId === null && hcData.hold_reason === null ? true : hcData.cStatus !== "Closed" && hcData.status && hcData.canHoldOrReactivate && hcData.cancelled !== true,
        //   onClick: handleHold
        // },
        // {
        //   text: 'Reactivate Request',
        //   visible: hcData?.canReactivateForApprover ? true : hcData.needApproval && hcData.actionedByApproverId === getUserInfo()?.id && hcData.activation_reason === null ? true : !hcData.needApproval && hcData.cStatus !== "Closed" && hcData.status && hcData.canReactivate,
        //   onClick: handleReactivate
        // },
        {
          text: 'Rollback',
          visible: hcData.status === null && canApproveReject && hcData.actionedByApproverId === null && hcData.needApproval && permissions && permissions.canApprove,
          onClick: handleRollback
        },
        {
          text: 'Cancel Request',
          visible: !hcData.needApproval && hcData.actionedByApproverId === null && hcData.cStatus !== "Closed" && !hcData.onHold && hcData.status && hcData.canCancel && permissions && permissions.canCreate,
          onClick: handleCancel
        }
      ];

      // Filter out buttons with visible === false
      const filteredButtons = buttons?.filter(button => button?.visible);
      setActionDropdownDatasource(filteredButtons);
    }
  }, [hcData, canApproveReject, hasDataChanged, permissions]);

  /**
   * Handle Form Data Change
   **/
  const formFieldDataChanged = (e) => {
    setHcData({
      ...hcData,
      [e.dataField]: e.value
    })
    setHasDataChanged(true)
  }


  /**
   * Handle Form edit Request
   **/
  const handleFormAdd = async () => {
    const ds = [...dataSource]
    const leaverRequiredFields = hcEditableFields?.length ? hcEditableFields.filter(f => f.required && f.is_visible) : []
    if (leaverRequiredFields?.length && leaverRequiredFields.every(keyObj => hcData[keyObj.dataIndex] !== null && hcData[keyObj.dataIndex] !== undefined)) {
      const updatedDataSource = ds.map(obj => obj.id === hcData.id ? { ...obj, data: { ...obj.data, ['data']: hcData }, isRequiredFieldsFilled: true } : obj);
      setDataSource(updatedDataSource)
      if (selectedRowKeys && selectedRowKeys.length) {
        const sds = [...selectedRowKeys]
        const updatedSelectedDataSource = sds.map(obj => obj.id === hcData.id ? { ...obj, data: { ...obj.data, ['data']: hcData, isRequiredFieldsFilled: true } } : obj);
        setSelectedRowKeys(updatedSelectedDataSource)
      }
      dgInstance?.collapseAll(-1)
    }
    else toast.error("There are some missing values. Please fill them before proceeding!")
  }

  /**
   * Handle Approve Request
   **/
  const handleApprove = async () => {
    await approveOrReject(true, [hcData], transactionType, masterModuleId, null, apiCallBack)
  }

  /**
   * Handle Reject Request
   **/
  const handleReject = async () => {
    await approveOrReject(false, [hcData], transactionType, masterModuleId, null, apiCallBack)
  }

  /**
   * Handle Hold Request
   **/
  const handleHold = async () => {
    await handleHoldOrReActivateRequest(hcData.id, hcData.hold !== true, transactionType, masterModuleId, hcData.needApproval, apiCallBack)
  }

  /**
   * Handle Reactivate Request
   **/
  const handleReactivate = async () => {
    await handleHoldOrReActivateRequest(hcData.id, hcData?.canReactivateForApprover ? false : hcData.needApproval && hcData?.actionedByApproverId === getUserInfo()?.id && hcData.hold === null ? false : hcData.hold !== true, transactionType, masterModuleId, hcData?.canReactivateForApprover ? true : hcData.needApproval, apiCallBack)
  }

  /**
   * Handle Cancel Request
   **/
  const handleCancel = async () => {
    await cancelGroupRequest(hcData.id, transactionType, false, false, apiCallBack)
  }

  /**
* Handle Approve Request
**/
  const handleRollback = async () => {
    await bulkRollback([hcData], transactionType, masterModuleId, null, apiCallBack)
  }

  return (
    <>
      {
        Object.keys(hcData).length ?
          <MDBox id="HcLeaverFormDetailsContainer">
            <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
            <div style={{ padding: "16px" }} className={formClassName}>
              <Form
                key={hcData.id}
                id="HcLeaverFormDetails"
                className="HcLeaverFormContent"
                onFieldDataChanged={formFieldDataChanged}
                formData={hcData}
                width="100%"
                style={{ marginBottom: "30px" }}
              >
                <GroupItem colCount={2}>
                  {columns.map(column => (
                    column.is_visible && (
                      <SimpleItem
                        key={column.dataIndex}
                        dataField={column.dataIndex}
                        editorType={getEditorType(column.type)}
                        name={column.dataIndex}
                        isRequired={hcEditableFields.find(item => item.dataIndex === column.dataIndex)?.required || column.required}
                        visible={hcData && hcData.hasOwnProperty(column.dataIndex) && hcData[column.dataIndex] !== null}
                        component={getComponentByType(column.type)}
                        editorOptions={{
                          searchEnabled: true,
                          dataSource: hcDropdownData[column.dataIndex] || hcDataAccessValues[column.dataIndex] || [],
                          valueExpr: "id",
                          displayExpr: "label",
                          disabled: !(permissions.canCreate && permissions.canApprove && hcEditableFields.find(item => item.dataIndex === column.dataIndex)?.editable && !hcData.cancelled && hcData.needApproval),
                        }}
                      >
                        <Label text={column.title} />
                      </SimpleItem>
                    )
                  ))}
                </GroupItem>
              </Form>
              {haveFormError ? <MDBox className="form-error">{formErrorMsg}</MDBox> : null}
              <MDBox style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", alignItems: "stretch" }}>
                {
                  !hcData.hasOwnProperty('newRow') && hcData.isDraft && permissions && permissions.canCreate
                    ? <Link to={`/views/group/${hcData?.transactionTransferGroupId}/manage-supervisor`}>
                      <DEButton visible={!hcData.hasOwnProperty('newRow') && hcData.isDraft && permissions && permissions.canCreate} stylingMode={"contained"} type={"default"} text={""} title={"edit"} icon={"edit"} style={{ marginRight: "10px" }} />
                    </Link>
                    : ""
                }
                <DEButton visible={!canApproveReject && hasDataChanged && hcData.needApproval && hcData.actionedByApproverId === null && permissions && permissions.canApprove} stylingMode={"contained"} type={"default"} text={"Add"} onClick={handleFormAdd} style={{ marginRight: "10px" }} />
                <DEButton visible={canApproveReject && hcData.needApproval && hcData.actionedByApproverId === null && permissions && permissions.canApprove} stylingMode={"contained"} type={"success"} text={"Approve"} onClick={handleApprove} style={{ marginRight: "10px" }} />
                {actionDropdownDatasource && actionDropdownDatasource.length > 0 && (
                  <ActionDropdown
                    permissions={permissions}
                    actionDropdownDatasource={actionDropdownDatasource}
                    isFormFormView={true}
                  />
                )}
              </MDBox>
            </div>
          </MDBox>
          : null
      }
    </>
  );
})
TransferDetailForm.displayName = "TransferDetailForm"
export default TransferDetailForm;
