import React, { useContext, useEffect, useState } from "react"
import { Context } from "utils/context/store/Store";
import useEmployee from 'utils/hooks/useEmployee';
import usePagePermissions from "utils/hooks/usePagePermissions";
import EmployeeDataGrid from "./DataTable/EmployeeDataGrid";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import useBulkUpload from "utils/hooks/useBulkUpload";

function ManageEmployee({ routeKey, pageName }) {

  const { getEmployee, deleteEmployee, updateOrCreateEmployee, isLoading } = useEmployee(routeKey)
  const { bulkUpload } = useBulkUpload(routeKey)
  const [{ employee }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)

  useEffect(async () => {
    await getEmployee()
  }, [])
  useEffect(() => { }, [employee])

  return (
    <BaseLayOut pageTitle={pageName ?? "Manage Employees"}>
      <EmployeeDataGrid isLoading={isLoading} rows={employee.rows ?? []} columns={employee.columns}
        permissions={getPermissionsForPage(routeKey)} dropDownData={employee.dropdownValues}
        postData={updateOrCreateEmployee} handleDelete={deleteEmployee} allowSelection={true} allowAdding={true}
        allowDeletingFromApi={true} bulkUploadApi={bulkUpload} apiCallback={getEmployee} tableName={employee.tableName}
        uploadTemplateLink={employee.uploadTemplateLink} orgStructureLink={employee.orgStructureLink} />
    </BaseLayOut>
  );
}

export default ManageEmployee;