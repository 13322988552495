import * as React from "react";
import DataGrid, {
  Column,
  Lookup,
  Export,
  SearchPanel,
  HeaderFilter,
  RequiredRule, MasterDetail, Button
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import "./styles.scss"
import { useContext, useEffect, useRef, useState } from "react";
import useHeadCountLeaver from "../../../utils/hooks/useHeadCountLeaver";
import {
  CustomFileInput, CustomDateBox, CustomSwitch,
  CustomTextArea, CustomDTag, CustomTextBox,
} from "../../../components/CustomDataGridComponents";
import { toast } from "react-toastify";
import { renderAttachment } from "../../../utils/services/DatagridHelpers";
import { areRequiredFieldsFilled, DEButton } from "../../../utils/services/Helpers";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import { v4 as uuidv4 } from "uuid";
import { Context } from "../../../utils/context/store/Store";
import useManageTransfer from "utils/hooks/useManageTransfer";
import TransferFormComponent from "./TransferFormComponent";
const _ = require("lodash");

const TransferDataGrid = React.memo(({ rows, columns, dropDownData, masterRequestTypeId, masterHeadcountTypeId, isLoading, routeKey, permissions, showButton = true, allowAdding = true, allowSelection = false, apiCallBack = null, enableMasterDetail = true, isCloned = false, isDraft = false, groupId = null, setDgData = [] }) => {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [addNewItem, setAddNewItem] = useState(0)
  const [onRowExpand, setOnRowExpand] = useState(false)
  const dataGridRef = useRef();
  const { updateOrCreateTransferRequest } = useManageTransfer();
  const [addEditMode, setAddEditMode] = useState(false)
  const [formEditMode, setFormEditMode] = useState(false)
  const [isReplacementRequired, setIsReplacementRequired] = useState(false)
  const [{ headcountRequest }, dispatch] = useContext(Context)

  useEffect(() => {
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => {
    if (dataSource?.length && dataSource.some(d => d.hasOwnProperty('modified'))) setHasDataChanged(false)
  }, [dataSource]);
  useEffect(() => {
    if (dataSource.length <= 0 && columns && columns.length) initializeRow()
  }, [dataColumns]);
  useEffect(() => {
    if (isCloned) setDataSource(dataSource.length ? dataSource : rows)
    else setDataSource(rows);
  }, [rows]);
  useEffect(() => { }, [columns]);
  useEffect(() => {
    if (hasDataChanged) {
      setAddEditMode(false)
      setFormEditMode(false)
      if (!isReplacementRequired) {
        setTimeout(() => {
          dataGridRef.current.instance.collapseAll(-1)
        }, 250)
      }
    }
  }, [hasDataChanged])
  useEffect(() => {
    if (dataGridRef.current && dataSource.length > 0 && dataGridRef.current.instance) {
      dataGridRef.current.instance.collapseAll(-1)
      dataGridRef.current.instance.expandRow(dataSource[0].id);
    }
  }, [addNewItem])


  /**
   * @param e
   * function use perform operation when data-grid editor is not prepared e.g enable/disable field etc
   **/
  function onEditorPreparing(e) {
    if (e && e.dataField === "masterEmployeeId") {
      // e.editorOptions.disabled = e && e.row && e.row.data && (!e.row.data.hasOwnProperty('newRow') || !e.row.data.isDraft)
      e.editorOptions.disabled = !(
        e &&
        e.row &&
        e.row.data &&
        (e.row.data.hasOwnProperty('newRow') && e.row.data.newRow === true) ||
        (e.row.data.hasOwnProperty('isDraft') && e.row.data.isDraft === true)
      );

    }

    if (e && e.column && e.column.type === "detailExpand" && e.row && e.row.data && e.row.data.hasOwnProperty("replacementRequired") && e.row.data.replacementRequired === true) {
      e.cellElement.style.visibility = "visible";
    }
  }


  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {

    if (col.type === "select") {
      if (!col.hasOwnProperty("filtrationKey")) {
        return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
          if (col.hasOwnProperty("bindedTo"))
            rowData[col.bindedTo] = null;
          this.defaultSetCellValue(rowData, value);
        }}>
          {
            col.required ? <RequiredRule /> : null
          }
          <Lookup allowClearing
            dataSource={(options) => {
              let masterEmployeeId = []
              let employeeId = null
              let usedIds = []
              if (col.dataIndex === "masterEmployeeId" && dataSource.length) {
                if (Object.keys(options).length) {
                  employeeId = options?.data?.masterEmployeeId ?? null
                  if (employeeId !== null) {
                    const ds = employeeId ? dataSource.filter(d => d.masterEmployeeId !== employeeId) : []
                    usedIds = _.map(ds, 'masterEmployeeId')
                    masterEmployeeId = dropDownData[col.dataIndex]?.filter(d => !usedIds.includes(d.id))
                  }
                  else {
                    usedIds = _.map(dataSource, 'masterEmployeeId')
                    masterEmployeeId = dropDownData[col.dataIndex]?.filter(d => !usedIds.includes(d.id))
                  }
                }
              }

              const data = {
                store: masterEmployeeId?.length ? masterEmployeeId : dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
                paginate: true,
                pageSize: 10
              }
              return data;
            }}
            displayExpr="name" valueExpr="id" />
        </Column>;
      }
      else if (col.hasOwnProperty("filtrationKey")) {
        return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} setCellValue={function (rowData, value) {
          if (col.dataIndex === "masterJobTypeId" && value) {
          }
          this.defaultSetCellValue(rowData, value);
          if (col.hasOwnProperty("bindedTo")) {
            rowData[col.bindedTo] = null;
          }
        }}>
          <Lookup allowClearing dataSource={(options) => {
            const data = {
              store: dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : [],
              filter: options.data ? [col.filtrationKey, "=", options.data[col.filtrationKey]] : null,
            }
            return data;
          }} displayExpr="name" valueExpr="id" />
          {
            col.required ? <RequiredRule /> : null
          }
        </Column>;
      }
    }
    else if (col.type === "multi-select") {
      return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTag}
        cellTemplate={(container, options) => {
          if (!addEditMode) {
            const noBreakSpace = "\u00A0";
            const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
            container.textContent = text || noBreakSpace;
            container.title = text;
          }
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === 'search' && typeof (filterValue) === 'string') {
            return [col.dataIndex, 'contains', filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
        <Lookup allowClearing dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null} displayExpr="label" valueExpr="id" />
      </Column>;
    }
    else if (col.type === "toggle") {
      return <Column alignment={"center"} dataType="boolean" showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} editCellComponent={CustomSwitch}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else if (col.type === "checkbox") {
      return <Column alignment={"center"} dataType="boolean"
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column alignment={"center"} width={"auto"} allowEditing={col.editable} visible={false} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false}>
        <Button name="delete"
          visible={(e) => e.visible = !!(e && e.row && e.row.data && e.row.data.newRow && permissions && permissions.canDelete)}
          disabled={(e) => e.disabled = !hasDataChanged || !isCloned}
          component={(props) => <DEButton hint="Delete" stylingMode={"contained"} type={"danger"} icon={"trash"} />}
        />
      </Column>
    }
    else if (col.type === "date") {
      return <Column alignment={"left"} dataType={"date"} format={'dd-MM-yyyy'} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else if (col.type === "file") {
      return <Column alignment={"left"} showInColumnChooser={false} visible={col.is_visible} allowEditing={col.editable} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} fixed={false} dataField={col.dataIndex} caption={col.title} cellRender={renderAttachment}
        editCellComponent={CustomFileInput} />
      {
        col.required ? <RequiredRule /> : null
      }
    }
    else if (col.type === "modal") {
      return <Column alignment={"left"} dataType={"buttons"} editCellComponent={CustomMasterDetail} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else if (col.type === "textarea") {
      return <Column alignment={"left"} cssClass={!onRowExpand ? "textAreaColumnWrap" : ""} width={!onRowExpand ? "350" : ""} editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else if (col.type === "text") {
      return <Column alignment={"left"} editCellComponent={CustomTextBox} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else {
      return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }

  }

  function initializeRow() {
    dataSource.length ?
      setDataSource([{
        id: uuidv4(),
        newRow: true,
        nr: true,
        routeKey: routeKey,
        dStatus: false,
        onHold: false,
        isCancelled: false,
        re_activate: false,
        lineManager: null,
        ...(isDraft ? { isDraft: true, transactionTransferGroupId: rows?.find(obj => 'transactionTransferGroupId' in obj)?.transactionTransferGroupId || null } : {})
      },
      ...dataSource])
      : setDataSource([{
        id: uuidv4(),
        newRow: true,
        nr: true,
        routeKey: routeKey,
        dStatus: false,
        onHold: false,
        isCancelled: false,
        re_activate: false,
        lineManager: null,
        ...(isDraft ? { isDraft: true, transactionTransferGroupId: rows?.find(obj => 'transactionTransferGroupId' in obj)?.transactionTransferGroupId || null } : {})
      }])
    // MasterDetail autoExpandAll is true (expanded by defualt)
    // setOnRowExpand(true)
    setHasDataChanged(false)
    setAddNewItem(addNewItem + 1)
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          disabled: dataSource.some(e => !e.hasOwnProperty('masterEmployeeId')),
          visible: dataSource.some(e => e.isRollback) ? false : permissions && permissions.canCreate && (isDraft || !groupId),
          icon: 'add',
          onClick: () => {
            localStorage.removeItem('HC')
            localStorage.removeItem('LR')
            initializeRow();
          }
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SAVE AS DRAFT",
          disabled: !(hasDataChanged || isDraft && !dataSource.some(e => !e.hasOwnProperty('masterEmployeeId'))),
          visible: !dataSource.some(e => e.hasOwnProperty('status') && e.status) && ((allowAdding || hasDataChanged) && !dataSource.some(e => e.hasOwnProperty('needApproval') && e.needApproval)) || (isDraft || !groupId),
          onClick: async () => {
            const newDs = [...dataSource];
            if (newDs.some(s => s.masterEmployeeId !== null && s.lineManager !== null && s?.master_clients?.length)) {
              if (newDs.length) {
                if (isCloned) {
                  const requiredFields = columns.filter(c => c.required === true)
                  const rFields = _.map(requiredFields, 'dataIndex').filter(d => d !== "proxyDelegate" && d !== "attachment")
                  const ds = [...dataSource]
                  const valuesArray = rFields.map(key => {
                    const object = ds.find(obj => obj.hasOwnProperty(key));
                    return object ? object[key] : undefined;
                  });
                  const filteredReplacements = dataSource.filter(ds => ds.replacementRequired > 0 && ds?.headcounts?.length && !ds.isDraft)
                  let missingAttachmentIds = []
                  const isAttachmentAddedForAllReplacements = filteredReplacements?.every(obj => {
                    const { division, entity, country, level, headcounts } = obj;

                    headcounts.map((hc) => {
                      const isStructureSame = hc.masterOrgDivisionId === division && hc.masterOrgEntityId === entity && hc.masterCountryId === country && hc.masterLevelId === level
                      if (!isStructureSame && (hc.attachment === null || (hc.attachment && hc.attachment?.length === 0)) || (hc.attachments === null || (hc.attachments && hc.attachments?.length === 0))) {
                        missingAttachmentIds.push(true)
                      }
                    }
                    );

                    if (missingAttachmentIds.includes(true)) {
                      return false;
                    }

                    return true;
                  });
                  if (valuesArray?.length && (valuesArray.includes(null) || valuesArray.includes(undefined))) {
                    toast.error("please fill all the required fields")
                  } else if (!isAttachmentAddedForAllReplacements) {
                    toast.error("Some of your replacements are missing attachments. Please add them to continue.")
                  } else {
                    setHasDataChanged(false)
                    const updatedData = dataSource.map(item => ({ ...item, replacementRequired: item.replacementRequired > 0 }));
                    await updateOrCreateTransferRequest(updatedData, masterRequestTypeId, !allowAdding && hasDataChanged, true);
                  }
                }
                else {
                  const updatedData = dataSource //.map(item => ({ ...item, replacementRequired: item.replacementRequired > 0 }));
                  await updateOrCreateTransferRequest(updatedData, true, null);
                }
              }
              else {
                toast.error("Please fill all the required fields")
              }
            }
            else toast.error("Please fill all the required Fields.")
          },
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !(hasDataChanged || isDraft && !dataSource.some(e => !e.hasOwnProperty('masterEmployeeId'))),
          visible: ((allowAdding || hasDataChanged) && !dataSource.some(e => e.hasOwnProperty('needApproval') && e.needApproval)) || (isDraft || !groupId),
          onClick: async () => {
            const newDs = [...dataSource];
            if (newDs.some(s => s.masterEmployeeId !== null && s.lineManager !== null && s?.master_clients?.length)) {
              if (newDs.length) {
                if (isCloned) {
                  const requiredFields = columns.filter(c => c.required === true)
                  const rFields = _.map(requiredFields, 'dataIndex').filter(d => d !== "proxyDelegate" && d !== "attachment")
                  const ds = [...dataSource]
                  const valuesArray = rFields.map(key => {
                    const object = ds.find(obj => obj.hasOwnProperty(key));
                    return object ? object[key] : undefined;
                  });
                  const filteredReplacements = dataSource.filter(ds => ds.replacementRequired > 0 && ds?.headcounts?.length && !ds.isDraft)
                  let missingAttachmentIds = []
                  const isAttachmentAddedForAllReplacements = filteredReplacements?.every(obj => {
                    const { division, entity, country, level, headcounts } = obj;

                    headcounts.map((hc) => {
                      const isStructureSame = hc.masterOrgDivisionId === division && hc.masterOrgEntityId === entity && hc.masterCountryId === country && hc.masterLevelId === level
                      if (!isStructureSame && (hc.attachment === null || (hc.attachment && hc.attachment?.length === 0)) || (hc.attachments === null || (hc.attachments && hc.attachments?.length === 0))) {
                        missingAttachmentIds.push(true)
                      }
                    }
                    );

                    if (missingAttachmentIds.includes(true)) {
                      return false;
                    }

                    return true;
                  });
                  if (valuesArray?.length && (valuesArray.includes(null) || valuesArray.includes(undefined))) {
                    toast.error("please fill all the required fields")
                  } else if (!isAttachmentAddedForAllReplacements) {
                    toast.error("Some of your replacements are missing attachments. Please add them to continue.")
                  } else {
                    setHasDataChanged(false)
                    const updatedData = dataSource.map(item => ({ ...item, replacementRequired: item.replacementRequired > 0 }));
                    await updateOrCreateTransferRequest(updatedData, masterRequestTypeId, !allowAdding && hasDataChanged, true);
                  }
                }
                else {
                  const updatedData = dataSource //.map(item => ({ ...item, replacementRequired: item.replacementRequired > 0 }));
                  await updateOrCreateTransferRequest(updatedData, false, null);
                }
              }
              else {
                toast.error("Please fill all the required fields")
              }
            }
            else toast.error("Please fill all the required Fields.")
          },
        },
      },
    );
  }

  return (
    <div id="HcLeaverDataGridContainer">
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      <DataGrid sorting={{ mode: 'none' }} id="HcLeaverDataGrid"
        className={formEditMode ? "HcLeaverDataGridForm" : ''}
        renderAsync={formEditMode ? true : false}
        onToolbarPreparing={onToolbarPreparing} showBorders={true}
        onRowCollapsed={(e) => {
          e?.component?.collapseRow(e?.key);
          setOnRowExpand(false)
          setAddEditMode(false)
          setFormEditMode(false)
        }}
        columnAutoWidth={true}
        showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
        ref={dataGridRef} allowColumnResizing={true}
        disabled={isLoading} dataSource={dataSource}
        key="id" keyExpr="id"
        onEditorPreparing={onEditorPreparing}>
        <HeaderFilter visible={false} allowSearch={true} />
        <SearchPanel visible={false} />
        <Export enabled={true} allowExportSelectedData={true} />
        <MasterDetail autoExpandAll={false} enabled={true} component={(props) => {
          return <TransferFormComponent data={props.data.data} hasDataChanged={hasDataChanged} columns={columns} dropDownData={dropDownData} masterTransactionType={"transaction_leaver"} permissions={permissions} dataSource={dataSource} setDataSource={setDataSource} setHasDataChanged={setHasDataChanged} routeKey={routeKey} apiCallBack={apiCallBack} setDgData={setDgData} isCloned={isCloned} setIsReplacementRequired={setIsReplacementRequired} headcountRequest={headcountRequest} isDraft={isDraft} />
        }} />
        {
          dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
        }
      </DataGrid>
    </div>
  );
})

TransferDataGrid.displayName = "TransferDataGrid"

export default TransferDataGrid
