import React, { useContext, useEffect, useState } from 'react';
import { Context } from 'utils/context/store/Store';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useStructureManagement from 'utils/hooks/useStructureManagement';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import Card from '@mui/material/Card';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Select from 'react-select';
import { Grid } from '@mui/material';
import { selectCustomStyles, a11yProps } from '../../utils/services/Helpers';
import StructureManagementDataGrid from './DataTable/StructureManagementDataGrid';
import TabPanel from 'components/TabPanel';
import CustomSkelton from 'components/Skelton/CustomSkelton';
import useBulkUpload from 'utils/hooks/useBulkUpload';

const RenderCompAndDropDown = ({ lists, routeKey }) => {
  const {
    getStructureManagement,
    deleteStructureManagement,
    updateOrCreateStructureManagement,
    isLoading
  } = useStructureManagement(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey);
  const [{ structureManagement }, dispatch] = useContext(Context);
  const { getPermissionsForPage } = usePagePermissions(routeKey);

  const [value, setValue] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);
  const [dValue, setDValue] = useState(null);

  const tablesForBulkUpload = [
    'division',
    'entity',
    'vertical',
    'function',
    'country',
    'market',
    'hfm',
    'currency',
    'level',
    'entity-country-vertical-function',
    'decvl',
    'department'
  ];

  useEffect(async () => {
    if (value !== null) await getStructureManagement(value);
  }, [value]);

  let options = lists ?? [];

  return (
    <>
      {options.length ? (
        <Grid item xs={6} sm={2}>
          <Select
            options={options}
            value={dValue}
            styles={selectCustomStyles}
            isSearchable
            menuPortalTarget={document.body}
            onChange={(val) => {
              setDValue(val);
              setValue(val.value);
              setDisplayValue(val.label);
            }}
          />
        </Grid>
      ) : null}
      {value ? (
        <>
          <br />

          <MDTypography variant="h5" fontWeight="medium">
            Manage {displayValue}
          </MDTypography>

          <StructureManagementDataGrid
            isLoading={isLoading}
            rows={structureManagement.rows ?? []}
            columns={structureManagement.columns}
            permissions={getPermissionsForPage(routeKey)}
            dropDownData={structureManagement.dropdownValues}
            postData={updateOrCreateStructureManagement}
            handleDelete={deleteStructureManagement}
            valueToFetch={value}
            allowSelection
            allowAdding={value !== 'entity-country-hfm'}
            allowDeletingFromApi={value !== 'entity-country-hfm'}
            bulkUploadApi={bulkUpload}
            apiCallback={getStructureManagement}
            tableName={structureManagement.tableName}
            uploadTemplateLink={structureManagement.uploadTemplateLink}
            orgStructureLink={structureManagement.orgStructureLink}
            allowBulkUploading={tablesForBulkUpload.indexOf(value) !== -1}
          />
        </>
      ) : null}
    </>
  );
};

function StructureManagement({ routeKey, pageName }) {
  const { getStructureManagementTabs } = useStructureManagement(routeKey);
  const [{ structureManagementList }, dispatch] = useContext(Context);

  const [value, setValue] = useState(0);

  const options = structureManagementList ?? [];

  useEffect(async () => {
    await getStructureManagementTabs();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox pt={3} pl={3} pr={3} pb={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
              {pageName.replace(/-/g, ' ') ?? 'Manage Structure Management'}
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pl={3} pr={3} pb={3}>
            <MDBox sx={{ width: '100%' }}>
              <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <CustomSkelton>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {options.map((obj, index) => {
                      return <Tab key={index} label={obj.label} {...a11yProps(index)} />;
                    })}
                  </Tabs>
                </CustomSkelton>
              </MDBox>
              {options.map((obj, index) => {
                return (
                  <TabPanel key={index} value={value} index={index} isText={false}>
                    <RenderCompAndDropDown lists={obj.categories} routeKey={routeKey} />
                  </TabPanel>
                );
              })}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StructureManagement;
