import { hasPermission } from "../services/Helpers";
import { useContext } from "react";
import { Context } from "../context/store/Store";

export default function usePagePermissions(routeKey){

  const [{pagesAndPermissions}, dispatch] = useContext(Context)

  function getPermissionsForPage() {

    return hasPermission(pagesAndPermissions, routeKey)
  }

  return { getPermissionsForPage }

}