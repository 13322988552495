import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  EmailRule, MasterDetail, Editing,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import {
  CustomDateBox,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea,
} from "../../../components/CustomDataGridComponents";
import { createSanitizeAsyncRule } from "../../../utils/services/Helpers";
import { cloneIconClick } from "../../../utils/services/DatagridHelpers";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import useFreelancerManagement from "../../../utils/hooks/useFreelancerManagement";

const CellWithTooltip = ({ data }) => {
  const textLength = data?.businessFeedback ? data?.businessFeedback.split(/\s+/).length : 0;
  const isLongText = textLength > 84 || (data?.businessFeedback && data?.businessFeedback.length > 100);

  return (
    <div className={`custom-cell-content ${isLongText ? 'scrollable' : ''}`}>
      {data?.businessFeedback}
    </div>
  );
};

export default function FreelancerManagementDataGrid({ rows, columns, hcDetailColumns, isLoading, permissions, showMasterDetail = true, allowEditing = false }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [autoWidth, setAutoWidth] = useState(true)
  const dataGridRef = useRef();
  const [addEditMode, setAddEditMode] = useState(false)
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");
  const { createUpdateFreelancerBusinessFeedback } = useFreelancerManagement()

  useEffect(() => {
    setSelectedRowKeys([])
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => {
    // if (rows && rows.length <= 0 && columns && columns.length) {
    //   setTimeout(() => addRow(dataGridRef, onInitNewRow), 200)
    // }
    setDataColumns(columns)
  }, [columns]);

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col) {
    if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} width={"auto"}>
        <Button name="delete" icon={'trash'} visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true} />
        <Button hint="Clone" icon="copy" visible={(e) => permissions && permissions.canCreate && valueToFetch !== "entity-country-hfm"} onClick={(e) => cloneIconClick(e, dataSource, setDataSource)} />
      </Column>
    }
    else if (col.type === "date") {
      return <Column alignment={"left"} dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        format={"yyyy-MM-dd"}
        // format={'dd-MM-yyyy'}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex === "endDate" ? <AsyncRule
            message="end date cannot be less than start date"
            validationCallback={async (e) => {
              if (e && e.data) {
                if (e.data.startDate && e.data.endDate) {
                  return e?.data?.startDate ? e.data.endDate >= e.data.startDate : true
                }
                else
                  return e?.value && e.data?.startDate ? e.value >= e.data.startDate : true
              }

            }}
          /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column dataType={col.type} /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} />}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex !== "id"
            ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
              return e && e.value && e.value.toString().length <= 15
            }} />
            : null
        }
      </Column>;
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} type={"buttons"} fixed={false} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomFileInput} />;
    }
    else if (col.type === "textarea") {
      return <Column cellRender={(props) => <CellWithTooltip data={props?.data} />} editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.type === "string") {
      return (
        <Column
          key={col.dataIndex}
          allowEditing={col.editable}
          visible={col.is_visible}
          allowSearch={col.is_searchable}
          allowSorting={col.is_sortable}
          dataField={col.dataIndex}
          caption={col.title}
          cellRender={(data) => {
            if (col.dataIndex === "reqCode") {
              return (
                <a
                  href={data.data.redirectLink}
                  style={{ textDecoration: 'underline !important' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.data.reqCode}
                </a>
              );
            }
            return data.text;
          }}
        >
          {col.dataIndex === "email" && <EmailRule />}
          {col.required && <RequiredRule />}
          <AsyncRule {...sanitizeAsyncRule} />
        </Column>
      );
    }
    else if (col.type === "button") {
      return <Column alignment={"left"} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title} cellComponent={(props) => <>{props?.data?.data?.[col.dataIndex] && <a href={props?.data?.data?.[col.dataIndex]} target={"_blank"} title={`Download ${col.title}`}><FileDownloadIcon /></a>}</>}>
        {col.required ? <RequiredRule /> : null}
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.dataIndex === "email" ? <EmailRule /> : null
        }
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "save",
        text: "SUBMIT",
        disabled: !hasDataChanged,
        visible: allowEditing,
        onClick: async () => await createUpdateFreelancerBusinessFeedback(dataSource?.map(d => ({
          id: d.id,
          businessFeedback: d.businessFeedback,
        })))
      }
    },
    );
  }

  function onSaved(e) {
    if (e && e.changes.length) setHasDataChanged(true)
    setAutoWidth(true)
    setAddEditMode(false)
    console.log("saved from FreelancerManagementDataGrid", e);
  }

  /**
   * custom function using useMemo to avoid re-renders unless the states listed are changed
   **/
  const Comp = useMemo(() => {
    try {
      return <div id="data-grid-demo">
        <DataGrid id="grid" showBorders={true} columnAutoWidth={true} onCellValueChanged={() => setHasDataChanged(true)}
          showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
          ref={dataGridRef} allowColumnResizing={true} onSaved={onSaved}
          disabled={isLoading} dataSource={dataSource} key="id" keyExpr="id" onToolbarPreparing={onToolbarPreparing}>
          <Paging defaultPageSize={25} />
          <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
          {
            addEditMode ? null : <Scrolling showScrollbar="always" mode="standard" />
          }
          <HeaderFilter visible={true} allowSearch={true} />
          <SearchPanel visible={true} />
          <Export enabled={true} allowExportSelectedData={true} />
          {
            showMasterDetail ?
              <MasterDetail style={{ color: 'red' }} autoExpandAll={false} enabled={true} component={(props) => {
                return <FreelancerManagementDataGrid isLoading={isLoading} columns={hcDetailColumns} rows={props?.data?.data?.transaction_headcounts} permissions={permissions} showMasterDetail={false} allowEditing={permissions?.canCreate} />
              }} />
              : null
          }
          {
            allowEditing ? <Editing newRowPosition={"first"} refreshMode={"repaint"} mode="cell" allowUpdating={allowEditing} allowAdding={false} allowDeleting={false} /> : null
          }
          {
            dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d)) : null
          }
        </DataGrid>
      </div>
    }
    catch (e) {
      console.log('error from Freelancer Management Data grid', e)
    }
  }, [dataSource, dataColumns, hasDataChanged, selectedRowKeys, isLoading, autoWidth, addEditMode])

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      {Comp}
    </React.Fragment>
  );
}
