import { useContext } from "react";
import { Context } from "../context/store/Store";
import { SET_IS_LOADING } from "../context/store/Constants";
import 'react-toastify/dist/ReactToastify.css';
import { handleUploadLogs, handleUploadResult, uploadFile } from "../services/Helpers";
import { useIsLoading } from "./useIsLoading";

export default function useBulkUpload(routeKey) {

    const [{ employee, pagesAndPermissions }, dispatch] = useContext(Context)
    const { isLoading, setIsLoading } = useIsLoading()

    async function bulkUpload(file, tableName, callback, valueToFetch) {
        setIsLoading(true)
        dispatch({ type: SET_IS_LOADING, payload: true })

        try {
            const formData = new FormData();
            formData.append('file', file);

            const res = await uploadFile(`bulk-upload/${tableName}`, formData);

            if (res) {
                handleUploadLogs(res.data.logs, new Date().getTime() + `_${tableName}UploadLogs.xlsx`);
                handleUploadResult(res.data.data);
                await callback(valueToFetch);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
        setIsLoading(false)
        dispatch({ type: SET_IS_LOADING, payload: false })
    }

    return { bulkUpload }
}