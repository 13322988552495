import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import DataGrid, {
  AsyncRule,
  Button,
  Column,
  Editing,
  Export,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
  Scrolling,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.light.css";
import {
  CustomDateBox,
  CustomFileInput,
  CustomNumberBox,
  CustomTextArea,
} from "../../../components/CustomDataGridComponents";
import { addRow, handleDeleteRequest, isObjectEmpty, hasDuplicates, showDialogBox, DEButton, createSanitizeAsyncRule } from "../../../utils/services/Helpers";
import MDAlert from "../../../components/MDAlert";
import MDTypography from "../../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import { cloneIconClick, onEditingStart, onRowExpanding } from "../../../utils/services/DatagridHelpers";
import { Context } from "../../../utils/context/store/Store";
import DeleteIcon from "@mui/icons-material/Delete";
import { SET_MANAGE_ENTITY_CLIENTS } from "../../../utils/context/store/Constants";
import { toast } from "react-toastify";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import { TagBox } from "devextreme-react/tag-box";
import CustomSkelton from "components/Skelton/CustomSkelton";
import BulkUploaderModal from "components/Modal/BulkUploader/BulkUploaderModal";
const _ = require('lodash')

export default function ManageDepartmentClientsGrid({ data, rows, columns, dropDownData, isLoading, showButton = true, routeKey, permissions, allowAdding = true, allowUpdating = true, allowSelection = false, allowDeleting = true, postData, valueToFetch, handleDelete, allowDeletingFromApi = true, bulkUploadApi, uploadTemplateLink, orgStructureLink, apiCallback, tableName, allowBulkUploading = false }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const dataGridRef = useRef();
  const [hasValue, setHasValue] = useState(0);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false)
  const [{ entityClients }, dispatch] = useContext(Context)
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");


  useEffect(() => {
    setSelectedRowKeys([])
    setDataSource(rows);
    setDataColumns(columns);

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);
  useEffect(() => { setDataSource(rows) }, [rows]);
  useEffect(() => { setDataColumns(columns) }, [columns]);
  useEffect(() => { }, [dataSource]);
  useEffect(() => { }, [dataColumns]);


  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  function onSelectionChanged({ selectedRowKeys, selectedRowsData }) {
    const final = selectedRowsData.filter((val) => val.count > 0);
    setHasValue(final.length);
    setSelectedRowKeys(selectedRowsData)
  }

  /**
   * get selected rows
   **/
  const hasSelected = selectedRowKeys.length > 0

  /**
   * @param col
   * @param dropDownData
   * function use to handle rendering of fields
   **/
  function renderField(col, dropDownData) {
    if (col.type === "select") {
      return <Column width={250} editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        setCellValue={function (rowData, value) {

          if (col.dataIndex === "masterDepartmentId") {
            let gridInstance = dataGridRef.current.instance;
            let editRowKey = gridInstance.option("editing.editRowKey");
            let index = gridInstance.getRowIndexByKey(editRowKey);
            const meId = gridInstance.cellValue(index, "masterDepartmentId");
            if (meId) {
              if (meId !== value) {
                rowData['departmentChanged'] = true
                rowData['oldDepartmentId'] = meId
              }
            }
          }

          if (col.hasOwnProperty("bindedTo"))
            rowData[col.bindedTo] = null;
          this.defaultSetCellValue(rowData, value);
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
        <Lookup allowClearing
          dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : []}
          displayExpr="label" valueExpr="id" />

        {
          col.dataIndex === "masterDepartmentId" ? <AsyncRule
            message="This item cannot be changed as it is currently in use."
            validationCallback={async (e) => {
              if (e.data && e.data.recordExistsInCv) {
                return false;
              }
              return true;

            }}
          /> : null
        }
      </Column>;
    }
    else if (col.type === "multi-select") {
      return <Column editorOptions={{ dropDownOptions: { width: "auto" } }} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomDTagThisComp}
        cellTemplate={(container, options) => {
          const noBreakSpace = "\u00A0";
          const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
          container.textContent = text || noBreakSpace;
          container.title = text;
        }}
        calculateFilterExpression={function (filterValue, selectedFilterOperation, target) {
          if (target === "search" && typeof (filterValue) === "string") {
            return [col.dataIndex, "contains", filterValue];
          }
          return function (data) {
            return (data[col.dataIndex] || []).indexOf(filterValue) !== -1;
          };
        }}
      >
        <Lookup allowClearing
          dataSource={dropDownData && dropDownData.hasOwnProperty(col.dataIndex) ? dropDownData[col.dataIndex] : null}
          displayExpr="label" valueExpr="id" />
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "checkbox") {
      return <Column dataType="boolean"
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
    else if (col.type === "actions") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} width={"auto"}>
        <Button name="delete" icon={'trash'} visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true} />
        <Button hint="Clone" icon="copy" visible={(e) => permissions && permissions.canCreate && valueToFetch !== "entity-country-hfm"} onClick={(e) => cloneIconClick(e, dataSource, setDataSource)} />
      </Column>
    }
    else if (col.type === "date") {
      return <Column alignment={"left"} dataType={"date"} editCellComponent={CustomDateBox} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} format={"yyyy-MM-dd"}
        caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex === "endDate" ? <AsyncRule
            message="end date cannot be less than start date"
            validationCallback={async (e) => {
              if (e && e.data) {
                if (e.data.startDate && e.data.endDate) {
                  return e?.data?.startDate ? e.data.endDate >= e.data.startDate : true
                }
                else
                  return e?.value && e.data?.startDate ? e.value >= e.data.startDate : true
              }

            }}
          /> : null
        }
      </Column>;
    }
    else if (col.type === "int") {
      return <Column dataType={col.type} /* editCellComponent={CustomNumberBox} */ allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} editCellComponent={(props) => <CustomNumberBox props={props.data} canEdit={col.editable} />}>
        {
          col.required ? <RequiredRule /> : null
        }
        {
          col.dataIndex !== "id"
            ? <AsyncRule message={"Value should not exceed more than 15 digits"} validationCallback={async (e) => {
              return e && e.value && e.value.toString().length <= 15
            }} />
            : null
        }
      </Column>;
    }
    else if (col.type === "file") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} type={"buttons"} fixed={false} dataField={col.dataIndex} caption={col.title}
        editCellComponent={CustomFileInput} />;
    }
    else if (col.type === "textarea") {
      return <Column editCellComponent={CustomTextArea} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.type === "string") {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>;
    }
    else {
      return <Column allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable}
        allowSorting={col.is_sortable} dataField={col.dataIndex} caption={col.title}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>;
    }
  }

  /**
   * @param props
   * custom component to display multi select box
   **/
  const CustomDTagThisComp = (props) => {

    useEffect(() => { }, [props])

    function onValueChanged(e) {
      const previousValue = e.previousValue || [];
      const removedId = previousValue.find(id => !e.value.includes(id));

      if (removedId) {
        const removedTag = modifiedDs.find(tag => tag.id === removedId);
        if (removedTag && removedTag.disabled) {
          toast.error("This item cannot be removed as it is currently in use.");
          e.component.option('value', previousValue);
          return;
        }
      }

      props.data.setValue(e.value);
    }


    function onSelectionChanged() {
      props.data.component.updateDimensions();
    }

    const ds = dropDownData && dropDownData.hasOwnProperty(props.data.column.dataField) && dropDownData[props.data.column.dataField]?.length ? dropDownData[props.data.column.dataField] : []

    const modifiedDs = ds.map(item => {
      const statusObj = props?.data?.data?.masterClientIdStatus?.find(status => status.id === item.id);
      return {
        ...item,
        disabled: statusObj ? (!statusObj.status || statusObj?.recordExistsInCv) : false
      };
    });

    return <TagBox
      dropDownOptions={{ width: "auto" }}
      dataSource={modifiedDs}
      defaultValue={props.data.value}
      valueExpr="id"
      displayExpr={"label"}
      showSelectionControls={true}
      maxDisplayedTags={3}
      showMultiTagOnly={false}
      applyValueMode="instantly"
      searchEnabled={true}
      onValueChanged={onValueChanged}
      onSelectionChanged={onSelectionChanged}
      disabledExpr="disabled"
    />;
  };

  /**
   * @param e
   * initialize new row in the data-grid
   **/
  const onInitNewRow = (e) => {
    window.scrollTo(0, 0)
    e.data.status = true
    e.data.newRow = true
  }

  /**
 * function use to call the api to post data
 **/
  const pushData = async () => {
    let newData = [...dataSource]
    const hasDuplicateMasterDepartmentId = newData.some((item, index) => newData.findIndex(obj => obj.masterDepartmentId === item.masterDepartmentId) !== index);

    if (hasDuplicateMasterDepartmentId) {
      toast.error("Duplicate combinations found for Agency/Practice. It cannot be duplicated!")
    } else {
      showDialogBox(async () => {
        await postData(newData)
        setHasDataChanged(false)
        setSelectedRowKeys([])
      }, 'info', 'Yes, continue', null, 'Please ensure to change the workflow steps with the updated values to execute workflows!')
    }
  }

  /**
   * @param e
   * function use to prepare toolbar
   **/
  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "upload",
          text: "BULK UPLOAD",
          visible: permissions && permissions.canCreate && allowBulkUploading,
          onClick: function () { setBulkUploadModalVisible(true) },
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !hasDataChanged,
          visible: permissions && permissions.canCreate && (allowAdding || showButton),
          onClick: async function () { await pushData() },
        }
      }
    );
  }

  /**
   * @param e
   * Manage pre api call to save data and validation
   **/
  function onSave(e) {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dsCopy = [...dataSource]
        const filteredDs = dsCopy.length ? dsCopy.filter(ds => ds.id !== e.changes[0].key) : []
        if (filteredDs && filteredDs.length) {
          setDataSource(filteredDs)
          setHasDataChanged(true)
        }
        else {
          setDataSource([])
          setHasDataChanged(false)
        }
      }
      else {
        const updatedData = e.changes[0].data;
        let finalResult = []
        if (dataSource && dataSource.length) {
          finalResult = _.unionBy(updatedData, dataSource);
        }
        else { finalResult.push(updatedData) }
        setDataSource(finalResult)
        setHasDataChanged(true)
      }
    }
  }

  /**
   * function used to handle delete part of data-grid
   **/
  const manageDelete = async () => {
    if (hasValue > 0) {
      toast.error(`${hasValue} records cannot be deleted as it exists in a relationship!`);
    } else if (selectedRowKeys.some(e => e.hasOwnProperty('recordExistsInCv') && e?.recordExistsInCv)) {
      toast.error(`${selectedRowKeys.filter(e => e.hasOwnProperty('recordExistsInCv') && e?.recordExistsInCv)?.length} records cannot be deleted as it exists in a relationship!`);
    } else {
      const newRecords = dataSource.filter(ds => ds.hasOwnProperty('newRow'))
      let newData = [...dataSource]
      handleDeleteRequest(async () => {
        const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow'))
        const deleteFromTable = selectedRowKeys.filter(a => a.hasOwnProperty('newRow'))
        const result = deleteFromApi.map(a => a.masterDepartmentId);
        if (deleteFromApi.length > 0 && deleteFromTable.length > 0) {
          await handleDelete(result)
        }
        else if (deleteFromApi && deleteFromApi.length > 0) {
          entityClients.rows = newRecords
          entityClients.apiDelete = true
          dispatch({
            type: SET_MANAGE_ENTITY_CLIENTS,
            payload: entityClients
          })
          await handleDelete(result)
        }
        else if (deleteFromTable && deleteFromTable.length > 0) {
          deleteFromTable.map(a => {
            newData = newData.filter((item) => item.key !== a.key);
          })
          setDataSource(newData)
        }
        setHasDataChanged(false)
        setSelectedRowKeys([])
      })
    }
  };

  /**
 * custom function using useMemo to avoid re-renders unless the states listed are changed
 **/
  const Comp = useMemo(() => {
    try {
      return <div id="data-grid-demo">
        {hasSelected > 0
          ?
          <React.Fragment>
            <br />
            <MDAlert color="light">

              <MDTypography variant="subtitle2">
                {`Selected ${selectedRowKeys.length} ${selectedRowKeys.length === 1 ? "item" : "items"}`}
              </MDTypography>

              <Divider orientation="vertical" color="dark" flexItem />

              <MDBox>
                <Grid container spacing={2}>
                  {
                    permissions && permissions.canDelete && allowDeletingFromApi
                      ? <Grid item>
                        <DEButton stylingMode={"contained"} type={"danger"} icon="trash" onClick={() => manageDelete()} />
                      </Grid>
                      : null
                  }
                </Grid >
              </MDBox >
            </MDAlert >
          </React.Fragment>
          : ""
        }

        <BulkUploaderModal title={"Structure Management - Bulk Upload"} isModalVisible={bulkUploadModalVisible} setIsModalVisible={setBulkUploadModalVisible} bulkUploadApi={bulkUploadApi} apiCallback={apiCallback} tableName={tableName} downloadLink={uploadTemplateLink} orgStructureLink={orgStructureLink} valueToFetch={valueToFetch} />

        <DataGrid id="grid"
          onToolbarPreparing={onToolbarPreparing}
          showBorders={true}
          columnAutoWidth={false} onSaved={onSave}
          showColumnLines={true} showRowLines={true} rowAlternationEnabled={true}
          ref={dataGridRef} onInitNewRow={onInitNewRow}
          onSelectionChanged={onSelectionChanged} allowColumnResizing={true}
          disabled={isLoading} dataSource={dataSource} key="id" keyExpr="id">
          <Paging defaultPageSize={25} />
          <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
          <Scrolling showScrollbar="always" mode="standard" />
          {
            allowSelection ?
              <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
              : null
          }
          <HeaderFilter visible={true} allowSearch={true} />
          <SearchPanel visible={true} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Editing newRowPosition={"first"} refreshMode={"repaint"} mode="cell" allowUpdating={permissions && permissions.canCreate && allowUpdating} allowAdding={permissions && permissions.canCreate && allowAdding} allowDeleting={allowDeleting} />
          {
            dataColumns && dataColumns.length ? dataColumns.map((d) => renderField(d, dropDownData)) : null
          }
        </DataGrid>
      </div>
    }
    catch (e) { }
  }, [dataSource, dataColumns, dropDownData, hasDataChanged, selectedRowKeys, data, isLoading, entityClients, bulkUploadModalVisible])

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setHasDataChanged} isDataChanged={hasDataChanged} />
      <CustomSkelton>
        {Comp}
      </CustomSkelton>
    </React.Fragment>
  );
}
