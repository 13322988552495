import React from "react";
import watermarkImage from "assets/images/logos/publicis-groupe-logo.png";
import GothamNarrow from "assets/fonts/GothamNarrow-Book.otf"
import GothamNarrowBold from "assets/fonts/GothamNarrow-Book-Bold.otf"
import Html from 'react-pdf-html';
import { Page, Text, Document, StyleSheet, Font, View, Image } from '@react-pdf/renderer';

Font.register({
  family: 'GothamNarrow',
  src: GothamNarrow
});

Font.register({
  family: 'GothamNarrowBold',
  src: GothamNarrowBold
});

const HtmlStylesheet = {
  ul: {
    paddingHorizontal: 5,
    paddingTop: 5,
  },
  li: {
    fontSize: 9.5,
    fontFamily: 'GothamNarrow',
    lineHeight: 1.3,
    textAlign: 'left',
    paddingLeft: 6,
    paddingBottom: 5,
  },
  p: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 9.5,
    fontFamily: 'GothamNarrow',
    lineHeight: 1.3,
    textAlign: 'left',
    paddingHorizontal: 5,
    paddingTop: 5,
  },
  strong: {
    fontSize: 9.5,
    fontFamily: 'GothamNarrowBold',
    lineHeight: 1.3,
    textAlign: 'left',
  }
};

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    padding: 35,
    paddingBottom: 65
  },
  watermark: {
    position: 'absolute',
    width: '70%',
    height: 'auto',
    opacity: 0.12,
    top: '32%',
    left: '22%',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 11,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  table: {
    display: "table",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol_15: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol_30: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol_40: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol_85: {
    width: "85%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol_full: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: 5,
    marginBottom: 0,
    fontSize: 9.5,
    fontFamily: 'GothamNarrow',
    lineHeight: 1.3,
    textAlign: 'left'
  },
  tableCell_bold: {
    margin: 5,
    marginBottom: 0,
    fontSize: 9.5,
    fontFamily: 'GothamNarrowBold',
    lineHeight: 1.3,
    textAlign: 'left'
  },
  tableCell_center: {
    margin: 5,
    marginBottom: 0,
    fontSize: 9.5,
    fontFamily: 'GothamNarrow',
    lineHeight: 1.3,
    textAlign: 'center'
  },
  tableCell_bold_center: {
    margin: 5,
    marginBottom: 0,
    fontSize: 9.5,
    fontFamily: 'GothamNarrowBold',
    lineHeight: 1.3,
    textAlign: 'center'
  }
});

const ToPrint = ({ job_name, jdData, division_name }) => (  
  <Document>
    <Page style={styles.page}>
      <Image src={watermarkImage} style={styles.watermark} fixed />
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol_full}>
            <Text style={styles.tableCell_bold_center}>Job Description</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol_15}>
            <Text style={styles.tableCell_bold}>Job Title</Text>
          </View>
          <View style={styles.tableCol_40}>
            <Text style={styles.tableCell}>{job_name}</Text>
          </View>
          <View style={styles.tableCol_15}>
            <Text style={styles.tableCell_bold}>Division</Text>
          </View>
          <View style={styles.tableCol_30}>
            <Text style={styles.tableCell}>{division_name}</Text>
          </View>
        </View>
        {
          jdData && jdData.length > 0
            ? jdData.map((segment) => {
              if (segment.main_task) {
                return <>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol_full}>
                      <Text style={styles.tableCell_bold_center}>{segment.name.trim()}</Text>
                    </View>
                  </View>
                  {
                    segment.elements.map((element) => {
                      return <View style={styles.tableRow}>
                        {
                          !element.summary
                            ? <View style={styles.tableCol_15}>
                              <Text style={styles.tableCell_bold}>{element.name.trim()}</Text>
                            </View>
                            : null
                        }
                        <View style={styles.tableCol_85}>
                          <Html stylesheet={HtmlStylesheet} children={element.jd_values[0].value} resetStyles />
                        </View>
                      </View>
                    })
                  }
                </>
              }
              else {
                return <>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol_full}>
                      <Text style={styles.tableCell_bold_center}>{segment.name.trim()}</Text>
                    </View>
                  </View>
                  {
                    segment.elements.map((element) => {
                      return <View style={styles.tableRow}>
                        <View style={styles.tableCol_full}>
                          <Html stylesheet={HtmlStylesheet} children={element.jd_values[0].value} resetStyles />
                        </View>
                      </View>
                    })
                  }
                </>
              }
            })
            : null
        }
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
    </Page>
  </Document>
);

export default ToPrint;