import React, { useEffect, useContext } from 'react'
import { Context } from "../../utils/context/store/Store";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import HcTypeDataGrid from '../manage-headcount-type/DataGrid/HcTypeDataGrid'
import useRequestType from "../../utils/hooks/useRequestType";
import usePagePermissions from "../../utils/hooks/usePagePermissions";
import useBulkUpload from 'utils/hooks/useBulkUpload';

export default function ManageReqType({ routeKey, pageName }) {
  const { fetchRequestType, updateOrCreateRequestType, deleteRequestType, isLoading } = useRequestType(routeKey)
  const { bulkUpload } = useBulkUpload(routeKey)
  const [{ requestType }, dispatch] = useContext(Context)
  const { getPermissionsForPage } = usePagePermissions(routeKey)
  const permissions = getPermissionsForPage()

  useEffect(async () => {
    await fetchRequestType()
  }, [])

  return (
    <BaseLayOut pageTitle={pageName ?? "Manage Request Type"}>
      <HcTypeDataGrid
        columns={requestType && requestType.columns ? requestType.columns : []}
        rows={requestType && requestType.rows ? requestType.rows : []}
        isLoading={isLoading}
        permissions={permissions}
        hitApi={updateOrCreateRequestType}
        handleDelete={deleteRequestType}
        bulkUploadApi={bulkUpload} apiCallback={fetchRequestType} tableName={requestType?.tableName}
        uploadTemplateLink={requestType?.uploadTemplateLink}
      />
    </BaseLayOut>
  )
}