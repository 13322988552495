import React, { useEffect, useState } from "react";
import 'devextreme/dist/css/dx.light.css';
import { Button } from 'devextreme-react/button';
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import SelectBox from "devextreme-react/select-box";
import { TextBox } from "devextreme-react";
import { DEButton } from "utils/services/Helpers";
import { Switch } from 'antd';
import MDTypography from "components/MDTypography";
import DateBox from 'devextreme-react/date-box';
import { TagBox } from "devextreme-react/tag-box";

const RecruitmentDetails = ({ updateInternalMobilityDetails, headcountRequest, isLoading, hcData, masterRequestTypeId, user, id, type, cName, employee, mrId, mrsId, masterEmployees, jDate, masterRecruiters, masterRecruitmentStatus, routeKey, transactionHeadcountGroupId, disableInternalSubmitButton = false, apiCallBack = null, internalReplacementRequired = false, masterCurrencyId }) => {

  const [candidateType, setCandidateType] = useState(type)
  const [candidateName, setCandidateName] = useState(cName)
  const [masterEmployeeId, setMasterEmployeeId] = useState(employee)
  const [masterRecruiterId, setMasterRecruiterId] = useState(mrId)
  const [joiningDate, setJoiningDate] = useState(jDate)
  const [startDate, setStartDate] = useState(hcData?.startDate ?? null)
  const [endDate, setEndDate] = useState(hcData?.endDate ?? null)
  const [masterRecruitmentStatusId, setMasterRecruitmentStatusId] = useState(mrsId)
  const [replacementRequired, setReplacementRequired] = useState(internalReplacementRequired);
  const [status, setStatus] = useState(null)
  const [internalEmpData, setInternalEmpData] = useState(null)

  useEffect(() => { }, []);
  useEffect(() => {
    if (type) setCandidateType(type)
    if (cName) setCandidateName(cName)
    if (employee) setMasterEmployeeId(employee)
    if (mrId) setMasterRecruiterId(mrId)
    if (jDate) setJoiningDate(jDate)
    if (hcData && hcData.masterJobTypeId === 2) {
      setStartDate(hcData?.startDate ?? null)
      setEndDate(hcData?.endDate ?? null)
      setCandidateType(hcData.candidateType ?? !hcData?.pToF ? "External" : null)
    }
    if (mrsId) {
      setMasterRecruitmentStatusId(mrsId);
      const d = masterRecruitmentStatus.filter(ms => ms.id === mrsId)
      if (d && d.length && (d[0].label.toLowerCase() === "hired" || d[0].label.toLowerCase() === "filled")) {
        setStatus(d[0].label.toLowerCase())
      }
      else
        setStatus(null)
    }
  }, [id, type, cName, employee, masterEmployees, jDate, masterRecruiters, masterRecruitmentStatus, hcData, masterCurrencyId]);
  useEffect(() => { }, [candidateName, candidateType, masterEmployeeId, masterRecruiterId, joiningDate, masterRecruitmentStatusId, status, startDate, endDate]);
  useEffect(() => { }, [isLoading]);
  useEffect(() => { }, [headcountRequest]);

  /**
   * define the dropdown values of select box
   **/
  const candidateTypes = [
    {
      "id": "Internal",
      "label": "Internal",
    },
    {
      "id": "External",
      "label": "External",
    }
  ]

  /**
   * function use to value change in employee dropdown to store employee data
   **/
  const manageInternalEmployee = (e) => {
    setMasterEmployeeId(e.value)
    // setJoiningDate(null)
    const [emp] = masterEmployees && masterEmployees.length ? masterEmployees.filter(o => o.id === e.value) : null
    const intEmp = {
      updHc: {
        candidateType, candidateName, masterEmployeeId: e.value, oId: id, joiningDate, startDate, endDate,
        isInternal: true, masterRequestTypeId, user, masterRecruiterId, masterRecruitmentStatusId,
        transactionHeadcountGroupId,
      },
      newHc: {
        masterOrgDivisionId: null,
        masterOrgEntityId: null, masterCountryId: null,
        masterOrgVerticalId: null, masterOrgFunctionId: null,
        masterLevelId: null,
        levelId: emp && emp.hasOwnProperty('level') ? emp?.level?.id : emp?.masterLevelId
      },
      orgHc: [hcData]
    }
    setInternalEmpData(intEmp)
  };

  /**
   * function use to call the api to post data
   **/
  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = {
      candidateType, candidateName, masterEmployeeId, joiningDate, startDate, endDate,
      isInternal: candidateType && candidateType.toLowerCase() === "internal",
      recruiterChanged: mrId === null ? true : masterRecruiterId && mrId !== masterRecruiterId,
      masterRequestTypeId, user, masterRecruiterId, masterRecruitmentStatusId,
      transactionHeadcountGroupId
    }
    await updateInternalMobilityDetails(data, id, apiCallBack)
  }

  return (
    <form id={"recruitment-details"} key={"recruitment-details"} onSubmit={handleSubmit} noValidate>
      <MDBox py={1}>
        <MDBox p={2}>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TagBox
                  showSelectionControls={true}
                  disabled={disableInternalSubmitButton}
                  showClearButton={true}
                  dataSource={masterRecruiters}
                  displayExpr="label"
                  valueExpr="id"
                  placeholder={"Select Recruiters"}
                  defaultValue={masterRecruiterId}
                  onValueChanged={(e) => {
                    if (!e.value?.length) {
                      setCandidateType(null)
                      setCandidateName(null)
                      setMasterEmployeeId(null)
                      setStatus(null)
                      setJoiningDate(null)
                      setMasterRecruiterId(null)
                    } else setMasterRecruiterId(e.value)

                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectBox searchEnabled={true} showClearButton={mrsId === null}
                  placeholder={"Select Recruitment Status"} value={masterRecruitmentStatusId}
                  onValueChanged={(e) => {
                    setMasterRecruitmentStatusId(e.value)
                    const d = masterRecruitmentStatus.filter(ms => ms.id === e.value)
                    if (d && d.length && (d[0].label.toLowerCase() === "hired" || d[0].label.toLowerCase() === "filled")) {
                      setStatus(d[0].label.toLowerCase())
                    } else {
                      setStatus(null)
                      setCandidateType(null)
                      setCandidateName(null)
                      setMasterEmployeeId(null)
                      setJoiningDate(null)
                    }
                  }} displayExpr="label" valueExpr="id" dataSource={masterRecruitmentStatus.map(item => ({
                    ...item,
                    disabled: !hcData.isBtFilled && ["hired", "filled"].includes(item.label.toLowerCase())
                  }))} />
              </Grid>
            </Grid>
          </MDBox>
          {
            status && hcData?.masterJobTypeId !== 2
              ?
              <>
                <MDBox mt={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <SelectBox disabled={disableInternalSubmitButton} showClearButton={type === null}
                        placeholder={"Select Candidate Type"} value={candidateType}
                        onValueChanged={(e) => {
                          setCandidateType(e.value)
                          setCandidateName(null)
                          setMasterEmployeeId(null)
                          setJoiningDate(null)
                          // setStartDate(null)
                          // setEndDate(null)
                        }} displayExpr="label" valueExpr="id" dataSource={candidateTypes} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {
                        candidateType
                          ? candidateType.toLowerCase() === "internal"
                            ? <SelectBox disabled={internalReplacementRequired} searchEnabled={true}
                              showClearButton={employee === null} placeholder={"Select Candidate"}
                              value={masterEmployeeId} onValueChanged={(e) => {
                                setJoiningDate(null)
                                // setStartDate(null)
                                // setEndDate(null)
                                setMasterEmployeeId(e.value === "" ? null : e.value)
                              }} displayExpr="name" valueExpr="id" dataSource={masterEmployees} />
                            : <TextBox value={candidateName} required={true} mode={"text"}
                              placeholder={"Enter candidate Name"} showClearButton={cName === null}
                              onValueChanged={(e) => {
                                setJoiningDate(null)
                                // setStartDate(null)
                                // setEndDate(null)
                                setCandidateName(e.value === "" ? null : e.value)
                              }} />
                          : null
                      }
                    </Grid>
                  </Grid>
                </MDBox>
                {
                  masterEmployeeId || candidateName
                    ?
                    hcData.masterJobTypeId === 1 || hcData?.pToF
                      ? <MDBox mt={2}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} sm={6}>
                            <DateBox placeholder="Select Joining Date" disabled={internalReplacementRequired}
                              displayFormat={"dd-MM-yyyy"} showClearButton={true} defaultValue={joiningDate}
                              onValueChanged={(e) => setJoiningDate(e.value === "" ? null : e.value)}
                              type="date" pickerType="calendar" /* min={new Date()} */
                              openOnFieldClick={true} />
                          </Grid>
                          {
                            hcData?.pToF
                              ? null
                              : <Grid item xs={12} sm={6} alignItems="center">
                                {
                                  masterEmployeeId
                                    ? <MDBox mt={2}>
                                      <Grid container spacing={3} alignItems="center">
                                        <Grid item>
                                          <MDTypography variant="subtitle">
                                            Replacement Required
                                          </MDTypography>
                                        </Grid>
                                        <Grid item>
                                          <Switch disabled={internalReplacementRequired || status === 'filled'}
                                            checked={replacementRequired} onChange={() => {
                                              setReplacementRequired(wasOpened => wasOpened ? 0 : 1)
                                              manageInternalEmployee({ value: masterEmployeeId })
                                            }} />
                                        </Grid>
                                      </Grid>
                                    </MDBox>
                                    : null
                                }
                              </Grid>
                          }
                        </Grid>
                      </MDBox>
                      : !hcData?.pToF ? null : <MDBox mt={2}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} sm={6}>
                            <DateBox placeholder="Select Start Date" disabled={internalReplacementRequired}
                              displayFormat={"dd-MM-yyyy"} showClearButton={true} defaultValue={startDate}
                              onValueChanged={(e) => setStartDate(e.value === "" ? null : e.value)} type="date"
                              pickerType="calendar" /* min={new Date()} */ openOnFieldClick={true} />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <DateBox placeholder="Select End Date" disabled={internalReplacementRequired}
                              displayFormat={"dd-MM-yyyy"} showClearButton={true} defaultValue={endDate}
                              onValueChanged={(e) => setEndDate(e.value === "" ? null : e.value)} type="date"
                              pickerType="calendar" min={startDate} openOnFieldClick={true} max={startDate ? new Date(startDate).setMonth(new Date(startDate).getMonth() + 3) : null} />
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} sm={6} alignItems="center">
                            {
                              masterEmployeeId
                                ? <MDBox mt={2}>
                                  <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                      <MDTypography variant="subtitle">
                                        Replacement Required
                                      </MDTypography>
                                    </Grid>
                                    <Grid item>
                                      <Switch disabled={internalReplacementRequired} checked={replacementRequired}
                                        onChange={() => {
                                          setReplacementRequired(wasOpened => wasOpened ? 0 : 1)
                                          manageInternalEmployee({ value: masterEmployeeId })
                                        }} />
                                    </Grid>
                                  </Grid>
                                </MDBox>
                                : null
                            }
                          </Grid>
                        </Grid>
                      </MDBox>
                    : null
                }

              </> : null
          }
        </MDBox>
        <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1}>
          <MDBox mr={2} mt={2}>
            {
              candidateType && candidateType?.toLowerCase() === "internal" && replacementRequired
                ?
                <DEButton stylingMode={"contained"} type={"success"} text={"Continue"} to="/views/manage-headcount"
                  state={internalEmpData} isForIM={true} employeeSelected={disableInternalSubmitButton}
                  disabled={(hcData.masterJobTypeId === 1 ? joiningDate === null : (startDate === null && endDate === null)) || masterEmployeeId === employee && replacementRequired === internalReplacementRequired} />
                : <Button
                  disabled={masterRecruiterId === null ? true : candidateType && candidateType?.toLowerCase() === "external" && (hcData.masterJobTypeId === 1 ? joiningDate === null : (startDate === null && endDate === null)) ? true : candidateType && candidateType?.toLowerCase() === "internal" && (hcData.masterJobTypeId === 1 ? joiningDate === null : (startDate === null && endDate === null))}
                  stylingMode="contained" type="success" useSubmitBehavior={true} text="Submit" />
            }
          </MDBox>
        </MDBox>
      </MDBox>
    </form>
  );
}

export default RecruitmentDetails;
