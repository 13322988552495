import React, { useEffect, useState } from "react";
import { Modal } from 'antd';
import MDTypography from "components/MDTypography";
import { DEButton } from "utils/services/Helpers";
import CustomSkelton from "../../../../components/Skelton/CustomSkelton";
import MDBox from "../../../../components/MDBox";
import useManageHeadCounts from "../../../../utils/hooks/useManageHeadCounts";
import AllHcDataGrid from "./AllHcDataGrid";
import PriorityDataGrid from "./PriorityDataGrid";

const ApprovedHCModal = ({ title = "Recruitment Priority", btnTitle = "Set Recruitment Priority", permissions }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { getAllApprovedHeadCounts, updateTransactionSequence, approvedHCS, isLoading } = useManageHeadCounts()

  useEffect(() => { }, [isModalVisible])

  useEffect(() => { }, [approvedHCS])

  const showModal = () => {
    const fn = async () => await getAllApprovedHeadCounts()
    fn()
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  async function handleSubmit(e) {
    // await createUpdatePriority({
    //   ...priority,
    //   functionalAccessId: role
    // })
  }

  return (
    <>
      <DEButton disabled={false} style={{
        align: "right !important"
      }} stylingMode={"contained"} type={"contained"} text={btnTitle} onClick={showModal} />
      <Modal maskClosable={false} title={title} width={1600} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={<DEButton stylingMode={"contained"} type={"success"} text={"Ok"} onClick={handleCancel} />}>
        <MDTypography variant="h6">
        </MDTypography>
        <br />
        <CustomSkelton is_loading={isLoading}>
          <MDBox>
            <div style={{ padding: "16px" }}>
              <PriorityDataGrid rows={approvedHCS.rows} columns={approvedHCS.columns} permissions={permissions} postData={updateTransactionSequence} />
              {/*<AllHcDataGrid rows={approvedHCS.rows} columns={approvedHCS.columns} permissions={permissions}/>*/}
            </div>
          </MDBox>
        </CustomSkelton>
      </Modal>
    </>
  );
};

export default ApprovedHCModal;
