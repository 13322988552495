import React, { useEffect, useState } from "react";
import 'antd/dist/antd.css';
import { Modal } from 'antd';
import MDButton from 'components/MDButton';
import ReactJson from "react-json-view";
import { DEButton, isJSONValid } from "../../utils/services/Helpers";

const DocumentModal = ({ data, title = "View Document", btnTitle = "View Document" }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(async () => { }, [isModalVisible])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <DEButton disabled={false} stylingMode={"contained"} type={"normal"} text={btnTitle} onClick={showModal} />
      <Modal maskClosable={false} title={title} width={1200} className="justify-content-center" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={[
        <DEButton stylingMode={"contained"} type={"success"} text={"Ok"} onClick={handleCancel} />,
      ]}>
        <ReactJson src={isJSONValid(data)} name={null} />
      </Modal>
    </>
  );
};

export default DocumentModal;