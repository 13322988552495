import request from "../services/Http";
import { useContext, useState } from "react";
import { addKeyAndIndexFieldToArrayOfObjects } from "../services/Helpers";
import useSegments from "./useSegments";
import { toast } from 'react-toastify'
import { useIsLoading } from "./useIsLoading";
import { Context } from "../context/store/Store";
import { SET_ELEMENTS, SET_IS_LOADING } from "../context/store/Constants";

export default function useElements(segment) {

  const [{ new_elements }, dispatch] = useContext(Context)
  const [elements, setElements] = useState({})
  const { getSegments } = useSegments()
  const { isLoading, setIsLoading } = useIsLoading()

  async function updateOrCreateElements(masterJdSegmentId, data) {

    setIsLoading(true)
    dispatch({
      type: SET_ELEMENTS,
      payload: {}
    })

    try {
      const res = await request.put(`segment/${masterJdSegmentId}`, {
        elements: data
      })
      await getElementsBySegment(masterJdSegmentId)
      toast.success(res.data.data)
    }
    catch (e) {
    }
    setIsLoading(false)
  }

  async function getElementsBySegment(segment) {
    setIsLoading(true)

    try {
      const res = await request.get(`segment/${segment}/element`)
      let rows = res.data.elementRows
      const pageStructure = res.data.pageStructure

      if (new_elements && new_elements.hasOwnProperty('apiDelete') && new_elements.apiDelete) {
        rows = [...new_elements.rows, ...rows]
      }

      const dataTableData = { columns: pageStructure.columns, rows: addKeyAndIndexFieldToArrayOfObjects(rows), apiDelete: false }
      setElements(dataTableData)
    }
    catch (e) {
    }

    setIsLoading(false)

  }

  async function deleteElement(elements, masterJdSegmentId = null) {

    setIsLoading(true)
    try {
      const res = await request.delete('element', {
        data: {
          masterJdSegmentId: masterJdSegmentId,
          elements: elements
        }
      })
      await getSegments()
      toast.success(res.data.data)
    }
    catch (e) {
    }

    setIsLoading(false)
  }

  return { getElementsBySegment, deleteElement, updateOrCreateElements, elements, isLoading }
}