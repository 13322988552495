import React, { useState, useLayoutEffect } from "react";

export default function useScroll() {
  const [scrollPosition, setScrollPosition] = useState(null);

  function updateScrollPosition() {
    setScrollPosition(window.scrollY);
  }

  useLayoutEffect(() => {

    window.addEventListener("scroll", updateScrollPosition);

    // Call updateScrollPosition initially to set the initial scroll position
    updateScrollPosition();

    return () => window.removeEventListener("scroll", updateScrollPosition);
  }, []);

  return scrollPosition;
}