import { getUserInfo } from "../../../../utils/services/Helpers";

const form = {
  formId: "new-user-form",
  formField: {
    Name: {
      name: "name",
      label: "Name",
      type: "text",
      value: getUserInfo()?.name,
      errorMsg: "Name is Required.",
    },
    UserName: {
      name: "username",
      label: "User Name",
      type: "email",
      errorMsg: "User Name is required.",
      invalidMsg: "Your email address is invalid",
      value: getUserInfo()?.username,
    },
    Email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
      value: getUserInfo()?.email ?? null,
      disabled: !!getUserInfo()?.email
    },
    Market: {
      name: "market",
      label: "Market",
      type: "text",
      errorMsg: "Market is required.",
      value: getUserInfo()?.market ?? ""
    },
    Practice: {
      name: "practice",
      label: "Practice",
      type: "text",
      errorMsg: "Practice is required.",
      value: getUserInfo()?.practice ?? ""
    },
  },
};

export default form;
