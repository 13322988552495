import React, { useContext, useEffect, useState } from "react";
import '../manage-headcount.scss';
import 'devextreme/dist/css/dx.light.css';
import 'antd/dist/antd.css';
import { Button } from 'devextreme-react/button';
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import { Image, Modal } from "antd";
import MDTypography from "../../../components/MDTypography";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { Context } from "../../../utils/context/store/Store";
import { DEButton, getPageProperties, getTableColumns, getUserInfo, isObjectEmpty } from "../../../utils/services/Helpers";
import { useNavigate } from "react-router-dom"
import useHeadCountLeaver from "../../../utils/hooks/useHeadCountLeaver";
import SelectBox from "devextreme-react/select-box";
import ToggleGroupItems from "./ToggleGroupItems";
import request from "../../../utils/services/Http"
import useDataAccess from "utils/hooks/useDataAccess";
import { useIsLoading } from "../../../utils/hooks/useIsLoading";
import loadingGif from "../../../assets/images/gifs/loading.gif";
const _ = require("lodash")

const CloneHeadCountPopup = ({ headcounts, columns, dropDownData, routeKey, getHcByIdDetails, pagesAndPermissions = null, isPopupVisible, setPopupVisibility }) => {

  // const [isPopupVisible, setPopupVisibility] = useState(false);
  const [reqValue, setReqValue] = useState(null);
  const [hcValue, setHcValue] = useState(null);
  const [hcType, setHcType] = useState([]);
  const [{ hcRequestType, hcLeaver, dataAccess }, dispatch] = useContext(Context)
  const { getHcLeaverDetails, leaversData } = useHeadCountLeaver(routeKey)
  const { getUserDataAccessByModuleForLocalState } = useDataAccess(routeKey)
  const [selectedLeaver, setSelectedLeaver] = useState(null)
  const {isLoading, setIsLoading} = useIsLoading()
  let divisions = [...new Set(_.map(headcounts, 'masterOrgDivisionId'))]

  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      setReqValue(null)
      setHcValue(null)
      setHcType(null)
    }
  }, []);
  useEffect(() => { }, [headcounts]);
  useEffect(async () => {
    if (reqValue) {
      const hcTypes = hcRequestType.filter(obj => obj.id === reqValue)[0]['master_headcount_types']
      setHcType(hcTypes.filter(c => c.status === true && c.id !== 5))
    }
    if (reqValue === 2 && isObjectEmpty(leaversData)) await getHcLeaverDetails(null, true)
  }, [reqValue])
  useEffect(async () => { }, [hcValue])
  useEffect(() => {
  }, [leaversData])

  /**
   * function use to manage state to view/hide popup
   **/
  const togglePopup = () => {
    setPopupVisibility(!isPopupVisible);
    if (reqValue) {
      setReqValue(null)
      setHcValue(null)
      setHcType(null)
    }
  };


  /**
   * function use to call the api to post data
   **/
  const handleSubmit = async (e) => {
    // e.preventDefault()
    let hcs = headcounts
    let div = []
    if (getHcByIdDetails?.length) {
      const details = await hcByIDS(getHcByIdDetails)
      const da = await getUserDataAccessByModuleForLocalState(getUserInfo()?.id, 3)
      hcs = details?.data
      dropDownData = {
        ...details?.dropdownValues,
        ...da
      }
      const properties = getPageProperties(pagesAndPermissions, 'manage-headcount')
      columns = getTableColumns(properties.page.table, 'raise-hc')?.columns ?? []
    }
    let leaver = getLeaverData()
    if (hcs?.length) div = [...new Set(_.map(hcs, 'masterOrgDivisionId'))]

    if (reqValue === 2 && div?.length > 1) toast.info('You have multiple divisions selected therefore only those headcounts will be attached to leaver having same division.')

    columns.map(c => {
      c.disabled = false
      c.editable = true
      if (c.dataIndex === "masterRecruitmentStatusId" || c.dataIndex === "masterBudgetStatusId" || c.dataIndex === "masterCurrencyStatusId" || c.dataIndex === "budget") {
        c.editable = false
        c.required = false
        c.is_visible = false
      }
      return c
    })

    if (hcs.length) {

      hcs.map(h => {
        // h.attachments = h.attachment
        delete h.isDraft
        delete h?.fieldToHighlights
        delete h.groupId
        delete h.submittedOn
        delete h.submittedBy
        delete h.steps
        delete h.rCode
        delete h.reqCode
        delete h.attachment
        delete h.attachments
        delete h.status
        delete h.hold
        delete h.cStatus
        delete h.id
        delete h.transactionHeadcountGroupId
        delete h.transaction_headcount_group
        delete h.transaction_headcount_markets
        delete h.userId
        delete h.actionedByApproverId
        delete h.internalHeadcountId
        delete h.budget
        delete h.candidateType
        delete h.fileId
        delete h.candidateName
        delete h.cancelled
        delete h.reActivate
        delete h.dECVLId
        delete h.cancel_reason
        delete h.hold_reason
        delete h.activation_reason
        delete h.reactivationDate
        delete h.master_job_type
        delete h.master_recruitment_status
        delete h.transaction_files
        delete h.transaction_approvals
        delete h.canView
        delete h.canApprove
        delete h.needApproval
        delete h.canCancel
        delete h.canHoldOrReactivate
        delete h.canReactivate
        delete h.onHold
        delete h.canEditAfterApproval
        delete h.isCancelled
        delete h.re_activate
        delete h.wantsToConvertToFreelancer
        delete h.canEdit
        delete h.isInternal
        delete h.showInternalMobilityModal
        delete h.replacementType
        delete h.endFreelancerRequest
        delete h.disableInternalSubmitButton
        delete h.minRange
        delete h.transactionLeaverId
        delete h.masterRecruiterId
        delete h.budget
        delete h.masterBudgetStatusId
        delete h.masterCurrencyId
        delete h.candidateType
        delete h.candidateName
        delete h.masterEmployeeId
        delete h.joiningDate
        delete h.masterCurrencyId
        delete h.reportTo;
        delete h.teamStructure;
        delete h.directReports;
        delete h.relocation;
        delete h.scope;
        delete h.downgrade;
        delete h.significance;
        delete h.bigWins;
        delete h.dailyTasks;
        delete h.skillsMustHave;
        delete h.skillsNiceToHave;
        delete h.qualities;
        delete h.clientDetails;
        delete h.interviewQuestions;
        delete h.interviewSchedule;
        delete h.extraInfo;
        delete h.isRollback;
        delete h?.createdAt;
        delete h?.updatedAt;
        delete h?.joiningDate;
        delete h?.hireDate;
        delete h?.canInitiatorEditAfterApproval;
        delete h?.canCancelRequestBeforeApproval;
        delete h?.cancelRequestType;
        delete h?.hc_version;
        delete h?.transactionHeadCountId;


        h.id = uuidv4()
        h.newRow = true
        h.dStatus = false;
        h.onHold = false;
        h.isCancelled = false
        h.re_activate = false
        h.pToF = false
        h.masterRecruitmentStatusId = 1
        h.attachment = null
        h.clonedHCID = h.id
        h.masterRequestTypeId = parseInt(reqValue)
        h.masterHeadcountTypeId = hcValue !== null ? parseInt(hcValue) : 4
        h.startDate = null
        h.endDate = null
        h.masterOrgHfmId = dropDownData?.masterOrgHfmId?.filter(b => b.id === h.masterOrgHfmId && b.isValid === true)?.length ? h.masterOrgHfmId : null
        h.master_clients = h.master_clients.filter(id => dropDownData?.master_clients?.find(obj => obj.id === id && obj.isValid));

        if (reqValue === 2) {
          const d = leaversData?.dropdownValues["masterEmployeeId"]?.filter(e => e.id === selectedLeaver) ?? null;
          h.leaverDivisionId = d[0].division.id
        }
        return h
      })
    }

    if (reqValue === 2 && selectedLeaver !== null && hcs.length) {
      if (div.length > 1 || divisions.length > 1) hcs = hcs.filter(hc => hc.masterOrgDivisionId === leaver.division)
      leaver['cols'] = columns
      leaver['headcounts'] = hcs
      leaver['replacementRequired'] = hcs.length ?? 1
      const hfms = dropDownData?.masterOrgHfmId?.filter(h => h.isValid === true)
      const clients = dropDownData?.master_clients?.filter(h => h.isValid === true)
      delete dropDownData?.masterOrgHfmId
      delete dropDownData?.master_clients
      dropDownData['masterOrgHfmId'] = hfms
      dropDownData['master_clients'] = clients
      leaver['dropDownData'] = dropDownData
    }

    const rows = reqValue === 2 ? [leaver] : hcs
    if (rows.length) {
      const data = { requestId: parseInt(reqValue), headcountTypeId: parseInt(hcValue), rows, columns }
      localStorage.setItem('clonedHCS', JSON.stringify(data))
      toast.success('headcount cloned successfully')
      setIsLoading(false)
      navigate('/views/manage-headcount')
    }
  }

  const hcByIDS = async (hcIDS) => {
    setIsLoading(true)
    try {
      const res = await request.post('headcount/request/hc-by-ids', { hcIDS })
      if (res) {
        return {
          data: res.data.data,
          dropdownValues: res.data.dropdownValues,
        }
      }
    }
    catch (e) {
      setIsLoading(false)
    }
  }

  const getLeaverData = () => {
    if (reqValue === 2) {
      const d = leaversData.dropdownValues["masterEmployeeId"].filter(e => e.id === selectedLeaver);

      if (d && d.length) {
        return {
          id: uuidv4(),
          masterEmployeeId: selectedLeaver,
          newRow: true,
          routeKey: routeKey ?? "manage-headcount",
          nr: true,
          jobName: d[0].jobName,
          masterOrgDivisionId: d[0].masterOrgDivisionId,
          division: d[0].division.id,
          masterOrgEntityId: d[0].masterOrgEntityId,
          entity: d[0].entity.id,
          masterCountryId: d[0].masterCountryId,
          country: d[0].country.id,
          masterLevelId: d[0].masterLevelId,
          level: d[0].level.id,
          masterOrgHfmId: d[0].masterOrgHfmId,
          hfm: d[0].hfm.id,
          replacementRequired: 1,
          regrettable: false,
          redundancy: false,
          dStatus: false,
          onHold: false,
          isCancelled: false,
          re_activate: false,
          okToHire: false,
          emailProxyRequired: false,
          masterRequestTypeId: 2,
          masterHeadcountTypeId: 4,
        }
      }
    }

    return null
  }

  return (
    <>
      <Modal className="recruitment-stage" maskClosable={false} title={"Clone Headcounts"} bodyStyle={{ paddingBottom: '25px' }} width={1000} height={500} open={isPopupVisible} onCancel={togglePopup}
        footer={
          <>
            <DEButton stylingMode={"contained"} type={"success"} text={"Continue"} disabled={isLoading ?? !reqValue ? true : reqValue === 2 && selectedLeaver !== null ? false : !hcValue} onClick={handleSubmit} />
            &nbsp;
            <Button disabled={isLoading} stylingMode={"contained"} type={"danger"} text={"Cancel"} onClick={togglePopup} />
          </>
        }
      >
        <div className="container">
          <div className="manage-headcount">
            <div className="manage-headcount__toggle-group-container">
              <ToggleGroupItems
                disabled={isLoading}
                title={'Request Type'}
                items={hcRequestType}
                checkedId={reqValue}
                onChange={setReqValue}
              />
              <ToggleGroupItems
                disabled={isLoading}
                title={'Headcount Type'}
                items={hcType}
                checkedId={hcValue}
                onChange={setHcValue}
              />
            </div>
          </div>
          <MDBox ml={1}>
            {
              reqValue === 2 && divisions.length > 1
                ?
                <>
                  <MDTypography variant="h6" fontWeight="medium">
                    You have multiple divisions selected therefore only those headcounts will be attached to leaver having same division.
                  </MDTypography>
                  <br />
                </>
                : null
            }
            {
              reqValue === 2 /* && !isObjectEmpty(leaversData) */ ?
                <Grid style={{ display: "flex" }} item xs={12} sm={9}>
                  <Grid style={{ paddingTop: "6px", paddingLeft: "8px" }} item xs={4} sm={2}>
                    <MDTypography variant="subtitle3" fontWeight="regular">Employee:</MDTypography>
                  </Grid>
                  <Grid item xs={8} sm={7}>
                    <SelectBox disabled={isObjectEmpty(leaversData)} searchEnabled={true} showClearButton={true} placeholder={"Select..."} value={selectedLeaver} onValueChanged={(e) => {
                      setSelectedLeaver(e.value)
                    }} displayExpr="email" valueExpr="id" dataSource={getHcByIdDetails?.length ? leaversData?.dropdownValues?.masterEmployeeId ?? [] : leaversData?.dropdownValues?.masterEmployeeId?.filter(em => divisions.includes(em?.division?.id))?.sort((a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase())) ?? []} />
                  </Grid>
                </Grid> : null
            }
          </MDBox>
          {
            isLoading ?
              <MDBox mt={2} mb={2} style={{ textAlign: "center" }}>
                <Image
                  title="Loading"
                  alt="Loading"
                  width={70}
                  src={loadingGif}
                  preview={false}
                />
              </MDBox> : null
          }
        </div>
      </Modal>
    </>
  );
}

export default CloneHeadCountPopup;
