import React, { useContext, useEffect, useState } from "react"
import { Context } from "utils/context/store/Store";
import useValues from 'utils/hooks/useValues';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import usePagePermissions from "../../utils/hooks/usePagePermissions";
import Select from "react-select";
import { Grid } from "@mui/material";
import { selectCustomStyles } from "../../utils/services/Helpers";
import ManageValuesDataGrid from "./DataGrid/ManageValuesDataGrid";

const options = [
  {
    value: 'generic',
    label: 'Generic'
  },
  {
    value: 'level',
    label: 'Levels'
  }];

function ManageValues({ routeKey, pageName }) {

  const { getValues, deleteValues, updateOrCreateValues, isLoading } = useValues(routeKey)
  const [{ values }, dispatch] = useContext(Context)
  const [value, setValue] = useState(null);
  const [dValue, setDValue] = useState(null);
  const { getPermissionsForPage } = usePagePermissions(routeKey)


  useEffect(() => { }, [dValue])
  useEffect(async () => {
    if (value !== null)
      await getValues(value)
  }, [])
  useEffect(async () => {
    if (value !== null)
      await getValues(value)
  }, [value])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox pt={3} pl={3} pr={3} pb={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {pageName ?? "Manage Values"}
            </MDTypography>
          </MDBox>
          <MDBox pt={2} pl={3} pr={3} pb={3}>
            <Grid item xs={6} sm={2}>
              <Select options={options} value={dValue} styles={selectCustomStyles} isSearchable menuPortalTarget={document.body} onChange={(val) => {
                setDValue(val)
                setValue(val.value)
              }} />
            </Grid>
            {value
              ? <ManageValuesDataGrid loading={isLoading} columns={values.columns} rows={values.rows}
                postData={updateOrCreateValues} handleDelete={deleteValues} valueToFetch={value}
                isDeleteable={false} defaultKey={"sequence"} isForValues={true}
                permissions={getPermissionsForPage(routeKey)} allowDeleting={false} allowAdding={false} allowUpdating={true} />
              : null
            }
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ManageValues;