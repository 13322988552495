import BaseLayOut from "../../components/Layout/BaseLayOut";
import useFreelancerManagement from "../../utils/hooks/useFreelancerManagement";
import { useEffect } from "react";
import FreelancerManagementDataGrid from "./DataTable/FreelancerManagementDataGrid";
import usePagePermissions from "../../utils/hooks/usePagePermissions";

export default function FreelancerManagement({routeKey, pageName}){
  const {getAllFreelancers, freelancers, isLoading} = useFreelancerManagement(routeKey)
  const {getPermissionsForPage} = usePagePermissions(routeKey)

  useEffect(() => {
    const fn = async () => await getAllFreelancers()
    fn()
  },[])

  useEffect(() => {}, [freelancers, isLoading]);

  return <BaseLayOut pageTitle={pageName}>
    <FreelancerManagementDataGrid rows={freelancers?.rows} columns={freelancers?.columns} hcDetailColumns={freelancers?.hcDetailColumns} isLoading={isLoading} permissions={getPermissionsForPage()}/>
  </BaseLayOut>
}
