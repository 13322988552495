import { useContext, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { rootNodes } from "../../models/nodes.model";
import {
  createGElement,
  getDimensions,
  isDescendant,
  handleZoom,
  zoomOut,
  rotateParentNode,
  normalizeRotation,
  getSvgDimensions,
} from "../../helpers/d3-methods";
import { createLinkGenerator, createLinkLabel, createNodes } from "../../helpers/d3-nodes";
import { CapabilityContext } from "../../contexts/capability-context";
import "./tree-chart.scss";
import "tippy.js/dist/tippy.css";

export const TreeChart = () => {
  // context and states
  const {
    selectedCompany,
    setSelectedCompany,
    setShowContactInfo,
    setIsReset,
    isReset,
    setSelectedNode,
  } = useContext(CapabilityContext);
  const [nodesList, setNodesList] = useState();
  const [gElement, setGElement] = useState();
  const [svgElement, setSvgElement] = useState();
  const [currentRotation, setCurrentRotation] = useState(0);
  const [currentScale, setCurrentScale] = useState(0);
  const [currentTransition, setCurrentTransition] = useState();

  const ref = useRef(null);
  let dataNodes = JSON.parse(localStorage.getItem('dataNodes'))

  useEffect(() => {
  }, []);
  useEffect(() => {
    setupSVG();
  }, [ref, rootNodes]);

  useEffect(() => {
    if (selectedCompany && currentRotation !== 0 && gElement) {
      zoomOut(gElement, svgElement, currentRotation, handleSelectedCompany);
    } else {
      handleSelectedCompany();
    }
  }, [selectedCompany, nodesList, gElement, svgElement]);

  useEffect(() => {
    if (isReset) {
      zoomOut(gElement, svgElement, currentRotation, resetSvg);
    }
  }, [isReset]);

  useEffect(() => {
    if (currentScale) {
      handleZoom(svgElement, gElement, currentScale, currentTransition);
    }
  }, [currentScale, currentTransition]);

  // Function to setup the SVG
  function setupSVG() {
    // Conditions to stop execution
    if (!ref.current || !rootNodes) return;
    if (ref.current) {
      d3.select(ref.current)?.select("*").remove();
    }
    // SVG and root hierarchy creation
    const svg = d3.select(ref.current);
    const root = d3.hierarchy(rootNodes);

    // Tree layout and info
    const treeLayout = d3
      .tree()
      .size([2 * Math.PI, 1400])
      .separation(function (a, b) {
        if (a.depth === 1 || b.depth === 1) {
          return 15;
        }
        if (a.depth === 2 || b.depth === 2) {
          return 7;
        }
        return 2;
      });

    const information = treeLayout(root);

    // Dimensions and g element creation
    const { width, height } = getDimensions(svg);
    const g = createGElement(svg, width, height);

    // set transition
    setCurrentTransition({ x: width / 2, y: height / 2 });

    // Zoom handling
    handleZoom(svg, g, currentScale);

    // Links and nodes creation
    createLinkGenerator(g, information);
    createLinkLabel(g, information);
    const nodes = createNodes(g, information, handleClickOnNode);

    // Update state
    setSvgElement(svg);
    setGElement(g);
    setNodesList(nodes);
  }

  function handleSelectedCompany() {
    // Checking for required values
    if (
      !ref.current ||
      !rootNodes ||
      !selectedCompany ||
      !nodesList ||
      !gElement ||
      !svgElement ||
      !dataNodes
    )
      return;

    // Get the selected node
    const selectedNode = nodesList.data().find((node) => node.data.id === selectedCompany);
    if (!selectedNode) return;

    // Setting rotation and zooming to selected node
    zoomToNode(gElement, selectedNode);

    // Helper function to check if a node is the current node or its descendant
    const isCurrentNodeOrDescendant = (node) =>
      node.depth !== 0 &&
      !isDescendant(node, selectedNode) &&
      node.data.id !== selectedNode.data.parentId;

    // Classing nodes
    nodesList.classed("node-non-selected", isCurrentNodeOrDescendant);
    nodesList.classed("node-selected", (node) => isDescendant(node, selectedNode));

    // Helper functions to get link class and stroke color
    const getLinkClass = (link) =>
      !isDescendant(link.target, selectedNode) &&
      link.target.data.id !== selectedNode.data.parentId;
    const getStrokeColor = (link) => {
      const isLinkDescendant = getLinkClass(link);
      if (isLinkDescendant) {
        return "grey";
      }
      const color =
        selectedNode.data.color ||
        dataNodes?.find((e) => e.id == selectedNode.data.parentId)?.color;
      return color || "red";
    };

    // Classing links and setting stroke color
    gElement
      .selectAll(".link")
      .classed("link-non-selected", getLinkClass)
      .attr("stroke", getStrokeColor);

    gElement
      .selectAll(".link-label")
      .classed("link-non-selected", getLinkClass)
      .attr("stroke", getStrokeColor);
  }

  const zoomToNode = (g, node) => {
    const numChildren =
      node.children?.reduce((acc, child) => acc + (child.children?.length || 0), 0) || 0;
    const scale = Math.abs(3 - numChildren * 0.009) || 3 - (node.x / 2) * 0.2;

    const targetRotation = normalizeRotation(((-node.x + Math.PI / 2) * 180) / Math.PI);
    const currentRotationNormalized = normalizeRotation(currentRotation);

    let delta = normalizeRotation(targetRotation - currentRotationNormalized);

    delta += delta < 0 || delta > 180 ? 360 : 0;
    delta %= 360;

    const interpolateRotate = d3.interpolate(
      currentRotationNormalized,
      currentRotationNormalized + delta
    );
    let translate;

    const { width, height } = getSvgDimensions(svgElement);
    const targetTransform = { k: scale, x: width / 2, y: height / 2 };
    const initialTranslate = [targetTransform.x, targetTransform.y];
    const targetTranslate = [targetTransform.x, targetTransform.y];
    const interpolateTranslate = d3.interpolate(initialTranslate, targetTranslate);

    executeNodeZoomTransition(
      g,
      interpolateRotate,
      interpolateTranslate,
      translate,
      scale - 0.8,
      node
    );
  };

  const executeNodeZoomTransition = (
    g,
    interpolateRotate,
    interpolateTranslate,
    translate,
    scale,
    node
  ) => {
    let rotation;
    g.transition()
      .duration(700)
      .attrTween("transform", () => {
        return (t) => {
          rotation = interpolateRotate(t);
          rotateParentNode(rotation);
          translate = interpolateTranslate(t);
          setCurrentRotation(rotation);
          return `translate(${translate[0]}, ${translate[1]}) scale(0.4) rotate(${rotation})`;
        };
      })
      .on("end", () => executeNodeScaleTransition(g, translate, rotation, scale, node));
  };

  const executeNodeScaleTransition = (g, translate, rotation, scale, node) => {
    const interpolateScale = d3.interpolateNumber(1, scale);
    let nodeScale;
    g.transition()
      .duration(1700)
      .delay(500)
      .attrTween("transform", () => {
        return (t) => {
          nodeScale = interpolateScale(t);
          return `translate(${translate[0]}, ${translate[1]}) scale(0.4) rotate(${rotation}) scale(${nodeScale})`;
        };
      })
      .on("end", () => executeCustomZoomTransition(g, translate, rotation, nodeScale, node));
  };

  const executeCustomZoomTransition = (g, translate, rotation, nodeScale, node) => {
    const nodeTransition = node.depth === 1 ? 800 : node.children?.length ? 1100 : 1300;
    const interpolateTranslateX = d3.interpolateNumber(translate[0], translate[0] - nodeTransition);
    const interpolateCustomScale = d3.interpolateNumber(nodeScale, nodeScale - 0.1);
    let translateX;
    g.transition()
      .duration(1700)
      .attrTween("transform", () => {
        return (t) => {
          translateX = interpolateTranslateX(t);
          const customZoom = interpolateCustomScale(t);
          setCurrentScale(customZoom);
          return `translate(${translateX}, ${translate[1]}) scale(0.4) rotate(${rotation}) scale(${customZoom})`;
        };
      })
      .on("end", () => {
        setCurrentTransition({ x: translateX, y: translate[1] });
      });
  };

  function handleClickOnNode(node) {
    setSelectedCompany(node.data.id);

    if (!node.children?.length) {
      setSelectedNode(node.data);
      setShowContactInfo(true);
    }
  }

  function resetSvg() {
    setCurrentRotation(0);
    setCurrentScale(0);
    setupSVG();
    setIsReset(false);
  }

  return (
    <>
      <svg ref={ref} width={"100%"} height={"100%"} scale={1} />
    </>
  );
};
