import MDBox from "components/MDBox";
import React, { useState, useEffect } from "react";
import CheckboxTree from "react-checkbox-tree";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import IndeterminateCheckBoxRoundedIcon from "@mui/icons-material/IndeterminateCheckBoxRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import Grid from "@mui/material/Grid";
import MDInput from "../../../MDInput";

export function CheckboxTreeContainer({ treeDataSource, nodeChecked, handleNodesChecked, combinationVal }) {
  const [nodeExpanded, setNodeExpanded] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const includeSearchBoxIn = ['market', 'hfms', 'master_employee', 'master_departments', 'master_client']

  useEffect(() => {
    return () => {
      setFilteredData([])
      setSearchKeyWord('')
    }
  }, []);
  useEffect(() => { }, [filteredData, searchKeyWord, selectedData]);

  const createTreeKey = (node) => {
    if (node.children) {
      return node.value + ":" + node.children.map(createTreeKey).join(",");
    }
    return node.value;
  };

  return (
    <>
      {treeDataSource && treeDataSource[0].children.length > 0 ? (
        <MDBox py={2} className="CheckboxTreeContainer">
          <Grid item>
            <Grid container spacing={2} alignItems="right" justifyContent="flex-end">
              <Grid item>
                {
                  nodeExpanded?.length && includeSearchBoxIn.includes(combinationVal?.toLowerCase())
                    ?
                    <>
                      {/*<p>
                        {
                          !filteredData?.length && searchKeyWord
                            ? "No Record found."
                            : ""
                        }
                      </p>*/}
                      <MDInput id="outlined-search" label="Enter value to search" type="search" onChange={(e) => {
                        e.preventDefault()
                        setSearchKeyWord(e.target.value)
                        if (e?.target?.value !== "" && e?.target?.value !== null && e?.target?.value !== undefined) {
                          const treeDataSourceCopy = [...treeDataSource]
                          const data = treeDataSourceCopy[0].children.every((e) => !e.children?.length) ? treeDataSourceCopy : treeDataSourceCopy[0].children
                          const dataToFilter = data[0]?.children
                          const filteredData = dataToFilter?.filter((item) => {
                            return item.label.toLowerCase().includes(e.target.value.toLowerCase())
                          });
                          if (filteredData?.length) {
                            data[0] = {
                              ...data[0], children: filteredData
                            }
                            setFilteredData(data)
                          } else setFilteredData([])
                        }
                        else setFilteredData([])
                      }} />
                    </>
                    : ""
                }
              </Grid>
            </Grid>
          </Grid>
          <CheckboxTree
            nodes={
              filteredData?.length ?
                filteredData[0].children.every((e) => !e.children?.length)
                  ? filteredData
                  : filteredData[0].children
                : treeDataSource[0].children.every((e) => !e.children?.length)
                  ? treeDataSource
                  : treeDataSource[0].children
            }
            checked={nodeChecked}
            expanded={nodeExpanded}
            onCheck={(values, nodeObj) => {
              let updatedData = []
              if (!nodeObj?.checked) updatedData = selectedData?.length ? [...selectedData.filter(s => values.includes(s)) ?? []] : values
              else updatedData = selectedData?.length ? [...selectedData, ...values] : values
              setSelectedData(updatedData)
              handleNodesChecked(updatedData)
            }}
            onExpand={setNodeExpanded}
            showNodeIcon={false}
            icons={checkboxTreeIcons}
          />
        </MDBox>
      ) : null}
    </>
  );
}

const checkboxTreeIcons = {
  check: <CheckBoxRoundedIcon fontSize="medium" />,
  uncheck: <CheckBoxOutlineBlankRoundedIcon fontSize="medium" />,
  halfCheck: <IndeterminateCheckBoxRoundedIcon fontSize="medium" />,
  expandClose: <KeyboardArrowRightRoundedIcon fontSize="medium" />,
  expandOpen: <KeyboardArrowDownRoundedIcon fontSize="medium" />,
  expandAll: <AddBoxIcon hint={"Expand All"} fontSize="medium" />,
  collapseAll: <IndeterminateCheckBoxRoundedIcon hint={"Collapse All"} fontSize="medium" />,
  parentClose: null,
  parentOpen: null,
  leaf: null,
};
