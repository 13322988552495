import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { Name, UserName, Email, Market, Practice},
} = checkout;

const validations = [
  Yup.object().shape({
    [Name.name]: Yup.string().required(Name.errorMsg),
    [UserName.name]: Yup.string().required(UserName.errorMsg),
    // [Email.name]: Yup.string().required(Email.errorMsg).email(Email.invalidMsg),
    // [Market.name]: Yup.string().required(Market.errorMsg),
    // [Practice.name]: Yup.string().required(Practice.errorMsg),
  })
];

export default validations;
