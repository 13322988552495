import 'devextreme-react/text-area';
import Form, {
  GroupItem, Label, SimpleItem,
} from "devextreme-react/form";
import { useState } from "react";
import MDBox from "../../../../components/MDBox";
import {
  CustomFormFileInput,
  FormFileUploader,
} from "../../../../components/CustomDataGridComponents";
import { DEButton, getUserInfo } from "../../../../utils/services/Helpers";
import * as React from "react";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import "../../../manage-headcounts/mhc/all-hc.scss";

const TransactionCapabilityForm = ({data, permissions, postAPI}) => {

  const [transactionData, setTransactionData] = useState(data);

  /**
   * Handle Form Data Change
   **/
  const formFieldDataChanged = (e) => {
    setTransactionData({
      ...transactionData,
      [e.dataField]: e.value
    })

  }

  const handleSubmit = async () => {
    const fieldsToIgnore = ["id", "entityVerticalCapabilityId"];
    const hasNonNullValue = Object.entries(transactionData).some(([key, value]) =>
      value !== null && typeof value !== 'boolean' && !Array.isArray(value) && !fieldsToIgnore.includes(key)
    );

    if (hasNonNullValue) await postAPI([{
      ...transactionData,
      fileId: transactionData.id
    }])
    else toast.error("Please fill at least one value other than attachment.")
  }

  return (
    <MDBox id="HcFormDetailsContainer">
      <div style={{ padding: "16px" }}>
        <Form id="HcFormDetails" className="HcFormContent"
              onFieldDataChanged={formFieldDataChanged}
              formData={transactionData}
              width={"100%"} style={{ marginBottom: "30px" }}>
          <GroupItem colCount={2}>

            <SimpleItem dataField={"makeItRight"} editorType="dxCheckBox" name={"makeItRight"} isRequired={false}
                        editorOptions={{ disabled: transactionData?.userId !== null && transactionData?.userId !== getUserInfo()?.id ? true : !permissions?.canCreate }}>
              <Label text={"Make It Right"} />
            </SimpleItem>

            <SimpleItem dataField={"makeItMagic"} editorType="dxCheckBox" name={"makeItMagic"}
                        isRequired={false}
                        editorOptions={{ disabled: transactionData?.userId !== null && transactionData?.userId !== getUserInfo()?.id ? true : !permissions?.canCreate }}>
              <Label text={"Make It Magic"} />
            </SimpleItem>

            <SimpleItem dataField={"makeItWork"} editorType="dxCheckBox" name={"makeItWork"}
                        isRequired={false}
                        editorOptions={{ disabled: transactionData?.userId !== null && transactionData?.userId !== getUserInfo()?.id ? true : !permissions?.canCreate }}>
              <Label text={"Make It Work"} />
            </SimpleItem>

            <SimpleItem dataField={"whatIsIt"} editorType="dxTextArea" name={"whatIsIt"}
                        isRequired={false}
                        visible={true}
                        editorOptions={{ readOnly: transactionData?.userId !== null && transactionData?.userId !== getUserInfo()?.id ? true : !permissions?.canCreate, disabled: false }}>
              <Label text={"What is It"} />
            </SimpleItem>

            <SimpleItem dataField={"howDoesItWork"} editorType="dxTextArea" name={"howDoesItWork"}
                        isRequired={false}
                        visible={true}
                        editorOptions={{ readOnly: transactionData?.userId !== null && transactionData?.userId !== getUserInfo()?.id ? true : !permissions?.canCreate, disabled: false }}>
              <Label text={"How Does It Work"} />
            </SimpleItem>

            <SimpleItem dataField={"whyDoClientsNeedIt"} editorType="dxTextArea" name={"whyDoClientsNeedIt"}
                        isRequired={false}
                        visible={true}
                        editorOptions={{ readOnly: transactionData?.userId !== null && transactionData?.userId !== getUserInfo()?.id ? true : !permissions?.canCreate, disabled: false }}>
              <Label text={"Why Do Clients Need It"} />
            </SimpleItem>

            <SimpleItem dataField={"attachment"} name={"attachment"}
                        isRequired={false}
                        visible={transactionData?.userId === null}
                        component={(props) => FormFileUploader(props, transactionData)}
                        editorOptions={{
                          disabled: transactionData?.userId !== null && transactionData?.userId !== getUserInfo()?.id ? true : !permissions?.canCreate,
                          setValue: (e, dataIndex) => {
                            transactionData[dataIndex] = e
                            setTransactionData(transactionData)
                          }
                        }}>
              <Label text={"Attachment"} />
            </SimpleItem>

            <SimpleItem dataField={"attachments"} name={"attachments"}
                        isRequired={false}
                        visible={transactionData?.userId !== null && transactionData?.attachments?.length}
                        component={CustomFormFileInput}
                        editorOptions={{ disabled: !permissions?.canCreate && transactionData?.userId !== null}}>
              <Label text={"Attachment"} />
            </SimpleItem>
          </GroupItem>
        </Form>
        <MDBox style={{ textAlign: "right" }}>
          <DEButton visible={transactionData?.userId !== null && transactionData?.userId !== getUserInfo()?.id ? false : permissions && permissions.canCreate} disabled={transactionData?.userId !== null && transactionData?.userId !== getUserInfo()?.id} stylingMode={"contained"} type={"success"} text={"Submit"} onClick={handleSubmit} style={{ marginLeft: "10px" }} />
        </MDBox>
      </div>
    </MDBox>
  );
};

export default TransactionCapabilityForm;
