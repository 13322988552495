import * as React from "react";
import { useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import "../components/styles.scss"
import MDBox from "../../../components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import loadingGif from "assets/images/gifs/loading.gif";
import { Image } from "antd";
import { Accordion, AccordionSummary, AccordionDetails } from "../../../utils/services/Helpers";
import { ProgressSteps } from "../../../components/CustomDataGridComponents";
import HcForm from "./components/HcForm"
import HcLeaverForm from "./components/HcLeaverForm";
import LeaverReplacementAccordion from "./LeaverReplacementAccordion";
import Swal from "sweetalert2";

const HcFormsView = React.memo(({ data, hasDataChanged, columns, dropDownData, masterTransactionType, permissions, dataSource, setDataSource, setHasDataChanged, routeKey, apiCallBack, setDgData, isDraft, isCloned = false, setIsReplacementRequired, isInternal = false, internalEmpData = null, headcountRequest }) => {

  const d = localStorage.getItem('LR') ? JSON.parse(localStorage.getItem('LR')) : null
  const leaverReplacements =  d?.replacementRequired > 0 && d?.headcounts?.length ? d?.headcounts : []
  useEffect(() => {
    const element = document?.querySelector('.css-e6tepc')
    const wdth = localStorage.getItem('hcFormWidth')
    const hght = localStorage.getItem('hcFormHeight')
    const screenSize = localStorage.getItem('screenSize')
    if (element && !wdth && !hght && !screenSize)
    {
      localStorage.setItem('hcFormWidth', element.offsetWidth)
      localStorage.setItem('hcFormHeight', element.offsetHeight)
      localStorage.setItem('screenSize', window.innerWidth)
    }
    return () => {
      localStorage.removeItem('hcFormWidth')
      localStorage.removeItem('hcFormHeight')
      localStorage.removeItem('screenSize')
    }
  }, []);

  const handleDelete = (index) => {

    Swal.fire({
      title: 'Are you sure?',
      text: 'This will remove this replacement',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      heightAuto: false,
      height: '200px'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedHeadcounts = {
          ...data,
          headcounts: data.headcounts.filter((item, i) => i !== index),
          replacementRequired: data.headcounts.filter((item, i) => i !== index).length
        }
        const newData = dataSource && dataSource?.map(item => item.id === updatedHeadcounts.id ? updatedHeadcounts : item);
        setDataSource(newData)
        setHasDataChanged(true)
      }
    });
  };

  return (
    <React.Fragment>
      {Object.keys(data).length
        ?
        <Card>
          {Object.keys(data).length ?
            <MDBox px={3} py={3}>
              {data && data?.steps && data?.steps.length ?
                <MDBox py={1}>
                  <ProgressSteps steps={data.steps} />
                </MDBox>
                : null
              }
              {data && dropDownData && masterTransactionType ?
                <MDBox pt={2}>
                  <Accordion defaultExpanded={masterTransactionType === "transaction_leaver" && data.replacementRequired ? false : true}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                      <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">{masterTransactionType === "transaction_leaver" ? "Leaver Details" : "HC Details"}</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails className="HcDetailsAccordionDetails">
                      {masterTransactionType === "hc_transaction" ?
                        // render HC Details Form
                        <HcForm
                          formHcData={Object.keys(data).length ? data : localStorage.getItem('HC') ? JSON.parse(localStorage.getItem('HC')) : data}
                          columns={columns}
                          dropDownData={dropDownData}
                          permissions={permissions}
                          setDataSource={setDataSource}
                          setDgData={setDgData}
                          dataSource={dataSource}
                          setHasDataChanged={setHasDataChanged}
                          apiCallBack={apiCallBack}
                          routeKey={routeKey}
                          isDraft={isDraft}
                          isCloned={isCloned}
                          isInternal={isInternal}
                          internalEmpData={internalEmpData}
                        />
                        : masterTransactionType === "transaction_leaver" ?
                          // render Leaver Details Form
                          <HcLeaverForm
                            formHcLeaverData={Object.keys(data).length ? data : localStorage.getItem('LR') ? JSON.parse(localStorage.getItem('LR')) : data}
                            columns={columns}
                            dropDownData={dropDownData}
                            permissions={permissions}
                            setDataSource={setDataSource}
                            setDgData={setDgData}
                            dataSource={dataSource}
                            setHasDataChanged={setHasDataChanged}
                            apiCallBack={apiCallBack}
                            routeKey={routeKey}
                            setIsReplacementRequired={setIsReplacementRequired}
                          />
                          : null}
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                : null
              }

              {
                masterTransactionType === "transaction_leaver" && data && data?.replacementRequired > 0
                  ?
                  data?.headcounts?.map((d, index) => (
                    <LeaverReplacementAccordion
                      key={index}
                      columns={isCloned ? data?.cols : data?.isDraft || data?.canInitiatorEditAfterApproval ? headcountRequest?.columns : data?.columns ?? []}
                      dropDownData={isCloned ? data?.dropDownData : data?.isDraft || data?.canInitiatorEditAfterApproval ? headcountRequest?.dropdownValues : data?.dropDownData ?? {}}
                      expandedKey={index}
                      handleDelete={handleDelete}
                      data={leaverReplacements?.length && leaverReplacements.filter(lr => lr.id === d.id)?.length ? leaverReplacements.filter(lr => lr.id === d.id)?.[0] : d}
                      permissions={permissions}
                      setDataSource={setDataSource}
                      setDgData={setDgData}
                      dataSource={dataSource}
                      setHasDataChanged={setHasDataChanged}
                      apiCallBack={apiCallBack}
                      routeKey={routeKey}
                      isReplacement={true}
                      isDraft={isDraft}
                      isCloned={isCloned}
                      leaverDetails={data}
                    />
                  ))
                  : null
              }
            </MDBox>
            : <MDBox px={2} py={2} >
              <MDTypography variant="subtitle3">
                No data exist in the system for this request. Please connect with the admin.
              </MDTypography>
            </MDBox>
          }
        </Card>
        : <MDBox mt={0} mb={0} style={{ textAlign: "center" }}>
          <Image
            title="Loading"
            alt="Loading"
            width={70}
            src={loadingGif}
            preview={false}
          />
        </MDBox>
      }
    </React.Fragment>
  );
}, (prevProps, nextProps) => {
  // Only re-render if the hasDataChanged state changes
  return prevProps.hasDataChanged === nextProps.hasDataChanged;
})

HcFormsView.displayName = "HcFormsView"

export default HcFormsView;
