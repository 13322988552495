import React, { useEffect, useRef, memo, useCallback } from "react";
import { Grid } from "@mui/material";
import { debounce } from "lodash";

const EmailSubject = ({ subject, setSubject, subjectChips }) => {
  const spanRef = useRef(null);

  const handleChipClick = useCallback((chip) => {
    if (spanRef.current) {
      const chipHtml = `<span class="chip__item" contenteditable="false">${chip}</span>&nbsp;`;
      const sel = window.getSelection();
      let range;

      if (sel.rangeCount) {
        let container = sel.getRangeAt(0).commonAncestorContainer;
        
        // Check if the container is actually the span or a child of it
        while (container && container.nodeType !== Node.ELEMENT_NODE) {
          container = container.parentNode;
        }
      
        if (container === spanRef.current) {
          range = sel.getRangeAt(0);
        } else {
          range = document.createRange();
          range.selectNodeContents(spanRef.current);
          range.collapse(false);
        }
      }

      const el = document.createElement("div");
      el.innerHTML = chipHtml;
      const frag = document.createDocumentFragment();
      let node, lastNode;
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);

      if (lastNode) {
        range.setStartAfter(lastNode);
        sel.removeAllRanges();
        sel.addRange(range);
      }

      setSubject(spanRef.current.innerHTML);
    }
  }, []);

  const handleInput = useCallback(
    debounce(() => {
      if (spanRef.current) {
        setSubject(spanRef.current.innerHTML);
      }
    }, 300),
    [setSubject]
  );

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerHTML = subject;
    }
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <div className="chip__list">
          {subjectChips
            .map((chip, index) => (
              <div onClick={() => handleChipClick(chip)} className="chip__item" key={index}>
                {chip}
              </div>
            ))}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="email-subject">
          <div className="email-subject__label">
            <label>Subject/Title:</label>
          </div>
          <span
            className="email-subject__input"
            contentEditable
            placeholder="Enter Subject here"
            ref={spanRef}
            onInput={handleInput}
          ></span>
        </div>
      </Grid>
    </>
  );
};

export default memo(EmailSubject);
