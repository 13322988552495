import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyAhPQq7AUd2xT1D5fElm6yDwBmi-Expr48",
    authDomain: "teos-7d534.firebaseapp.com",
    projectId: "teos-7d534",
    storageBucket: "teos-7d534.appspot.com",
    messagingSenderId: "170086210173",
    appId: "1:170086210173:web:bfd752201314a99de62f14",
    measurementId: "G-357VM1FHRE"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase