import { useContext } from "react";
import { SET_IS_LOADING, SET_CV_CATEGORIES } from "../context/store/Constants";
import { Context } from "../context/store/Store";
import {
  addKeyAndIndexFieldToGenericArrayOfObjects,
  getPageProperties,
  getTableColumns,
  isJSONValid
} from "../services/Helpers";
import request from "../services/Http";
import { useIsLoading } from "./useIsLoading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CATEGORIES_ENDPOINT = "cv-category";

export default function useManageCvCategories(routeKey) {
  const [{ cvCategories, pagesAndPermissions }, dispatch] = useContext(Context);
  const { isLoading, setIsLoading } = useIsLoading();

  async function getCvCategories() {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.get(CATEGORIES_ENDPOINT);
      const properties = getPageProperties(pagesAndPermissions, routeKey);
      const table = getTableColumns(properties.page.table, "manage-categories");
      const columns = table.columns;
      const itemsTable = getTableColumns(properties.page.table, "manage-items");
      const itemsColumns = itemsTable.columns;
      const lightsTable = getTableColumns(properties.page.table, "manage-lights");
      const lightsColumns = lightsTable.columns;
      let rows = isJSONValid(res.data.data) ?? {};
      let dropdownValues = isJSONValid(res.data.dropdownValues) ?? {};
      let uploadTemplateLink = res.data.uploadTemplate ?? null
      let orgStructureLink = res.data.orgStructureLink ?? null
      let tableName = res.data.tableName ?? null

      if (cvCategories && cvCategories.hasOwnProperty("apiDelete") && cvCategories.apiDelete) {
        rows = [...cvCategories.rows, ...rows];
      }

      const dgData = {
        columns,
        itemsColumns,
        lightsColumns,
        rows: addKeyAndIndexFieldToGenericArrayOfObjects(rows),
        dropdownValues,
        apiDelete: false,
        uploadTemplateLink, orgStructureLink, tableName
      };
      dispatch({
        type: SET_CV_CATEGORIES,
        payload: dgData,
      });
    } catch (e) { }

    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function updateOrCreateCvCategory(data) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const categories = data.map((e, i) => ({ ...e, sequence: i + 1 }));
      const res = await request.post(CATEGORIES_ENDPOINT, { categories });
      if (res) {
        toast.success(res.data.data);
        await getCvCategories();
      }
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  async function deleteCVCategory(data, masterCvCategoryId, deleteType) {
    setIsLoading(true);
    dispatch({ type: SET_IS_LOADING, payload: true });
    try {
      const res = await request.delete('cv-item', {
        data: { data, masterCvCategoryId, deleteType },
      });
      if (res) {
        toast.success(res.data.data);
        await getCvCategories();
      }
    } catch (e) { }
    setIsLoading(false);
    dispatch({ type: SET_IS_LOADING, payload: false });
  }

  return { getCvCategories, deleteCVCategory, updateOrCreateCvCategory, isLoading }
}