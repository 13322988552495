import { useContext, useState } from "react";
import { Context } from "../context/store/Store";
import request from "../services/Http";
import { getPageProperties, getTableColumns } from "../services/Helpers";
import { useIsLoading } from "./useIsLoading";
import { toast } from "react-toastify";

export default function useModule(routeKey) {
  const [{ pagesAndPermissions }, dispatch] = useContext(Context)
  const { isLoading, setIsLoading } = useIsLoading()
  const [masterModules, setMasterModules] = useState({})

  async function getModules() {
    setIsLoading(true)
    try {
      const res = await request.get('module')
      let rows = res.data.data
      const dropdownData = res.data.dropdownData
      const properties = getPageProperties(pagesAndPermissions, routeKey)
      const table = getTableColumns(properties.page.table, routeKey)
      let columns = table.columns
      setMasterModules({ rows, columns, dropdownData })
      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
    }
  }

  async function updateOrCreateModules(master_module) {
    setIsLoading(true)
    try {
      const res = await request.post('module', {master_module})
      if (res)
      {
        setIsLoading(false)
        toast.success(res.data.data)
        await getModules()
      }
    }
    catch (e) {
      setIsLoading(false)
    }
  }

  return { getModules, updateOrCreateModules,  setMasterModules, masterModules, isLoading }

}