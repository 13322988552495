import React, { useContext, useEffect, useState } from "react";
import usePagePermissions from "utils/hooks/usePagePermissions";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import { Context } from "utils/context/store/Store";
import useManageClientVital from "utils/hooks/useManageClientVital";
import { Grid } from "@mui/material";
import Select from "react-select";
import { selectCustomStyles, a11yProps } from "../../utils/services/Helpers";
import MDBox from "components/MDBox";
import TabPanel from "components/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import CustomSkelton from "components/Skelton/CustomSkelton";
import ManageClientVitalGrid from "./components/manage-cv-grid";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ViewClientVitalGrid from "./components/view-cv-grid";
import { v4 as uuidv4 } from "uuid";

function ManageClientVital({ routeKey, pageName }) {
  const { id, clientId, entityId, categoryId } = useParams();
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const [{ clientVital }] = useContext(Context);
  const { getClientVitals, deleteClientVital, updateOrCreateClientVital, isLoading } =
    useManageClientVital(routeKey);
  const [clients, setClients] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedVertical, setSelectedVertical] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [rows, setRows] = useState([]);
  const [viewCVRows, setViewCVRows] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const getClientVital = async () => {
    await getClientVitals();
  };

  const handleURLPathChanges = (key, newValue) => {
    // Get the current pathname
    let currentPath = location.pathname;

    // Split the pathname into its segments
    let pathSegments = currentPath.split("/");

    let entityIdIndex = pathSegments.indexOf(key);

    // If 'entityId' is in the path, update the value
    if (entityIdIndex !== -1) {
      pathSegments[entityIdIndex + 1] = newValue;
    }

    // Join the segments back into a URL
    let newUrl = pathSegments.join("/");

    // Navigate to the new URL
    navigate(newUrl);
  };

  const handleClientChange = (value) => {
    setSelectedClient(value);
    if (value?.value)
      handleURLPathChanges("client", value.value);
  };

  const handleEntityChange = (value) => {
    setSelectedVertical(value);
    if (value?.value)
      handleURLPathChanges("entityId", value.value);
  };

  const handleSubmit = async (data) => {
    // Edit Mode
    if (id && entityId && clientId) {
      let result = {};
      const updatedClientVitals = clientVital.data
        .filter((e) => {
          return (
            e.id === Number(id) &&
            e.masterClientId === Number(clientId) &&
            e.masterOrgEntityId === Number(entityId)
          );
        })
        .map((e) => {
          if (e.transaction_cv_items?.length) {
            return {
              ...e,
              items: [
                ...data.map((data) => ({
                  ...data,
                  transactionCvItemId: data.id,
                })),
              ],
            };
          }
          return {
            id: Number(id),
            items: [...data.map((e) => ({ ...e, transactionCvGroupId: Number(id) }))],
            masterClientId: Number(clientId),
            masterOrgEntityId: Number(entityId),
            rowEdited: true,
          };
        });
      if (!updatedClientVitals.length) {
        result = {
          items: [...data],
          masterClientId: selectedClient.id,
          masterOrgEntityId: Number(entityId),
          id: uuidv4(),
          newRow: true,
        };
      }
      if (Object.keys(result)?.length) updatedClientVitals.push(result);
      const existingClientVitals = clientVital.data
        .filter((e) => {
          return (
            e.id !== Number(id) ||
            e.masterClientId !== Number(clientId) ||
            e.masterOrgEntityId !== Number(entityId)
          );
        })
        .map((e) => ({ ...e, items: e.transaction_cv_items }));
      const clientVitalsData = [...updatedClientVitals, ...existingClientVitals];

      await updateOrCreateClientVital(clientVitalsData);
    } else {
      // Add mode
      // adding a new item into an existing group add transactionCvGroupId
      const isTransactionExist = clientVital.data.find(
        (e) =>
          e.masterOrgEntityId === selectedVertical.value && e.masterClientId === selectedClient.id
      );

      let result = {
        items: [...data],
        masterClientId: selectedClient.id,
        masterOrgEntityId: selectedVertical.value,
      };

      if (isTransactionExist) {
        const transactionItems = data.map((e) => {
          if (e.rowEdited) {
            return {
              ...e,
              transactionCvItemId: e.transactionCvGroupId ? e.id : null,
            };
          }
          return { ...e };
        });
        const editedNewRow = {
          id: isTransactionExist.id,
          rowEdited: true,
          items: [...transactionItems],
        };
        result = { ...result, ...editedNewRow };
      } else {
        result["newRow"] = true;
        result["id"] = uuidv4();
      }

      await updateOrCreateClientVital([result]);
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedCategory(categories.at(newValue));
    setTabValue(newValue);
  };

  useEffect(() => {
    getClientVital();
  }, []);

  useEffect(() => {
    if (clientVital && clientVital.dropdownValues) {
      const { masterClientId, masterOrgEntityId, masterCvCategoryId } = clientVital.dropdownValues;

      setClients(masterClientId);
      setCategories(masterCvCategoryId);
      setVerticals(masterOrgEntityId.map((e) => ({ label: e.label, value: e.id })));

      if (categoryId !== undefined) {
        const tabVal = parseInt(categoryId)
        setSelectedCategory(masterCvCategoryId[tabVal])
        setTabValue(tabVal)
      } else {
        setSelectedCategory(masterCvCategoryId[0])
        setTabValue(0);
      }
      setSelectedClient(masterClientId.find((e) => e.value === Number(clientId)));
      setSelectedVertical(masterOrgEntityId.find((e) => e.id === Number(entityId)));
    }
  }, [clientVital]);

  useEffect(() => {
    if (clientVital?.dropdownValues && entityId && clientId && selectedCategory && selectedClient) {
      const transactionView = clientVital.data.find(
        (e) =>
          e.id === Number(id) &&
          e.masterClientId === selectedClient.value &&
          e.masterOrgEntityId === Number(entityId)
      );
      const transactionViewItems = transactionView?.transaction_cv_items.filter(
        (e) => e.masterCvCategoryId === selectedCategory.id
      );
      if (transactionViewItems?.length) {
        //masterClientId
        const transactionIds = transactionViewItems
          .map((e) => e.transactionCvItemId)
          .filter((e) => e);

        const manageCvRows = transactionViewItems
          .filter((e) => !transactionIds.includes(e.id))
          .map((e) => ({
            ...e,
            masterCvLightId: e.masterCvLightId,
            name: selectedCategory.master_cv_items.find((item) => item.id === e.masterCvItemId)
              ?.name,
            sequence: selectedCategory.master_cv_items.find((item) => item.id === e.masterCvItemId)
              ?.sequence,
          }));

        setRows(manageCvRows?.sort((a, b) => a.sequence - b.sequence));

        const viewCvRows = transactionViewItems
          .filter((e) => transactionIds.includes(e.id))
          .map((e) => ({
            ...e,
            masterCvLightId: selectedCategory.master_cv_lights.find(
              (light) => light.id === e.masterCvLightId
            )?.name,
            color: selectedCategory.master_cv_lights.find((light) => light.id === e.masterCvLightId)
              ?.color,
            userName: e.createdByUser?.name,
            masterCvItemId: selectedCategory.master_cv_items.find(
              (item) => item.id === e.masterCvItemId
            )?.name,
            sequence: selectedCategory.master_cv_items.find(
              (item) => item.id === e.masterCvItemId)
              ?.sequence,
          }));
        setViewCVRows(viewCvRows?.sort((a, b) => a.sequence - b.sequence));
      } else {
        const addViewRows = selectedCategory.master_cv_items.map((e) => ({
          ...e,
          masterCvLightId: null,
          comments: "",
          masterCvItemId: e.id,
        }));
        setRows(addViewRows);
        setViewCVRows([]);
      }
    } else if (
      clientVital?.dropdownValues &&
      !id &&
      !entityId &&
      selectedVertical &&
      selectedCategory &&
      selectedClient
    ) {
      const transactionView = clientVital.data.find(
        (e) =>
          e.masterClientId === selectedClient.value &&
          e.masterOrgEntityId === selectedVertical.value
      );
      const transactionViewItems = transactionView?.transaction_cv_items.filter(
        (e) => e.masterCvCategoryId === selectedCategory.id
      );

      if (transactionViewItems?.length) {
        const transactionIds = transactionViewItems
          .map((e) => e.transactionCvItemId)
          .filter((e) => e);
        const manageCvRows = transactionViewItems
          .filter((e) => !transactionIds.includes(e.id))
          .map((e) => ({
            ...e,
            masterCvLightId: e.masterCvLightId,
            name: selectedCategory.master_cv_items.find((item) => item.id === e.masterCvItemId)
              ?.name,
            sequence: selectedCategory.master_cv_items.find((item) => item.id === e.masterCvItemId)
              ?.sequence,
          }));
        setRows(manageCvRows?.sort((a, b) => a.sequence - b.sequence));

        const viewCvRows = transactionViewItems
          .filter((e) => transactionIds.includes(e.id))
          .map((e) => ({
            ...e,
            masterCvLightId: selectedCategory.master_cv_lights.find(
              (light) => light.id === e.masterCvLightId
            )?.name,
            color: selectedCategory.master_cv_lights.find((light) => light.id === e.masterCvLightId)
              ?.color,
            userName: e.createdByUser?.name,
            masterCvItemId: selectedCategory.master_cv_items.find(
              (item) => item.id === e.masterCvItemId
            )?.name,
            sequence: selectedCategory.master_cv_items.find(
              (item) => item.id === e.masterCvItemId)
              ?.sequence,
          }));
        setViewCVRows(viewCvRows?.sort((a, b) => a.sequence - b.sequence));

      } else {
        const addViewRows = selectedCategory.master_cv_items.map((e) => ({
          ...e,
          masterCvLightId: null,
          comments: "",
          masterCvItemId: e.id,
        }));
        setRows(addViewRows);
        setViewCVRows([]);
      }
    }
  }, [
    selectedClient,
    id,
    selectedVertical,
    selectedCategory,
    entityId,
    clientId,
    clientVital,
    tabValue,
  ]);

  return (
    <BaseLayOut pageTitle={pageName || "Manage Client Vital"}>
      <Grid container alignItems="center" spacing={3} mb={5}>
        <Grid xs={6} sm={2} item>
          <Select
            isClearable={true}
            placeholder="Select Client"
            options={clients}
            value={selectedClient}
            styles={selectCustomStyles}
            isSearchable
            menuPortalTarget={document.body}
            onChange={(value) => handleClientChange(value)}
          />
        </Grid>
        <Grid xs={6} sm={2} item>
          <Select
            isClearable={true}
            placeholder="Select Vertical/Practice"
            options={verticals}
            value={selectedVertical}
            styles={selectCustomStyles}
            isSearchable
            menuPortalTarget={document.body}
            onChange={(value) => handleEntityChange(value)}
          />
        </Grid>
      </Grid>
      {selectedClient && selectedVertical && (
        <MDBox my={3}>
          <Card>
            <MDBox pb={3}>
              <MDBox sx={{ width: "100%" }}>
                <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <CustomSkelton>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
                      {categories.map((obj, index) => {
                        return (
                          <Tab
                            key={`${obj.name}-${index}`}
                            label={obj.name}
                            {...a11yProps(index)}
                          />
                        );
                      })}
                    </Tabs>
                  </CustomSkelton>
                </MDBox>
                {categories.map((obj, index) => {
                  return (
                    <TabPanel value={tabValue} key={`${obj.name}-${index}`} index={index}>
                      <MDBox sx={{ borderBottom: 1, borderColor: "divider" }} pb={5} mb={5}>
                        <ManageClientVitalGrid
                          isLoading={isLoading}
                          categoryData={obj}
                          rows={rows}
                          colors={obj.master_cv_lights?.sort((a, b) => a.sequence - b.sequence)}
                          permissions={getPermissionsForPage(routeKey)}
                          columns={clientVital.columns}
                          allowAdding
                          dropDownData={clientVital.dropdownValues}
                          postData={handleSubmit}
                          handleDelete={deleteClientVital}
                        />
                      </MDBox>
                      {viewCVRows?.length > 0 && (
                        <MDBox>
                          <ViewClientVitalGrid
                            rows={viewCVRows}
                            columns={clientVital.viewClientVitalsColumns}
                            isLoading={isLoading}
                          />
                        </MDBox>
                      )}
                    </TabPanel>
                  );
                })}
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      )}
    </BaseLayOut>
  );
}

export default ManageClientVital;
