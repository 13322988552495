import React, { useState, useEffect } from 'react'
import ClientVitalsDataGrid from './ClientVitalsDataGrid';
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import "./manage-client-vitals.scss"
import { SelectBox } from 'devextreme-react';

const ClientVitalsView = ({ clientVitals, isLoading, permissions, tabValue }) => {
  // const [tabValue, setTabValue] = useState(clientVitals?.tabValue ?? 0)
  const [dropdownData, setDropdownData] = useState(null)
  const [dropdownValue, setDropdownValue] = useState(clientVitals?.dropdownValue ?? null)
  const [dataGridRows, setDataGridRows] = useState([])
  const [dataGridColumns, setDataGridColumns] = useState([])
  const [pageView, setPageView] = useState(localStorage.getItem('pageView') === "true" ?? false);

  useEffect(() => {
    // setDropdownValue(null)
    if (tabValue === 0) {
      // Client Tab
      if (clientVitals?.dropdownValues?.masterClientId) {
        let clientsData = clientVitals.dropdownValues.masterClientId.map(item => (
          {
            label: item.label,
            value: item.id
          }
        ))
        setDropdownData(clientsData)
      }
    }
    else {
      if (clientVitals?.dropdownValues?.masterDepartmentId) {
        let entitiesData = clientVitals.dropdownValues.masterDepartmentId.map(item => (
          {
            label: item.label,
            value: item.id
          }
        ))
        setDropdownData(entitiesData)
      }
    }
  }, [tabValue])

  useEffect(() => {
    if (dropdownValue) {
      if (clientVitals && clientVitals.columns && clientVitals.rows && clientVitals.dropdownValues) {
        // Client Tab
        let columnsResult = [],
          rowsResult = [],
          dataIndex = "masterClientId",
          selectedId = dropdownValue;

        if (tabValue === 1) dataIndex = "masterDepartmentId"

        // Update the columns for the datagrid
        columnsResult = clientVitals.columns.filter((column) => column.dataIndex !== dataIndex)
        setDataGridColumns(columnsResult)
        const dataCombination = clientVitals?.dropdownValues?.departmentClientsCombination.filter(d => d[dataIndex] === dropdownValue)
        // Update the rows for the datagrid
        clientVitals?.rows?.filter(cv => dataCombination.some(d => d.masterClientId === cv.masterClientId && d.masterDepartmentId === cv.masterDepartmentId))?.map((row) => {
          if (row[dataIndex] === selectedId) {
            const rowData = row
            const result = {
              id: rowData.id,
              masterDepartmentId: rowData.masterDepartmentId,
              masterClientId: rowData.masterClientId,
              department: rowData?.department ?? row?.masterDepartmentName ?? row?.master_department ?? row?.master_departments,
              master_client: rowData.master_client,
              status: rowData.status,
              createdAt: rowData.updatedAt
            }

            // Find the latest object based on the 'createdAt' property
            const latestObject = row.transaction_cv_items.filter(rt => !rt.hasOwnProperty('newRow') && rt.hasOwnProperty('transactionCvItemId') && rt?.transactionCvItemId !== null).sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateB - dateA;
            })

            // Filter the transaction cv items to add only the latest items
            const historyTransactionIds = row.transaction_cv_items
              .map((e) => e.transactionCvItemId)
              .filter((e) => e);

            const transactionCvItems = row.transaction_cv_items.filter((e) => !historyTransactionIds.includes(e.id))
            let historyTransactionCvItems = row?.transaction_cv_items?.filter((e) => !e.newRow)

            transactionCvItems.map((item) => {
              if (clientVitals.dropdownValues.masterCvCategoryId) {
                let category = clientVitals.dropdownValues.masterCvCategoryId.find(cat => cat.id === item.masterCvCategoryId);

                if (result[category.name] === undefined)
                  result[category.name] = []

                result[category.name].push({
                  id: rowData.id,
                  masterDepartmentId: rowData.masterDepartmentId,
                  masterClientId: rowData.masterClientId,
                  name: category.master_cv_items.find(catItem => catItem.id === item.masterCvItemId)?.name,
                  comment: item.comments,
                  lights: category.master_cv_lights,
                  activeLight: item.masterCvLightId,
                  createdBy: item.createdByUser,
                  createdAt: item.updatedAt,
                  sequence: category.master_cv_items.find((catItem) => catItem.id === item.masterCvItemId)?.sequence,
                  historyTransactionItems: item?.transactionHistories?.map((historyItem) => {
                    return {
                      name: category.master_cv_items.find(catItem => catItem.id === historyItem.masterCvItemId)?.name,
                      comment: historyItem.comments,
                      lights: category.master_cv_lights,
                      activeLight: historyItem.masterCvLightId,
                      createdBy: historyItem.createdByUser,
                      createdAt: historyItem.updatedAt,
                    }
                  }) ?? []
                  /*historyTransactionItems: historyTransactionCvItems.filter((history) => {
                    return history.masterCvLightId === item.masterCvLightId
                    return history.masterCvItemId === item.masterCvItemId
                  }).map((historyItem) => {
                    return {
                      name: category.master_cv_items.find(catItem => catItem.id === historyItem.masterCvItemId)?.name,
                      comment: historyItem.comments,
                      lights: category.master_cv_lights,
                      activeLight: historyItem.masterCvLightId,
                      createdBy: historyItem.createdByUser,
                      createdAt: historyItem.createdAt,
                    }
                  })*/
                })

                return result
              }
            })
            rowsResult.push(result)
          }
        })

        setDataGridRows(rowsResult)
      }
    }
    else {
      setDataGridColumns(null)
      setDataGridRows(null)
    }
  }, [dropdownValue])

  const handleChange = (newValue) => {
    setDropdownValue(null)
    setDataGridColumns(null)
    setDataGridRows(null)

    if (newValue === 1) {
      if (clientVitals?.dropdownValues?.masterClientId) {
        let clientsData = clientVitals.dropdownValues.masterClientId.map(item => (
          {
            label: item.label,
            value: item.id
          }
        ))
        setDropdownData(clientsData)
      }
    }
    else {
      if (clientVitals?.dropdownValues?.masterDepartmentId) {
        let entitiesData = clientVitals.dropdownValues.masterDepartmentId.map(item => (
          {
            label: item.label,
            value: item.id
          }
        ))
        setDropdownData(entitiesData)
      }
    }
  };

  return (
    <div>
      <MDBox>
        <Grid md={4}>
          {
            tabValue !== false
              ?
              <>
                {/* <MDTypography variant="body2" fontWeight="medium">
                  {`Select ${tabValue === 0 ? "Client" : "Entity"}`}
                </MDTypography>*/}

                <SelectBox
                  searchEnabled={true}
                  showClearButton={true}
                  placeholder={`Please select a ${tabValue === 0 ? "client" : "agency/practice"}...`}
                  value={dropdownValue}
                  onValueChanged={(e) => {
                    if (e.value !== null) {
                      setDropdownValue(e.value);
                    } else {
                      setDropdownValue(null);
                    }
                  }}
                  displayExpr="label"
                  valueExpr="value"
                  dataSource={dropdownData ?? []} />
              </>
              : null
          }
        </Grid>
      </MDBox> <br />
      <MDBox>
        {dataGridRows && dataGridColumns && dropdownValue ?
          <MDBox>

            <ClientVitalsDataGrid
              rows={dataGridRows}
              columns={dataGridColumns}
              isLoading={isLoading}
              dropdownValues={clientVitals && clientVitals.dropdownValues ? clientVitals.dropdownValues : []}
              permissions={permissions}
              dropdownValue={dropdownValue}
              tabValue={tabValue}
            />
          </MDBox>
          : null}
      </MDBox>
    </div>
  )
}

export default ClientVitalsView;
