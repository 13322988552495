import { INTERNAL_MOBILITY_ID } from './constants';
import { getLevels, isObjectEmpty } from 'utils/services/Helpers';
export const setHeadcountValue = (
  requestTypeList,
  selectedRequestType,
  internalEmpData,
  headcountRequest,
  dispatch
) => {
  let hcTypes = selectedRequestType === 1
    ? requestTypeList
      ?.find((e) => e.id === selectedRequestType)
      ?.master_headcount_types
    : requestTypeList
      ?.find((e) => e.id === selectedRequestType)
      ?.master_headcount_types?.filter((e) => e.status);

  if (internalEmpData || headcountRequest?.rows?.some((e) => e.internalHeadcountId)) {
    dispatch({ type: 'SET_HEADCOUNT_TYPE_LIST', payload: hcTypes });
    return;
  }

  hcTypes = hcTypes?.filter((e) => e.id !== INTERNAL_MOBILITY_ID);
  dispatch({ type: 'SET_HEADCOUNT_TYPE_LIST', payload: hcTypes });
};

/**
 * Filters, deduplicates, and sorts entities by division from the provided data.
 *
 * @param {Object} options - The options object containing filtering criteria.
 * @param {Object} dropDownData - The data to filter.
 * @returns {Array} The filtered, deduplicated, and sorted entities.
 */
export function getEntitiesByDivision(options, dropDownData) {
  if (!options?.data || !dropDownData?.masterOrgEntityId) {
    return [];
  }

  const { masterOrgDivisionId } = options.data;
  const entities = dropDownData.masterOrgEntityId;

  // Filter entities based on masterOrgDivisionId if provided, otherwise use all entities
  const filteredEntities =
    masterOrgDivisionId !== null && masterOrgDivisionId !== undefined
      ? entities.filter((entity) => entity.masterOrgDivisionId === masterOrgDivisionId)
      : entities;

  // Remove duplicate entities based on id
  const uniqueEntities = [...new Map(filteredEntities.map((item) => [item.id, item])).values()];

  // Sort alphabetically based on value property
  const sortedEntities = uniqueEntities.sort((a, b) =>
    a.value.toLowerCase().localeCompare(b.value.toLowerCase())
  );

  return sortedEntities;
}

/**
 * Filters, deduplicates, and sorts verticals by entity and division from the provided data.
 *
 * @param {Object} options - The options object containing filtering criteria.
 * @param {Object} dropDownData - The data to filter.
 * @returns {Array} The filtered, deduplicated, and sorted verticals.
 */
export function getVerticalsByEntityAndDivision(options, dropDownData) {
  if (!dropDownData?.masterOrgVerticalId) {
    return [];
  }

  const { masterOrgDivisionId, masterOrgEntityId, masterCountryId } = options?.data || {};

  let filterCallback;
  if (masterOrgDivisionId !== null && masterOrgEntityId !== null && masterCountryId !== null) {
    filterCallback = (obj) =>
      obj.masterCountryId === masterCountryId &&
      obj.masterOrgEntityId === masterOrgEntityId &&
      obj.masterOrgDivisionId === masterOrgDivisionId;
  } else if (
    masterOrgDivisionId !== null &&
    masterOrgEntityId === null &&
    masterCountryId !== null
  ) {
    filterCallback = (obj) =>
      obj.masterCountryId === masterCountryId && obj.masterOrgDivisionId === masterOrgDivisionId;
  } else if (masterOrgDivisionId !== null && masterOrgEntityId !== null) {
    filterCallback = (obj) =>
      obj.masterOrgEntityId === masterOrgEntityId &&
      obj.masterOrgDivisionId === masterOrgDivisionId;
  } else if (isObjectEmpty(options)) {
    return [
      ...new Map(
        dropDownData.masterOrgVerticalId.map((item) => [item.masterOrgVerticalId, item])
      ).values()
    ].sort(sortCallback);
  } else {
    filterCallback = (obj) => obj.masterOrgDivisionId === masterOrgDivisionId;
  }

  if (filterCallback) {
    const filteredVerticals = dropDownData.masterOrgVerticalId.filter(filterCallback);
    return getUniqueAndSortedArrayByKey(filteredVerticals, 'masterOrgVerticalId');
  }

  return [];
}

/**
 * Filters, deduplicates, and sorts functions by verticals from the provided data.
 *
 * @param {Object} options - The options object containing filtering criteria.
 * @param {Object} dropDownData - The data to filter.
 * @returns {Array} The filtered, deduplicated, and sorted functions.
 */
export function getFunctionsByVerticals(options, dropDownData) {

  if (!dropDownData?.masterOrgFunctionId) {
    return [];
  }

  const { masterOrgVerticalId, masterOrgDivisionId, masterOrgEntityId, masterCountryId } =
    options?.data || {};

  const filterCallback =
    masterOrgVerticalId !== undefined ||
      masterOrgDivisionId !== undefined ||
      masterOrgEntityId !== undefined ||
      masterCountryId !== undefined
      ? (cap) =>
        cap.masterOrgVerticalId === masterOrgVerticalId &&
        cap.masterOrgDivisionId === masterOrgDivisionId &&
        cap.masterOrgEntityId === masterOrgEntityId &&
        cap.masterCountryId === masterCountryId
      : null;

  const filteredCaps = filterCallback
    ? dropDownData.masterOrgFunctionId.filter(filterCallback)
    : dropDownData.masterOrgFunctionId;

  return getUniqueAndSortedArrayByKey(filteredCaps, 'masterOrgFunctionId');
}

/**
 * Filters, deduplicates, and sorts countries by division or entity from the provided data.
 *
 * @param {Object} options - The options object containing filtering criteria.
 * @param {Object} dropDownData - The data to filter.
 * @returns {Array} The filtered, deduplicated and sorted countries.
 */
export function getCountriesByDivisionOrEntity(options, dropDownData) {
  // Early return if necessary data is not provided
  if (!dropDownData?.masterCountryId) {
    return [];
  }

  const { masterOrgDivisionId, masterOrgEntityId } = options?.data || {};

  // Define filter callback function based on provided options
  const filterCallback = (obj) => {
    if (masterOrgDivisionId !== null && masterOrgEntityId !== null) {
      return (
        obj.masterOrgDivisionId === masterOrgDivisionId &&
        obj.masterOrgEntityId === masterOrgEntityId
      );
    }
    if (masterOrgDivisionId !== null) {
      return obj.masterOrgDivisionId === masterOrgDivisionId;
    }
    return true;
  };

  const filteredCountries = dropDownData.masterCountryId.filter(filterCallback);

  return getUniqueAndSortedArrayByKey(filteredCountries, 'id');
}

/**
 * Filters, deduplicates, and sorts level from the provided data.
 *
 * @param {Object} options - The options object containing filtering criteria.
 * @param {Object} dropDownData - The data to filter.
 * @returns {Array} The filtered, deduplicated, and sorted levels.
 */
export function getLevelsLocal(options, dropDownData) {
  // Early return if dropDownData is not provided or doesn't have the required property
  if (!dropDownData?.masterLevelId) {
    return [];
  }

  const { masterOrgDivisionId, masterOrgEntityId, masterCountryId, masterOrgVerticalId } =
    options?.data || {};
  const { masterLevelId, masterOrgVerticalId: vet, levels, LIds } = dropDownData;

  // If masterOrgDivisionId is provided, fetch levels using the getLevels function
  if (masterOrgDivisionId !== null) {
    return getLevels(
      masterLevelId,
      vet || [],
      masterOrgDivisionId,
      masterOrgEntityId,
      masterCountryId,
      masterOrgVerticalId,
      levels || [],
      LIds || []
    );
  }

  // If masterOrgDivisionId is not provided, get unique levels from masterLevelId
  const uniqueLevels = getUniqueAndSortedArrayByKey(masterLevelId, 'id');

  return uniqueLevels;
}
/**
 * Helper function to deduplicate and sort an array of objects based on a specific key.
 *
 * @param {Array} array - The array to process.
 * @param {string} key - The key to use for deduplication and sorting.
 * @returns {Array} The deduplicated and sorted array.
 */
function getUniqueAndSortedArrayByKey(array, key) {
  const uniqueArray = [...new Map(array.map((item) => [item[key], item])).values()];
  const sortedArray = uniqueArray.sort((a, b) =>
    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  );
  return sortedArray;
}
