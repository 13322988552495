import 'devextreme/dist/css/dx.light.css';
import {
  HtmlEditor,
  Toolbar,
  Item,
  TableContextMenu,
  Variables
} from 'devextreme-react/html-editor';
import React, { useCallback } from 'react';
import { debounce } from 'lodash';

function Editor({ value, setValue, variableValues }) {
  const debouncedSetValue = useCallback(debounce(setValue, 200), [setValue]);

  const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
  const fontValues = [
    'Arial',
    'Courier New',
    'Georgia',
    'Impact',
    'Lucida Console',
    'Tahoma',
    'Times New Roman',
    'Verdana'
  ];
  const escapeCharacters = ['', ''];
  return (
    <HtmlEditor
      defaultValue={value}
      onValueChange={debouncedSetValue}
      allowSoftLineBreak
      height="350px"
      mentions={[{ dataSource: variableValues || [] }]}
    >
      <Toolbar multiline={false}>
        <Item name="size" acceptedValues={sizeValues} />
        <Item name="font" acceptedValues={fontValues} />
        <Item name="separator" />
        <Item name="bold" />
        <Item name="italic" />
        <Item name="strike" />
        <Item name="underline" />
        <Item name="separator" />
        <Item name="alignLeft" />
        <Item name="alignCenter" />
        <Item name="alignRight" />
        <Item name="alignJustify" />
        <Item name="separator" />
        <Item name="orderedList" />
        <Item name="bulletList" />
        <Item name="separator" />
        <Item name="clear" />
        <Item name="separator" />
        <Item name="variable" />
        <Item name="separator" />
        <Item name="insertTable" />
        <Item name="deleteTable" />
        <Item name="insertRowAbove" />
        <Item name="insertRowBelow" />
        <Item name="deleteRow" />
        <Item name="insertColumnLeft" />
        <Item name="insertColumnRight" />
        <Item name="deleteColumn" />
      </Toolbar>
      <Variables dataSource={variableValues} escapeChar={escapeCharacters} />
      <TableContextMenu enabled />
    </HtmlEditor>
  );
}

export default Editor;
