import React from 'react'
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Select from "react-select";
import { selectCustomStyles } from "utils/services/Helpers";

export function CombinationTypeSelect({
  combinationsData,
  setCombinationVal,
  getCombinationsList,
  accordionID,
  combinationVal
}) {
  return (
   <>
    {combinationsData.length > 0 && (
      <MDBox pb={2}>
        <Grid xs={8} sm={4}>
          <Select
            placeholder="Please select a combination..."
            isRtl={false}
            styles={selectCustomStyles}
            onChange={setCombinationVal}
            value={combinationVal}
            options={getCombinationsList(accordionID)}
          />
        </Grid>
      </MDBox>
    )}
    </>
  )
}