import React, { Component } from 'react';
import { sendErrorToTeams, setGlobalErrorBoundaryRef } from "../../utils/services/ErrorHandler";
const ErrorComponent = () => {
  const handleReload = () => {
    window.location.reload(); // Reload the page
  };

  const handleGoBack = () => {
    window.history.back(); // Go back to the previous page
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.heading}>Oops! 🙊</h1>
        <p style={styles.message}>
          It seems like a glitch in the matrix. <br />
          Don't worry, we're fixing it! <br />
          In the meantime, please try the options below or feel free to <a
          href="https://apps.powerapps.com/play/e/default-d52c9ea1-7c21-47b1-82a3-33a74b1f74b8/a/d8cc0e5c-2b2e-409a-a1cb-c2459d63f91c?tenantId=d52c9ea1-7c21-47b1-82a3-33a74b1f74b8" target={"_blank"}>contact</a> for assistance.
        </p>
        <div style={styles.errorIcon}></div>
        <div style={styles.buttonContainer}>
          <button style={styles.button} onClick={handleReload}>Reload</button>
          {/*<button style={styles.button} onClick={handleGoBack}>Go Back</button>*/}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#fff', // White background color
    color: '#333', // Dark text color
    fontFamily: 'Arial, sans-serif',
  },
  content: {
    textAlign: 'center',
  },
  heading: {
    fontSize: '4rem',
    marginBottom: '10px',
  },
  message: {
    fontSize: '1.5rem',
    marginBottom: '30px',
  },
  errorIcon: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: '#333', // Dark background color
    backgroundImage: 'url("https://cdn-icons-png.flaticon.com/512/564/564619.png")',
    backgroundSize: 'cover',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    margin: '0 auto',
  },
  buttonContainer: {
    marginTop: '30px',
  },
  button: {
    padding: '10px 20px',
    margin: '0 10px',
    fontSize: '1.5rem',
    backgroundColor: '#333', // Dark background color
    color: '#fff', // White text color
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: '#444', // Slightly darker background color on hover
  },
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
    this.handleError = this.handleError.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidMount() {
    // Set the global error boundary reference after the component mounts
    setGlobalErrorBoundaryRef(this);
  }

  componentWillUnmount() {
    // Clear the reference when the component unmounts to prevent memory leaks
    setGlobalErrorBoundaryRef(null);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    console.error("Error caught by ErrorBoundary: ", error.message, error.stack, errorInfo);
    // Extracting the component name from the error stack trace
    const componentNameRegex = /at ([a-zA-Z]+)\./;
    const componentNameMatch = errorInfo.componentStack.match(componentNameRegex);
    const componentName = componentNameMatch ? componentNameMatch[1] : 'UnknownComponent';
    this.setState({ componentName });
    /*const fn = async () => await sendErrorToTeams({error: error?.toString(), errorInfo})
    fn()*/
  }

  handleError(error) {
    this.setState({
      hasError: true,
      error,
      errorInfo: { componentStack: 'Global Error' }
    });

    console.error("Global error handled: ", error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent/>
      return (
        <div>
          <h1>Something went wrong {this.state.componentName}.</h1>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
