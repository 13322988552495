import { useMemo, useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useHeadcountManagement } from './hooks/useHeadcountManagement';
import usePagePermissions from 'utils/hooks/usePagePermissions';
import { LEAVER_HEADCOUNT_ID } from './utils/constants';
import './manage-headcount.scss';
import BaseLayOut from 'components/Layout/BaseLayOut';
import HcDataGrid from './components/HcDataGrid';
import HcLeaverDataGrid from './components/HcLeaverDataGrid';
import ToggleGroupItems from './components/ToggleGroupItems';
import { isObjectEmpty } from 'utils/services/Helpers';
import MDTypography from 'components/MDTypography';
import { SET_HC_REQUEST } from "../../utils/context/store/Constants";
import { Context } from "../../utils/context/store/Store";

export default function ManageHeadCount({ routeKey, pageName, masterPanel = false }) {
  const { id, requestId, headcountTypeId, notificationId } = useParams();
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const [dgData, setDgData] = useState([]);
  const [{ hcRequestType }, dispatch] = useContext(Context);

  useEffect(() => {
    localStorage.removeItem("resize");
    localStorage.removeItem("LR");
    localStorage.removeItem("HC");
    return () => {
      localStorage.removeItem("resize");
      localStorage.removeItem("LR");
      localStorage.removeItem("HC");
      dispatch({
        type: SET_HC_REQUEST,
        payload: {}
      })
    }
  }, []);
  useEffect(() => { }, [dgData]);

  const {
    isLoading,
    selectedRequestType,
    selectedHeadcountType,
    requestTypeList,
    headcountTypeList,
    isRequestTypeDisabled,
    isHeadcountTypeDisabled,
    onRequestTypeChange,
    onHeadcountChange,
    headcountRequest,
    clonedHeadcounts,
    internalEmpData,
    getHeadcountRequest,
    hcLeaver,
    getHcLeaverDetails
  } = useHeadcountManagement(routeKey, id, requestId, headcountTypeId, notificationId);

  const pageTitle = pageName === 'manage-hc' ? 'manage-headcount' : 'Manage Headcount';

  const allowAddingCondition = useMemo(() => {
    if (headcountRequest?.isDraft) return true;
    if (!isObjectEmpty(clonedHeadcounts)) return true;
    if (
      internalEmpData &&
      typeof internalEmpData === 'object' &&
      Object.keys(internalEmpData).length &&
      !requestId
    )
      return false;
    return !requestId;
  }, [headcountRequest, clonedHeadcounts, internalEmpData, requestId]);

  const showButtonCondition = useMemo(() => {
    return (
      (headcountRequest &&
        (!headcountRequest.canAction || headcountRequest.showButtonToCreatorAfterApproval)) ||
      selectedHeadcountType === 4
    );
  }, [headcountRequest, selectedHeadcountType]);

  const tableRows = useMemo(() => {
    if (!isObjectEmpty(clonedHeadcounts)) {
      return clonedHeadcounts.rows;
    }

    if (headcountRequest?.rows) {
      return headcountRequest.rows;
    }

    return [];
  }, [clonedHeadcounts, headcountRequest]);

  const leaverTableRows = useMemo(() => {
    if (!isObjectEmpty(clonedHeadcounts)) {
      return clonedHeadcounts.rows;
    }

    if (hcLeaver?.rows) {
      return hcLeaver.rows;
    }

    return [];
  }, [clonedHeadcounts, hcLeaver]);

  useEffect(() => { }, [requestTypeList])

  return (
    <BaseLayOut pageTitle={pageTitle}>
      <div className="manage-headcount">
        <div className="manage-headcount__toggle-group-container">
          <ToggleGroupItems
            title={'Request Type'}
            items={requestTypeList}
            checkedId={selectedRequestType}
            disabled={Boolean(isRequestTypeDisabled) || (dgData && dgData.length)}
            onChange={onRequestTypeChange}
          />
          <ToggleGroupItems
            title={'Headcount Type'}
            items={headcountTypeList}
            disabled={Boolean(isHeadcountTypeDisabled) || (dgData && dgData.length)}
            checkedId={selectedHeadcountType}
            onChange={onHeadcountChange}
          />
        </div>

        {internalEmpData ? (
          <>
            <MDTypography variant="h6" fontWeight="medium">
              Internal Mobility Headcount
            </MDTypography>
            <HcDataGrid
              columns={headcountRequest && headcountRequest.columns ? headcountRequest.columns : []}
              rows={internalEmpData?.orgHc ?? null}
              dropDownData={headcountRequest?.dropdownValues || {}}
              isLoading={isLoading}
              disableSubmitButton
              allowUpdating={false}
              showActionColumn={false}
              allowAdding={false}
              showButton
              allowSelection={false}
              allowDeleting={false}
              isForInternalMobility={true}
            />
          </>
        ) : null}
        {selectedRequestType && selectedRequestType !== LEAVER_HEADCOUNT_ID && selectedHeadcountType ? (
          <HcDataGrid
            globalState={headcountRequest ?? {}}
            routeKey={routeKey}
            orgColumns={headcountRequest?.orgColumns}
            columns={headcountRequest?.columns || []}
            isDraft={headcountRequest?.isDraft}
            groupId={id}
            permissions={getPermissionsForPage(routeKey)}
            rows={tableRows}
            dropDownData={headcountRequest?.dropdownValues || {}}
            isLoading={isLoading}
            masterRequestTypeId={selectedRequestType}
            masterHeadcountTypeId={selectedHeadcountType}
            allowUpdating={true}
            internalEmpData={internalEmpData}
            allowAdding={allowAddingCondition}
            showButton={showButtonCondition}
            allowSelection={headcountRequest?.canAction}
            appFields={headcountRequest?.appFields || []}
            isInternal={!!internalEmpData}
            allowDeleting={headcountRequest && !headcountRequest.canAction}
            apiCallBack={async () => await getHeadcountRequest(false, id)}
            isCloned={!isObjectEmpty(clonedHeadcounts)}
            setDgData={setDgData}
          />
        ) : null}

        {!masterPanel && selectedRequestType === LEAVER_HEADCOUNT_ID ? (
          <HcLeaverDataGrid
            columns={hcLeaver?.columns || []}
            rows={leaverTableRows}
            dropDownData={hcLeaver?.dropdownValues || {}}
            isLoading={isLoading}
            masterRequestTypeId={selectedRequestType}
            routeKey={routeKey}
            permissions={getPermissionsForPage(routeKey)}
            allowAdding={hcLeaver?.isDraft ? true : !id}
            showButton={!id}
            allowSelection={!!id}
            apiCallBack={async () => await getHcLeaverDetails(id)}
            isCloned={!isObjectEmpty(clonedHeadcounts)}
            isDraft={hcLeaver?.isDraft}
            groupId={id}
            setDgData={setDgData}
          />
        ) : null}
      </div>
    </BaseLayOut>
  );
}
