import React, { useState, useEffect, useMemo } from 'react'
import useNavListing from 'utils/hooks/useNavListing';
import DataGrid, { AsyncRule, Column, Button, Export, Paging, Pager, Scrolling, Selection, RequiredRule, SearchPanel, HeaderFilter, Editing } from "devextreme-react/data-grid";
import { isObjectEmpty, isJSONValid, selectCustomStyles } from "utils/services/Helpers";
import Select from "react-select";
import MDAlert from "components/MDAlert";
import { DEButton, createSanitizeAsyncRule } from "../../../utils/services/Helpers";
import DetectNavigationBlocker from "components/navigationdetector/DetectNavigationBlocker";
import CustomSkelton from "components/Skelton/CustomSkelton";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Swal from "sweetalert2";
import "devextreme/dist/css/dx.light.css";
const _ = require('lodash')

export default function PagePropertiesDataGrid({ data, pagePropertyColumns, routeKey = "page-properties", updateIsDataChangedOfParent = null, getNavListingOfParent, permissions, allowAdding = true, allowSelection = true, allowDeletingFromApi = true }) {

  const [dataSource, setDataSource] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const { getNavListing, updatePageProperty, isLoading } = useNavListing(routeKey)
  const [pageTitle, setPageTitle] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  let pageId = data.data && data.data.id ? data.data.id : 0;
  let pageProperties = data.data && data.data.properties && !isObjectEmpty(data.data.properties) ? isJSONValid(data.data.properties) : [];
  let pagesDropdown = []
  const sanitizeAsyncRule = createSanitizeAsyncRule("Invalid characters detected. Please remove any special characters.");

  useEffect(() => {
    if (pagePropertyColumns)
      setDataColumns(pagePropertyColumns)

    // cleanup on unmount
    return () => {
      setDataSource([])
      setDataColumns([])
    }
  }, []);

  // initialize new row in the data-grid
  const onInitNewRow = (e) => {
    e.data.newRow = true
    setIsDataChanged(false)
  }

  // save new row in the data-grid
  const onSave = (e) => {
    if (e && e.changes.length) {
      if (e.changes[0].type === "remove") {
        const dataSourceArray = [...dataSource]
        const deleteItemId = e.changes[0].key
        if (deleteItemId) {
          const deleteFromTable = dataSourceArray.length ? dataSourceArray.filter(data => data.id !== deleteItemId) : []
          setDataSource(deleteFromTable)
        }
      }
      else {
        const updatedData = e.changes[0].data;
        let finalResult = []
        if (dataSource && dataSource.length) {
          finalResult = _.unionBy(updatedData, dataSource);
        }
        else { finalResult.push(updatedData) }
        setDataSource(finalResult)
        setIsDataChanged(true)
      }
    }
  }

  // prepare data-grid toolbar 
  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "save",
          text: "SUBMIT",
          disabled: !isDataChanged,
          onClick: async () => {
            // handle API create and update of data-grid
            let newData = [...dataSource];
            pageProperties.page.table.map((table) => {
              if (table.name === pageTitle) {
                // replace page properties new values of the selected page
                table.columns = newData
              }
              // remove unused data from the object
              table.columns.map((column) => {
                delete column.id
                delete column.key
                delete column.name
                delete column.sequence
              })
            })
            await updatePageProperty(pageProperties, pageId)
            await getNavListingOfParent()
            setIsDataChanged(false)
          },
        }
      }
    );
  }

  // handle API delete of data-grid
  const manageDelete = () => {
    const msg = "You won't be able to revert this!"
    Swal.fire({
      title: 'Are you sure?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, delete it!",
      heightAuto: false,
      height: '200px'
    }).then(async (res) => {
      let dataSourceArray = [...dataSource]
      if (res.isConfirmed) {
        const deleteFromApi = selectedRowKeys.filter(a => a.hasOwnProperty('id') && !a.hasOwnProperty('newRow'))
        const deleteFromTable = selectedRowKeys.filter(a => a.hasOwnProperty('newRow'))

        if (deleteFromApi && deleteFromApi.length) {
          const deleteResult = deleteFromApi.map(a => a.id);
          await handleDelete(deleteResult)
        }
        else if (deleteFromTable && deleteFromTable.length) {
          deleteFromTable.map(a => {
            dataSourceArray = dataSourceArray.filter((item) => item.id !== a.id);
          })
          setDataSource(dataSourceArray)
        }
        setIsDataChanged(false)
        setSelectedRowKeys([]);
      }
    })
  }

  // get selected rows detail of data-grid
  const onSelectionChanged = ({ selectedRowsData }) => {
    setSelectedRowKeys(selectedRowsData)
  }

  // handle rendering of data-grid fields
  const renderField = (col) => {
    if (col.type === "actions") {
      return <Column key={col.dataIndex} allowEditing={col.editable} visible={col.is_visible} allowSearch={col.is_searchable} allowSorting={col.is_sortable}
        type="buttons" dataField={col.dataIndex} caption={col.title} fixed={false} width={"auto"}>
        <Button name="delete" icon={'trash'} visible={(e) => e && e.row && e.row.data && e.row.data.newRow === true && permissions && permissions.canDelete && allowDeletingFromApi} />
      </Column>
    }
    else if (col.type === "checkbox" || col.type === "toggle") {
      return <Column dataType="boolean" key={col.dataIndex}
        showEditorAlways={true} allowEditing={col.editable} visible={col.is_visible}
        allowSearch={col.is_searchable} allowSorting={col.is_sortable} dataField={col.dataIndex}
        caption={col.title} alignment={"center"} setCellValue={function (rowData, value) {
          this.defaultSetCellValue(rowData, value)
        }}>
      </Column>
    }
    else if (col.dataIndex === "string") {
      return <Column allowEditing={col.editable} key={col.dataIndex} dataField={col.dataIndex} caption={col.title} encodeHtml={col.hasOwnProperty('encodeHtml') ? col.encodeHtml : true} alignment={"left"}>
        {
          col.required ? <RequiredRule /> : null
        }
        <AsyncRule {...sanitizeAsyncRule} />
      </Column>
    }
    else if (col.dataIndex !== "id") {
      return <Column allowEditing={col.editable} key={col.dataIndex} dataField={col.dataIndex} caption={col.title} encodeHtml={col.hasOwnProperty('encodeHtml') ? col.encodeHtml : true} alignment={"left"}>
        {
          col.required ? <RequiredRule /> : null
        }
      </Column>
    }
    else {
      return null
    }
  }

  if (pageProperties && pageProperties.page && pageProperties.page.table && pageProperties.page.table.length) {
    pageProperties.page.table.map((obj, index) => {
      const rows = obj.columns.map((col, index) => {
        return { ...col, id: index + 1, key: index + 1, sequence: index + 1, name: obj.name };
      })

      pagesDropdown.push({
        "id": index + 1,
        "name": obj.name,
        "value": JSON.stringify(rows.filter(row => row.name === obj.name)),
        "label": obj.name.replace(/-/g, " ")
      })
    })
  }

  const Comp = useMemo(() => {
    try {
      return <div id="hc-type-data-grid">
        {
          pagesDropdown.length ?
            <Card>
              <MDBox pt={2} pl={3} pr={3} pb={2}>
                <MDTypography variant="h6" fontWeight="medium">Page Properties</MDTypography>
              </MDBox>
              <MDBox pt={2} pl={3} pr={3} pb={2}>
                <Grid item xs={6} sm={3}>
                  <Select placeholder="Select page..." options={pagesDropdown} value={selectValue} styles={selectCustomStyles} isSearchable menuPortalTarget={document.body}
                    onChange={(val) => {
                      setSelectValue(val)
                      setPageTitle(val.name)
                      setDataSource(JSON.parse(val.value))
                    }} />
                </Grid>
              </MDBox>
              {
                pageTitle ?
                  <>
                    <br />
                    <Divider color="dark" flexItem />
                    <MDBox pt={0} pl={3} pr={3} pb={0}>
                      <MDBox pt={0} pl={0} pr={0} pb={0}>
                        <MDTypography variant="h6" fontWeight="medium">Manage {pageTitle}</MDTypography>
                      </MDBox>

                      <DataGrid
                        allowColumnReordering={true}
                        onInitNewRow={onInitNewRow}
                        onSaved={onSave}
                        onSelectionChanged={onSelectionChanged}
                        onToolbarPreparing={onToolbarPreparing}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        allowColumnResizing={true}
                        dataSource={dataSource}
                        keyExpr="id"
                        disabled={isLoading}>
                        <HeaderFilter visible={true} disabled={false}
                          allowSearch={true} />
                        <SearchPanel visible={true} />
                        <Paging defaultPageSize={25} />
                        <Editing
                          mode="cell"
                          newRowPosition={"first"}
                          refreshMode={"repaint"}
                          allowAdding={permissions && permissions.canCreate && allowAdding}
                          allowUpdating={permissions && permissions.canCreate}
                          allowDeleting={permissions && permissions.canDelete && allowDeletingFromApi} />
                        <Pager visible={true} showNavigationButtons={true} showInfo={true} displayMode={"full"} />
                        <Scrolling showScrollbar="always" mode="standard" />
                        {
                          dataColumns && dataColumns.length ? dataColumns.map((col) => { return renderField(col); }) : null
                        }
                        {
                          allowSelection ?
                            <Selection allowSelectAll={true} mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
                            : null
                        }
                        <Export enabled={true} allowExportSelectedData={true} />
                      </DataGrid>
                    </MDBox>
                  </>
                  : null
              }
            </Card>
            : <MDBox pt={0} pl={3} pr={3} pb={0}>
              <MDTypography variant="subtitle2" fontWeight="regular">
                There are no page properties for this navigation.
              </MDTypography>
            </MDBox>
        }
      </div>
    }
    catch (e) {
    }
  }, [dataSource, pageTitle, pageProperties, selectedRowKeys, isDataChanged, isLoading, permissions, allowSelection]);

  return (
    <React.Fragment>
      <DetectNavigationBlocker setIsDataChanged={setIsDataChanged} isDataChanged={isDataChanged} />
      <CustomSkelton>
        {Comp}
      </CustomSkelton>
    </React.Fragment>
  );
}