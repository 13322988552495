import { useEffect, useState } from "react";
import { ContactInfoModal } from './components/contact-modal/contact-modal';
import { Filters } from './components/filters/filters';
import { OrgChart } from './components/org-chart/org-chart';
import { TreeChart } from './components/tree-chart/tree-chart';
import { CapabilityProvider } from './contexts/capability-context';
import './capabilities.scss';
import useEntityVerticalCapability from "../../utils/hooks/useEntityVerticalCapability";

const CapabilitiesView = () => {
  const [selectedView, setSelectedView] = useState('Tree View');
  const {getGraphsData} = useEntityVerticalCapability()
  useEffect(() => {
    const fn = async () => await getGraphsData()
    fn()
    return () => {
      localStorage.removeItem('dataNodes')
    }
  }, []);
  return (
    <CapabilityProvider>
      <div className="chart-card">
        <div className="chart-card__container">
          <Filters selectedView={setSelectedView} />
          {selectedView === 'Tree View' ? <TreeChart /> : <OrgChart />}
          <ContactInfoModal />
        </div>
      </div>
    </CapabilityProvider>
  );
};

export default function Capabilities({ routeKey, pageName }) {
  return (
    <CapabilitiesView />
  );
}
