import React, { useContext, useEffect, useState } from "react";
import 'antd/dist/antd.css';
import useUserRoles from "../../../utils/hooks/useUserRoles";
import { Context } from "../../../utils/context/store/Store";
import { SET_USER_FUNCTIONAL_ACCESS } from "utils/context/store/Constants";
import MDBox from "../../MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../MDTypography";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import { selectCustomStyles, DEButton } from "../../../utils/services/Helpers";
import CustomSkelton from "../../Skelton/CustomSkelton";

const UserFunctionalAccess = ({ masterModuleId, userId, permissions }) => {

  const { fetchUserFunctionalAccess, createOrUpdateUserFunctionalAccess, deleteUserFunctionalAccess, isLoading } = useUserRoles(userId, masterModuleId)
  const [{ userFunctionalAccess }, dispatch] = useContext(Context)
  const [currentRole, setCurrentRole] = useState([])


  useEffect(async () => {
    dispatch({ type: SET_USER_FUNCTIONAL_ACCESS, payload: {} })
    await fetchUserFunctionalAccess()
  }, [])

  useEffect(() => {
    setCurrentRole(userFunctionalAccess.rows ?? null)
  }, [userFunctionalAccess])

  useEffect(() => { }, [currentRole]);



  async function handleSubmit(e) {
    e.preventDefault()
    await createOrUpdateUserFunctionalAccess(currentRole)
  }

  function getOptions(records) {
    return records && records.length > 0 ?
      [{ label: "Select All", value: "all" }, ...records] : []
  }

  return (
    <CustomSkelton is_loading={isLoading}>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <MDBox py={3}>
            <Card>
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="h5" fontWeight="medium">User Functional Access</MDTypography>
                  <MDBox mt={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Select
                          placeholder="Please Select functional Access..."
                          isMulti={true}
                          isClearable={true}
                          isRtl={false}
                          styles={selectCustomStyles}
                          onChange={(val) => {
                            if (val.length && val.find(option => option.value === "all")) {
                              if (userFunctionalAccess && userFunctionalAccess.dropDownData && userFunctionalAccess.dropDownData?.functionalAccess?.length > 0) {
                                const r = [...getOptions(userFunctionalAccess.dropDownData?.functionalAccess).slice(1), ...userFunctionalAccess.dropDownData?.functionalAccess]
                                const fr = [...new Map(r?.map(item => [item['id'], item])).values()]
                                setCurrentRole(fr)
                                return fr
                              }
                            }
                            setCurrentRole(val)
                            return val
                          }}
                          value={currentRole}
                          options={userFunctionalAccess && userFunctionalAccess.dropDownData ? getOptions(userFunctionalAccess.dropDownData?.functionalAccess) : []}
                          className={"input-error"} />
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox display="flex" justifyContent="right" alignItems="center" position="relative" zIndex={1} >
                    {
                      permissions && permissions.canCreate
                        ?
                        <>
                          <DEButton stylingMode="contained" type="success" disabled={isLoading} useSubmitBehavior={true} text="Submit" />
                        </>

                        : null
                    }
                  </MDBox>
                </MDBox>
              </Card>
            </Card>
          </MDBox>
        </form>
      </div>
    </CustomSkelton>
  );
};

export default UserFunctionalAccess;