import BaseLayOut from "../../components/Layout/BaseLayOut";
import CustomSkelton from "../../components/Skelton/CustomSkelton";
import useEmailDeliveryStatusHub from "../../utils/hooks/useEmailDeliveryStatusHub";
import { useEffect } from "react";
import EmailDeliveryStatusHubDataGrid from "./datagrid/EmailDeliveyStatusHubDataGrid";

const EmailDeliveryStatusHub = ({pageName, routeKey}) => {
  const {isLoading, getAllEmailDeliveryStatusHub, stats} = useEmailDeliveryStatusHub(routeKey)
  useEffect(() => {
    const fn = async () => await getAllEmailDeliveryStatusHub()
    fn()
  },[])

  useEffect(() => {},[stats])

  return <BaseLayOut pageTitle={pageName}>
    <CustomSkelton is_loading={isLoading}>
      <EmailDeliveryStatusHubDataGrid isLoading={isLoading} rows={stats?.rows ?? []} columns={stats?.columns ?? []}/>
    </CustomSkelton>
  </BaseLayOut>
}

export default EmailDeliveryStatusHub