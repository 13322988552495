import React from 'react';
import { Link } from '@mui/material';
import { Card, Col } from 'antd';

const DownloadTemplateCard = ({ downloadLink, title }) => (
    <Col align="middle" md={8} xs={24}>
        <Link
            href={downloadLink}
            target="_blank"
            color="light"
            rel="noreferrer"
            style={{ display: 'inline-block' }}
        >
            <Card
                style={{
                    width: '240px',
                    borderRadius: '0.75rem',
                    boxShadow: '0rem 0.25rem 0.375rem -0.0625rem rgba(33, 33, 41, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(33, 33, 41, 0.06)',
                }}
            >
                <Card.Meta style={{ textAlign: 'center', justifyContent: 'center' }} title={title} />
            </Card>
        </Link>
    </Col>
);

export default DownloadTemplateCard;
