import React, { useContext, useEffect } from "react";
import usePagePermissions from "utils/hooks/usePagePermissions";
import BaseLayOut from "../../components/Layout/BaseLayOut";
import { Context } from "utils/context/store/Store";
import useManageCvCategories from "utils/hooks/useManageCvCategories";
import ManageCategoriesGrid from "./components/manage-categories-grid";
import useBulkUpload from "utils/hooks/useBulkUpload";

function ManageCvCategories({ routeKey, pageName }) {
  const { getPermissionsForPage } = usePagePermissions(routeKey);
  const [{ cvCategories }] = useContext(Context);
  const { getCvCategories, deleteCVCategory, updateOrCreateCvCategory, isLoading } =
    useManageCvCategories(routeKey);
  const { bulkUpload } = useBulkUpload(routeKey)

  const getCategories = async () => {
    await getCvCategories();
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <BaseLayOut pageTitle={"Manage CV Categories"}>
      <ManageCategoriesGrid
        isLoading={isLoading}
        rows={cvCategories.rows ?? []}
        columns={cvCategories.columns}
        itemsColumns={cvCategories.itemsColumns}
        lightsColumns={cvCategories.lightsColumns}
        permissions={getPermissionsForPage(routeKey)}
        dropDownData={cvCategories.dropdownValues}
        postData={updateOrCreateCvCategory}
        handleDelete={deleteCVCategory}
        allowAdding
        bulkUploadApi={bulkUpload}
        apiCallback={getCvCategories}
        tableName={cvCategories.tableName}
        uploadTemplateLink={cvCategories.uploadTemplateLink}
        orgStructureLink={cvCategories.orgStructureLink}
        allowBulkUploading={true}
      />
    </BaseLayOut>
  );
}

export default ManageCvCategories;
