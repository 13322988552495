import React from "react";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import { CheckBox } from "devextreme-react/check-box";
import MDTypography from "components/MDTypography";

export function FormulaCheckboxes({ combinationVal, selectedFormula, setSelectedFormula }) {
  // handle Formula/Combinations checkboxes change
  const onCheckboxValueChanged = (key, value) => {
    if (selectedFormula.length) {
      let formulaResult = selectedFormula?.map((formula) => {
        if (formula.id + formula.label === key) {
          return { ...formula, isChecked: !formula.isChecked };
        }
        return formula;
      });
      setSelectedFormula(formulaResult);
    }
  };

  return (
    <>
      {combinationVal && selectedFormula ? (
        <MDBox pb={1} style={{ display: "flex" }}>
          <Grid pt={0.3} xs={4} sm={2}>
            <MDTypography variant="button">Formula/Combinations:</MDTypography>
          </Grid>
          <Grid xs={10} sm={8}>
            {selectedFormula?.map(({ id, label, isChecked, value, isDisabled }) => (
              <CheckBox
                key={id + label}
                style={{ paddingRight: "20px" }}
                text={label}
                value={isChecked}
                disabled={isDisabled}
                onValueChanged={() => onCheckboxValueChanged(id + label, value)}
              />
            ))}
          </Grid>
        </MDBox>
      ) : null}
    </>
  );
}
