import CustomSkelton from "../../../../components/Skelton/CustomSkelton";
import BaseLayOut from "../../../../components/Layout/BaseLayOut";
import useCapability from "../../../../utils/hooks/useCapability";
import { useEffect } from "react";
import MasterCapabilityDataGrid from "./MasterCapabilityDataGrid";

export default function MasterCapability({routeKey, pageName}){

  const {getAllMasterCapabilities, createUpdateMasterCapabilities, deleteMasterCapabilities, setMasterCapability, masterCapability, permissions, isLoading} = useCapability(routeKey)

  useEffect( () => {
    const fn = async () => await getAllMasterCapabilities()
    fn()

    return () => {
      setMasterCapability({rows: [], columns: []})
    }
  }, []);

  useEffect( () => {}, [masterCapability]);

  return <CustomSkelton is_loading={isLoading}>
    <BaseLayOut pageTitle={pageName ?? "Capability"}>
      <MasterCapabilityDataGrid rows={masterCapability?.rows} columns={masterCapability?.columns} isLoading={isLoading} postAPI={createUpdateMasterCapabilities} handleDelete={deleteMasterCapabilities} permissions={permissions}/>
    </BaseLayOut>
  </CustomSkelton>
}