import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import useManageNotification from "utils/hooks/useManageNotifications";
import MDBox from "components/MDBox";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  isObjectEmpty,
} from "utils/services/Helpers";
import { toast } from "react-toastify";
import {
  NotificationStepTreelist,
  NotificationAccessStep,
  NotificationConditionStep,
} from "./components";
import Swal from "sweetalert2";
import { sanitizeInput } from "utils/services/Helpers";

const workflowStepData = [
  {
    id: 1,
    label: "Functional Access",
    value: "functional",
  },
  {
    id: 2,
    label: "Workflow Access",
    value: "workflow",
  },
];

function NotificationStep({
  data,
  updateButtonStateOfParent = null,
  permissions,
  tablesList,
  dropDownData,
  routeKey,
}) {
  const { getRecordByField, records, isLoading, deleteNotificationSteps } =
    useManageNotification(routeKey);
  const [conditionType, setConditionType] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [tableName, setTableName] = useState(null);
  const [tableColumnName, setTableColumnName] = useState(null);
  const [columnOperation, setColumnOperation] = useState(null);
  const [conditionValue, setConditionValue] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isForCreator, setIsForCreator] = useState(false);
  const [isForHiringManager, setIsForHiringManager] = useState(false);
  const [sendPush, setSendPush] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [approveDirectly, setApproveDirectly] = useState(false);
  const [subject, setSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [workflowStep, setWorkflowStep] = useState(null);
  const [workflowAccesses, setWorkflowAccess] = useState([]);
  const [functionalAccesses, setFunctionalAccesses] = useState([]);
  const [dataId, setDataId] = useState(null);
  const [tableId, setTableId] = useState(null);
  const [labelConditionValue, setLabelConditionValue] = useState(null);
  const [usePredefinedValue, setUsePredefinedValue] = useState(false);
  const [predefinedValue, setPredefinedValue] = useState(null);

  const expandedRowKeys = [1];

  const subjectChips = ["<< Email Type >>", "<< Request ID >>", "<< HC ID >>", "<< Module >>"];

  useEffect(async () => {
    if (data.data.notification_steps?.length > 0) {
      setDataSource(data.data.notification_steps);
    }
  }, []);

  useEffect(() => {
    if (labelConditionValue && records) {
      const labelArray = labelConditionValue && labelConditionValue.toString().split(",");
      let condVal = [];
      labelArray.map((obj) => {
        const filter = records && records.filter((o) => o.label === obj);
        if (condVal.length) {
          condVal = [...condVal, ...filter];
        } else {
          condVal.push(...filter);
        }
        setConditionValue(condVal);
      });
    }
  }, [records]);

  useEffect(async () => {
    if (columnOperation && !isObjectEmpty(columnOperation)) {
      if (
        tableColumnName &&
        tableColumnName.type === "STRING" &&
        (columnOperation.value === "=" || columnOperation.value === "in")
      ) {
        await getRecordByField(tableName.modelName, tableColumnName.name);
      }
    }
  }, [columnOperation]);

  /**
   * @param selectedRowKeys
   * @param selectedRowsData
   * used to get selected rows detail of data-grid
   **/
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedRowKeys(selectedRowsData);
  };

  /**
   * @param e
   * function to handle sequence change of main tree list
   **/
  const onMainReorder = (e) => {
    const visibleRows = e.component.getVisibleRows();
    let sourceData = e.itemData;
    let newData = [...dataSource];
    const sourceIndex = newData.indexOf(sourceData);
    if (e.toIndex === 0 && sourceData.conditionType !== "condition") {
      return handleErrorToast("First step must be a condition");
    }

    if (e.dropInsideItem) {
      sourceData = { ...sourceData, id: visibleRows[e.toIndex].key };
      newData = [...newData.slice(0, sourceIndex), sourceData, ...newData.slice(sourceIndex + 1)];
    } else {
      const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
      let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

      if (targetData && e.component.isRowExpanded(targetData.id)) {
      } else {
        const parentId = targetData ? targetData.parentId : -1;
        if (sourceData.parentId !== parentId) {
          sourceData = { ...sourceData, parentId: parentId };
        }
      }

      newData = [...newData.slice(0, sourceIndex), ...newData.slice(sourceIndex + 1)];

      const targetIndex = newData.indexOf(targetData) + 1;
      newData = [...newData.slice(0, targetIndex), sourceData, ...newData.slice(targetIndex)].map(
        (e, i) => ({ ...e, sequence: i + 1, uniqueKey: i + 1 })
      );
      if (newData[0].conditionType !== "condition") {
        return handleErrorToast("First step must be a condition");
      }
    }

    data.data.notification_steps = newData;
    data.data.dataChanged = true;
    setDataSource(newData);
    updateButtonStateOfParent(true);
  };

  // bulk delete using row selection
  const manageDelete = async () => {
    const results = [...selectedRowKeys];
    const allStepsSelected = selectedRowKeys.length === dataSource.length;

    const deleteWarningMessage = allStepsSelected
      ? "You have selected all steps and the notification associated to it will also be deleted and you won't be able to revert this!"
      : "You won't be able to revert this!";

    const numberOfConditionsSelected = results.filter(
      (e) => e.conditionType === "condition"
    ).length;

    const totalNumberOfConditions = dataSource.filter(
      (e) => e.conditionType === "condition"
    ).length;

    if (!allStepsSelected && numberOfConditionsSelected >= totalNumberOfConditions) {
      toast.error("There must be at-least 1 condition in the notification!");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: deleteWarningMessage,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      heightAuto: false,
      height: "200px",
    }).then(async (res) => {
      let newData = [...dataSource];
      if (res.isConfirmed) {
        const keysWithTableId = selectedRowKeys.filter((a) => a.tableId);
        const keysWithoutTableId = selectedRowKeys.filter((a) => !a.tableId);

        // Determine the notificationId based on selectedRowKeys and dataSource length
        const notificationId = allStepsSelected ? results[0]?.notificationId : null;

        // Define actions with associated conditions
        const actions = [
          {
            condition: keysWithTableId.length > 0 && keysWithoutTableId.length > 0, // Both keys with and without ID
            action: async () => await deleteNotificationSteps(results, notificationId),
          },
          {
            condition: keysWithTableId.length > 0, // Only keys with ID
            action: async () => await deleteNotificationSteps(results, notificationId),
          },
          {
            condition: keysWithoutTableId.length > 0, // Only keys without ID
            action: () => {
              newData = newData.filter((item) => !keysWithoutTableId.some((a) => item.id === a.id)); // Remove matching items
              data.data.notification_steps = newData;
              data.data.dataChanged = true;
              setDataSource(data.data.notification_steps); // Update the data source
            },
          },
        ];

        // Find the first matching condition and execute the associated action
        const matchingAction = actions.find(({ condition }) => condition);
        if (matchingAction) {
          await matchingAction.action(); // Await here handles both synchronous and asynchronous actions
        }

        // Reset selected row keys and fetch data from the API
        setSelectedRowKeys([]);
      }

      setSelectedRowKeys([]);
    });
  };

  /**
   * @param e
   * function to empty states on change of main accordion
   **/
  const handleChange = (panel) => (event, newExpanded) => {
    setConditionType(newExpanded ? panel : false);
    setTableName(null);
    setTableColumnName(null);
    setColumnOperation(null);
    setConditionValue(null);
    setWorkflowStep(null);
    setWorkflowAccess([]);
    setFunctionalAccesses([]);
    setSubject("");
    setSendEmail(false);
    setSendPush(false);
    setApproveDirectly(false);
    setEmailBody("");
    setDataId(null);
    setPredefinedValue(null);
    setUsePredefinedValue(false);
    setIsForCreator(false);
    setIsForHiringManager(false);
    setTableId(null);
    setLabelConditionValue(null);
  };

  /**
   * @param records
   * function to implement select all functionality for multi-select dropdown
   **/
  function getOptions(records) {
    return records && records.length > 0 ? [{ label: "Select All", value: "all" }, ...records] : [];
  }

  /**
   * @param e
   * function to set sup-process data to sub-process tree list
   **/
  const setEditState = (data) => {
    if (!isObjectEmpty(data)) {
      // Mapping of data properties to their corresponding setter functions
      const propertiesMapping = {
        id: setDataId,
        conditionType: setConditionType,
        ddpreDefined: setPredefinedValue,
        ddtableName: setTableName,
        ddtableColumnName: setTableColumnName,
        ddcolumnOperation: setColumnOperation,
        subject: setSubject,
        emailBody: setEmailBody,
        isForCreator: setIsForCreator,
        isForHiringManager: setIsForHiringManager,
        sendPush: setSendPush,
        sendEmail: setSendEmail,
        ddconditionValue: setConditionValue,
        conditionValue: setLabelConditionValue,
        tableId: setTableId,
        approveDirectly: setApproveDirectly,
        directlyApprove: setApproveDirectly,
      };

      // Loop through the properties mapping and call the setter function with the corresponding value
      for (const [property, setter] of Object.entries(propertiesMapping)) {
        setter(data[property]);
      }
      // check if predefined has a value
      setUsePredefinedValue(Boolean(data.preDefined));

      // Processing notification accesses if available
      const notificationAccesses = data.notification_accesses;
      if (notificationAccesses?.length) {
        const isFunctionalAccess = notificationAccesses.some(
          (e) => e.accessType === "functional_access"
        );
        const accessIds = notificationAccesses.map((e) => e.accessId);

        // Select the correct access data and workflow step based on functional access
        const accessKey = isFunctionalAccess ? "functionalAccessData" : "workflowAccessData";
        const selectedAccesses = dropDownData[accessKey].filter((e) => accessIds.includes(e.value));
        setWorkflowStep(workflowStepData[isFunctionalAccess ? 0 : 1]);

        isFunctionalAccess
          ? setFunctionalAccesses(selectedAccesses)
          : setWorkflowAccess(selectedAccesses);
      }
    }
  };

  const addTableStyles = (html) => {
    if (!html) {
      return html;
    }
    // Create a temporary div element
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Select all table elements and add styles
    const tables = tempDiv.querySelectorAll("table");
    tables.forEach((table) => {
      table.style.border = "1px solid black"; // Border for the table
      table.style.width = "100%";
      table.style.borderCollapse = "collapse";
      // Add borders to all td and tr elements within the table
      const cells = table.querySelectorAll("td, th");
      cells.forEach((cell) => {
        cell.style.border = "1px solid black"; // Border for the td and tr
        cell.style.borderCollapse = "collapse";
        cell.style.paddingLeft = "15px";
      });
    });

    // Return the modified innerHTML
    return tempDiv.innerHTML;
  };

  const getMappedNotificationAccesses = (arr, isFunctionalAccess) => {
    return arr.map((noti, i) => ({
      id: i + 1,
      accessType: isFunctionalAccess ? "functional_access" : "workflow_access",
      accessId: noti.value,
      name: noti.label,
    }));
  };
  /**
   * @param e
   * function to insert records to the main tree list
   **/
  const performAction = (emailBodyValue) => {
    const generateValue = (value, fallback) => (value ? value : fallback);

    const idValue = generateValue(dataId, dataSource.length + 1);

    const conditionValueLabel = Array.isArray(conditionValue)
      ? conditionValue.map((obj) => obj["label"])
      : conditionValue?.label;

    const notificationAccesses = getMappedNotificationAccesses(
      functionalAccesses.length ? functionalAccesses : workflowAccesses,
      functionalAccesses.length
    );
    let workflowUpdated = false;
    let currentTableId = tableId;
    if (dataId) {
      const previousNotificationAccesses = dataSource.find(
        (e) => e.id === dataId
      )?.notification_accesses;
      const isFunctionalAccess = previousNotificationAccesses?.some(
        (e) => e.accessType === "functional_access"
      );
      if (workflowStep?.value !== "functional" && isFunctionalAccess) {
        workflowUpdated = true;
      } else if (workflowStep?.value === "functional" && !isFunctionalAccess) {
        workflowUpdated = true;
      }
      if (!previousNotificationAccesses?.length) {
        workflowUpdated = false;
      }
      currentTableId = data.data?.notification_steps?.find(e => e.id == dataId)?.tableId;
      setTableId(currentTableId);
    }
    let dataToSave = createDataToSave({
      conditionType,
      idValue,
      tableId: currentTableId,
      data,
      isForCreator,
      isForHiringManager,
      notificationAccesses,
      sendPush,
      sendEmail,
      approveDirectly,
      subject,
      emailBodyValue,
      workflowUpdated,
    });
    if (usePredefinedValue) {
      dataToSave = { ...dataToSave, ...getPredefinedValues(predefinedValue) };
    } else {
      dataToSave = {
        ...dataToSave,
        ...getConditionStep({
          tableName,
          tableColumnName,
          columnOperation,
          conditionValue,
          conditionValueLabel,
        }),
      };
    }

    const notificationSteps = data.data.notification_steps || [];
    const i = notificationSteps.findIndex((_item) => _item.id === dataToSave.id);
    const newData = [...notificationSteps];

    // Update or add the data
    if (i > -1) {
      dataToSave.sequence = newData[i]?.sequence ?? idValue;
      newData[i] = dataToSave;
    } else {
      dataToSave.sequence = idValue;
      newData.push(dataToSave);
    }

    // Update the notification steps
    data.data.notification_steps = newData;
    data.data.dataChanged = true;

    updateButtonStateOfParent(true);
    setDataSource(newData);
    toast.success("Step added in table successfully!");
    handleReset();
  };

  const getNotificationAccessId = (isForCreator, isForHiringManager, notificationAccesses) =>
    !(isForCreator || isForHiringManager) ? notificationAccesses.map((e) => e.name).join(",") : null;

  const getPredefinedValues = (predefinedValue) => ({
    preDefined: predefinedValue?.value,
    ddpreDefined: predefinedValue,
  });

  const getConditionStep = ({
    tableName,
    tableColumnName,
    columnOperation,
    conditionValue,
    conditionValueLabel,
  }) => ({
    tableName: tableName?.value,
    tableColumnName: tableColumnName?.value,
    foreignKeyValue: tableName?.foreignKeyValue,
    columnOperation: columnOperation?.value,
    conditionValue: conditionValue
      ? typeof conditionValue === "object"
        ? conditionValueLabel
        : conditionValue
      : null,
    ddtableName: tableName,
    ddtableColumnName: tableColumnName,
    ddcolumnOperation: columnOperation,
    ddconditionValue: conditionValue,
  });

  const createDataToSave = ({
    idValue,
    tableId,
    data,
    isForCreator,
    isForHiringManager,
    notificationAccesses,
    sendPush,
    sendEmail,
    approveDirectly,
    subject,
    emailBodyValue,
    workflowUpdated,
    conditionType,
  }) => ({
    id: idValue,
    uniqueKey: idValue,
    tableId: tableId ?? null,
    parentId: -1,
    notificationId: data.data.id,
    notification_accesses: !(isForCreator || isForHiringManager) ? notificationAccesses : null,
    isForCreator,
    isForHiringManager,
    sendPush,
    sendEmail,
    approveDirectly,
    directlyApprove: approveDirectly,
    subject: sendPush || sendEmail ? subject : null,
    emailBody: sendEmail ? addTableStyles(emailBodyValue) : null,
    ddworkflowAccessId: getNotificationAccessId(isForCreator, isForHiringManager, notificationAccesses),
    workflowUpdated,
    preDefined: null,
    ddpreDefined: null,
    conditionType,
    tableName: null,
    tableColumnName: null,
    foreignKeyValue: null,
    columnOperation: null,
    conditionValue: null,
    ddtableName: null,
    ddtableColumnName: null,
    ddcolumnOperation: null,
    ddconditionValue: null,
  });

  // Helper function to handle error messaging
  const handleErrorToast = (message) => {
    toast.error(message);
    return false;
  };

  // Helper function to validate "condition" type
  const validateConditionType = () => {
    if (usePredefinedValue) {
      if (!predefinedValue) return handleErrorToast("Please select the predefined value");
    } else {
      if (!tableName) return handleErrorToast("Please select value");
      if (!tableColumnName) return handleErrorToast("Please select table column");
      if (!columnOperation) return handleErrorToast("Please select table column operator");
      if (tableColumnName.type !== "BOOLEAN" && !conditionValue)
        return handleErrorToast("Please select condition value");
      if (conditionValue && !sanitizeInput(conditionValue))
        return handleErrorToast("Invalid characters detected. Please remove any special characters.")
    }

    return true;
  };

  // Helper function to validate "access" type
  const validateAccessType = () => {
    if (!(isForCreator || isForHiringManager)) {
      if (!workflowStep || (!workflowAccesses.length && !functionalAccesses.length)) {
        return handleErrorToast(
          `Please select ${!workflowStep ? "access type" : workflowStep.label} value!`
        );
      }
    }

    if (!sendEmail && !sendPush) {
      return handleErrorToast("Please select Send Email or Push");
    }

    if ((sendEmail || sendPush) && !subject) {
      return handleErrorToast("Please fill the notification subject!");
    }

    return true;
  };

  const validationFunctions = {
    condition: validateConditionType,
    access: validateAccessType,
  };

  const handleSubmit = (emailBodyString) => {
    const validationFunction = validationFunctions[conditionType] || (() => true);
    if (validationFunction()) {
      setEmailBody(emailBodyString);
      performAction(emailBodyString);
    }
  };

  /**
   * @param e
   * function to empty states
   **/
  const handleReset = () => {
    setConditionType(null);
    setTableName(null);
    setTableColumnName(null);
    setColumnOperation(null);
    setPredefinedValue(null);
    setConditionValue(null);
    setIsForCreator(false);
    setIsForHiringManager(false);
    setUsePredefinedValue(false);
    setSendPush(false);
    setSendEmail(false);
    setApproveDirectly(false);
    setSubject("");
    setEmailBody("");
    setWorkflowStep(null);
    setWorkflowAccess([]);
    setFunctionalAccesses([]);
    setDataId(null);
    setLabelConditionValue(null);
  };

  const onFocusedRowChanged = (e) => {
    handleReset();
    const record = e && e.hasOwnProperty("row") ? e.row.node : e.node;
    if (e.element.id === "main") {
      if (!record.hasChildren && record.parent.key === -1) {
        setEditState(record.data);
      }
    }
  };

  /**
   * @param e
   * function to set condition to allow/disable deleting of row in datagrid
   **/
  const allowDeleting = (e) => {
    return e.row.data.tableId === null;
  };

  /**
   * @param e
   * in-built function to handle sequence change of tree list
   **/
  const onDragChange = (e) => {
    const visibleRows = e.component.getVisibleRows();
    const sourceNode = e.component.getNodeByKey(e.itemData.id);
    let targetNode = visibleRows[e.toIndex].node;

    while (targetNode && targetNode.data) {
      if (targetNode.data.id === sourceNode.data.id) {
        e.cancel = true;
        break;
      }
      targetNode = targetNode.parent;
    }
  };

  const functionalAccessData = dropDownData.functionalAccessData.filter(
    (e) => e.masterModuleId === data.data.masterModuleId
  );

  const workflowAccessData = dropDownData.workflowAccessData;

  const subjectTags = dropDownData.emailTagsData.length
    ? dropDownData.emailTagsData
      .filter((e) => !e.masterModuleId || e.masterModuleId === data.data.masterModuleId)
      .map((e) => e.label.replace(/<<(\S)/g, "<< $1"))
    : subjectChips;

  const isSubmitDisabled = !dataSource.some((e) => e.conditionType === "condition");
  const editorBody = emailBody?.length ? emailBody : defaultEmailBodyValue;
  const predefinedValues = dropDownData.preDefined[data.data.masterModuleId] ?? [];
  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <MDBox>
          <MDBox pt={2} pl={3} pr={3} pb={3}>
            <div>
              <Accordion
                expanded={conditionType === "condition"}
                onChange={handleChange("condition")}
              >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">By Condition</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <NotificationConditionStep
                    props={{
                      tablesList,
                      permissions,
                      tableName,
                      setTableName,
                      tableColumnName,
                      setTableColumnName,
                      columnOperation,
                      setColumnOperation,
                      conditionValue,
                      setConditionValue,
                      dropDownData,
                      getRecordByField,
                      isLoading,
                      getOptions,
                      records,
                      handleSubmit,
                      handleReset,
                      predefinedValues,
                      usePredefinedValue,
                      setUsePredefinedValue,
                      predefinedValue,
                      setPredefinedValue,
                    }}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={conditionType === "access"} onChange={handleChange("access")}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <MDTypography style={{ color: "#333333", fontSize: "14px" }} variant="h6">By Access</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <NotificationAccessStep
                    subject={subject}
                    isForCreator={isForCreator}
                    setIsForCreator={setIsForCreator}
                    isForHiringManager={isForHiringManager}
                    setIsForHiringManager={setIsForHiringManager}
                    sendPush={sendPush}
                    setSendPush={setSendPush}
                    sendEmail={sendEmail}
                    setSendEmail={setSendEmail}
                    subjectChips={subjectTags}
                    approveDirectly={approveDirectly}
                    setApproveDirectly={setApproveDirectly}
                    setSubject={setSubject}
                    emailBody={editorBody}
                    setEmailBody={setEmailBody}
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                    isSubmitDisabled={isSubmitDisabled}
                    workflowStepData={workflowStepData}
                    workflowStep={workflowStep}
                    setWorkflowStep={(value) => {
                      setFunctionalAccesses([]);
                      setWorkflowAccess([]);
                      setWorkflowStep(value);
                    }}
                    isLoading={isLoading}
                    workflowAccessData={workflowAccessData}
                    workflowAccesses={workflowAccesses}
                    setWorkflowAccess={setWorkflowAccess}
                    functionalAccessData={functionalAccessData}
                    functionalAccesses={functionalAccesses}
                    setFunctionalAccesses={setFunctionalAccesses}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </MDBox>
        </MDBox>
      </form>

      <NotificationStepTreelist
        isLoading={isLoading}
        dataSource={dataSource.sort((a, b) => a.sequence - b.sequence)}
        setDataSource={(value) => {
          data.data.notification_steps = value;
          setDataSource(value);
        }}
        expandedRowKeys={expandedRowKeys}
        onSelectionChanged={onSelectionChanged}
        allowDeleting={allowDeleting}
        onDragChange={onDragChange}
        permissions={permissions}
        onMainReorder={onMainReorder}
        selectedRowKeys={selectedRowKeys}
        manageDelete={manageDelete}
        onFocusedRowChanged={onFocusedRowChanged}
      />
    </React.Fragment>
  );
}

export default NotificationStep;

const defaultEmailBodyValue = `<h1 style="text-align: center;"><span style="font-family: &quot;Courier New&quot;; font-size: 36pt;">Your header here</span></h1><p><br><span style="font-family: &quot;Courier New&quot;; font-size: 14pt;">Your Body goes here... <br><br><br> </span><span style="font-size: 14pt;"> <br><br><br></span></p>
  <table style="height: 100px; border: 1px solid black; background-color: rgba(110, 106, 106, 0); text-align: center;"><tbody><tr style="border: 1px solid black;"><td style="border: 1px solid black;"><p>Header 1</p></td><td style="border: 1px solid black;"><p>Header 2</p></td><td style="border: 1px solid black;"><p>Header 3</p></td></tr><tr style="border: 1px solid black;"><td style="border: 1px solid black;"><p>Value 1</p></td><td style="border: 1px solid black;"><p>Value 2</p></td><td style="border: 1px solid black;"><p>Value 3</p></td></tr><tr style="border: 1px solid black;"><td style="border: 1px solid black;"><p>Value 1</p></td><td style="border: 1px solid black;"><p>Value 2</p></td><td style="border: 1px solid black;"><p>Value 3</p></td></tr></tbody></table>`;
